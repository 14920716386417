import produce from "immer";
import storyApi, { GetStoryViewParams } from "lib/api/story";
import { IPostUser } from "types/responses/post";
import { IAddStory, IStory, IStoryItem } from "types/responses/story";
import create from "zustand";

interface StoryStore {
  stories: {
    mine: Array<IStory>;
    stories: Array<IStoryItem>;
  };
  storyViewers: Record<string, Array<IPostUser>>;
  storyLikes: Record<string, Array<IPostUser>>;
  getStories: () => Promise<void>;

  getStoryViewers: (params: GetStoryViewParams) => Promise<void>;
  viewStory: (id: string) => Promise<void>;
  getStoryLikes: (params: GetStoryViewParams) => Promise<void>;
  likeStory: (id: string) => Promise<boolean>;
  unlikeStory: (id: string) => Promise<boolean>;

  addStory: (data: IAddStory) => Promise<boolean>;
  deleteStory: (id: string) => Promise<boolean>;
}

export const useStoryStore = create<StoryStore>((set) => ({
  stories: {
    mine: [],
    stories: [],
  },
  storyViewers: {},
  storyLikes: {},
  getStories: async () => {
    try {
      const response = await storyApi.getStories();

      if (response.status !== 200) {
        return;
      }

      if (response.data.message) {
        return;
      }

      set((state) => ({
        ...state,
        stories: response.data,
      }));
    } catch (error) {
      console.log(error);
    }
  },
  getStoryViewers: async (params) => {
    try {
      const response = await storyApi.getStoryViewers(params);

      if (response.status !== 200) {
        return;
      }

      if (response.data.message) {
        return;
      }

      if (params.page === 1) {
        return set(
          produce((state: StoryStore) => {
            state.storyViewers[params.id] = response.data.data;
          }),
        );
      }

      set(
        produce((state: StoryStore) => {
          state.storyViewers[params.id] = [
            ...state.storyViewers[params.id],
            ...response.data.data,
          ];
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  viewStory: async (id: string) => {
    try {
      const response = await storyApi.viewStory(id);

      if (response.status !== 200) {
        return;
      }

      if (response.data.message) {
        return;
      }

      return;
    } catch (error) {
      console.log(error);
    }
  },
  addStory: async (data: IAddStory) => {
    try {
      const response = await storyApi.addStory(data);

      if (response.status !== 200) {
        return false;
      }

      if (response.data.message) {
        return false;
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deleteStory: async (id: string) => {
    try {
      const response = await storyApi.deleteStory(id);

      if (response.status !== 200) {
        return false;
      }

      if (response.data.message) {
        return false;
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  getStoryLikes: async (params) => {
    try {
      const response = await storyApi.getStoryLikes(params);

      if (response.status !== 200) {
        return;
      }

      if (response.data.message) {
        return;
      }

      if (params.page === 1) {
        return set(
          produce((state: StoryStore) => {
            // @ts-ignore
            state.storyLikes[params.id] = response.data;
          }),
        );
      }

      set(
        produce((state: StoryStore) => {
          state.storyLikes[params.id] = [
            ...state.storyLikes[params.id],
            ...response.data.data,
          ];
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  likeStory: async (id: string) => {
    try {
      const response = await storyApi.likeStory(id);

      if (response.status !== 200) {
        throw new Error("Error");
      }

      if (response.data.message) {
        throw new Error("Error");
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  unlikeStory: async (id: string) => {
    try {
      const response = await storyApi.unlikeStory(id);

      if (response.status !== 200) {
        throw new Error("Error");
      }

      if (response.data.message) {
        throw new Error("Error");
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
}));

export default useStoryStore;
