import { Image } from "components/Base";

interface FooterIconProps {
  src: string;
  onClick?: () => void;
  htmlFor?: string;
}

function FooterIcon({ src, onClick = () => {}, htmlFor }: FooterIconProps) {
  return (
    <label htmlFor={htmlFor}>
      <Image
        src={src}
        alt="footer-icon"
        h="19px"
        onClick={onClick}
        css={{ cursor: "pointer" }}
      />
    </label>
  );
}

export default FooterIcon;
