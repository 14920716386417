import create from "zustand";

interface LoginStore {
  state: "form" | "code";
  setState: (state: "form" | "code") => void;
}

const useLoginStore = create<LoginStore>((set) => ({
  state: "form",
  setState: (state) => set({ state }),
}));

export default useLoginStore;
