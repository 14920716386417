/* eslint-disable max-len */
import { Box, Button, Card, Flex, Text } from "components/Base";
import useMengenal7DStore from "features/7d/mengenal7dStore";
import If from "global/ErrorHandlers/If";
import dApi from "lib/api/7d";
import { BsCalendar2Fill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/date";

interface CardTakeProps {
  id: string;
  idTake?: string | null;
  title: string;
  description: string;
  color: string;
  doneAt?: string | null;
  status?: string | null;
}

function CardTake({
  id,
  idTake,
  title,
  description,
  color,
  doneAt,
  status,
}: CardTakeProps) {
  const navigate = useNavigate();
  const getList = useMengenal7DStore((s) => s.getTakeList);

  const percentToHex = (percentNum = 0) =>
    Math.round((percentNum / 100) * 255)
      .toString(16)
      .padStart(2, "0");

  const handleClick = async () => {
    if (!status) {
      const res = await dApi.add7dTake(Number(id));

      if (res.status !== 200) {
        return;
      }

      if (res.data.message) {
        return;
      }

      getList();
      return;
    }

    navigate(`/reports/${idTake}`);
  };

  const bgColor = `${color}${percentToHex(20)}`;
  const circleColor = `${color}${percentToHex(80)}`;

  return (
    <Card
      boxShadow="none"
      backgroundColor={bgColor}
      br="$rounded-lg"
      p="20px"
      w="100%"
      h="180px"
      position="relative"
      overflow="hidden"
    >
      <Flex direction="column" gap="14px">
        <Text color={color} weight="$bold" fontSize="$2xl" lineHeight="34px">
          {title}
        </Text>
        <Box
          css={{
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            height: "44px",
          }}
        >
          <Text color={color} lineHeight="22px">
            {description}
          </Text>
        </Box>
        <Flex justify="space-between" width="100%">
          <If condition={!!doneAt}>
            <Flex alignItems="center" gap="8px">
              <BsCalendar2Fill color={color} />
              <Text weight="$bold" color={color}>
                {/* @ts-ignore */}
                Diambil: {formatDate(doneAt, "d MMM yyyy")}
              </Text>
            </Flex>
          </If>
          <If condition={!doneAt}>
            <div>&nbsp;</div>
          </If>
          <Button
            color="secondary"
            size="small"
            shape="circle"
            onClick={handleClick}
          >
            {!status ? "Mulai" : "Lihat Laporan"}
          </Button>
        </Flex>
      </Flex>
      <Flex
        w="70px"
        h="70px"
        br="$rounded-full"
        border={`solid 6px ${circleColor}`}
        p="8px"
        position="absolute"
        top="-20px"
        right="-18px"
      >
        <Flex w="42px" h="42px" br="$rounded-full" bg={circleColor} />
      </Flex>
    </Card>
  );
}

export default CardTake;
