import { Progress } from "antd";
import { Flex, Image, Text } from "components/Base";
import profileStore from "lib/stores/entities/profile";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getColor } from "utils/general";
import { getUserBadge } from "../utils";

interface PointCardProps {
  onClick?: () => void;
}

function PointCard({ onClick }: PointCardProps) {
  const user = profileStore((s) => s.user);

  if (!user) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      w="100%"
      br="$rounded-lg"
      border="solid 1px $coolGray300"
      linearGradient="90deg, #FFE7B8, #fff"
      p="18px"
      gap="12px"
      position="relative"
    >
      <Image
        src={user.badge.media}
        alt="Sigap Medal"
        css={{ h: "70px", w: "45px" }}
      />
      <Flex direction="column" style={{ flex: 1 }}>
        <Text color="$sunrise" weight="$bold" fontSize="$lg">
          {user.badge.name}
        </Text>
        <Progress
          percent={(user.total_point / Number(user.badge.end_score)) * 100}
          showInfo={false}
          strokeColor="#37A555"
        />
        <Flex alignItems="center" gap="8px">
          <Text size="$md">
            <strong>{user.total_point}</strong> Poin
          </Text>
          <Flex
            alignItems="center"
            gap="3px"
            style={{ cursor: "pointer" }}
            onClick={onClick}
          >
            <Text color="$secondary" weight="$bold">
              Lihat level kamu
            </Text>
            <BiChevronRight color={getColor("secondary")} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PointCard;
