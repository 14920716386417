import { Flex } from "components/Base";
import BadgeLabel from "./BadgeLabel";

interface UserBadgeProps {
  color: string;
  name: string;
  points: number;
  onClick?: () => void;
}

function UserBadge({
  color,
  name,
  points,
  onClick = () => {},
}: UserBadgeProps) {
  return (
    <Flex
      alignItems="center"
      justify="space-between"
      background="$white"
      boxShadow="0px 2px 40px rgba(173, 215, 185, 0.6)"
      w="100%"
      h="85px"
      br="100px"
      cursor="pointer"
      onClick={onClick}
    >
      <Flex h="85px" br="100px" p="30px 16px" alignItems="center">
        <BadgeLabel
          label="Level"
          icon="/icons/medal.svg"
          color={color}
          value={name}
        />
      </Flex>
      <Flex
        h="85px"
        br="100px"
        p="16px 30px"
        alignItems="center"
        background="$secondary5"
      >
        <BadgeLabel
          label="Total Poin"
          icon={"/icons/point.svg"}
          value={points}
          color="$secondary"
        />
      </Flex>
    </Flex>
  );
}

export default UserBadge;
