import If from "global/ErrorHandlers/If";
import OTP from "./OTP";
import RegisterForm from "./RegisterForm";
import useRegisterStore from "./registerStore";

function Register() {
  const state = useRegisterStore((s) => s.state);

  return (
    <>
      <If condition={state === "form"}>
        <RegisterForm />
      </If>
      <If condition={state === "otp"}>
        <OTP />
      </If>
    </>
  );
}

export default Register;
