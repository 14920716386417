import { VIDEO_FILES_EXTENSION } from "constants/app";
import { Image } from "components/Base";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { getMediaExtension } from "utils/media";

interface ImageItemProps {
  src: string;
  isSingle?: boolean;
  isVideoFile?: boolean;
  [key: string]: any;
}

function ImageItem({
  src,
  isSingle = false,
  isVideoFile = false,
  ...props
}: ImageItemProps) {
  const [image, setImage] = useState(src);
  const isVideo = useMemo(() => {
    const fileExtension = getMediaExtension(src);
    return VIDEO_FILES_EXTENSION.includes(fileExtension) || isVideoFile;
  }, [src, isVideoFile]);

  useEffect(() => {
    if (src) {
      const fileExtension = getMediaExtension(src);
      const isVideo = VIDEO_FILES_EXTENSION.includes(fileExtension);

      if (isVideo) {
        setImage("/images/video-placeholder.webp");
      }
    }
  }, [src]);

  if (isSingle && isVideo) {
    return <ReactPlayer width={547} height={296} url={src} controls />;
  }

  return <Image src={image} w="100%" h="100%" autofit {...props} />;
}

export default ImageItem;
