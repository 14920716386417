import { Divider, Popover } from "antd";
import { Box, Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import useInput from "hooks/useInput";
import authStore from "lib/stores/entities/auth";
import { useEffect, useState } from "react";
import { IoSearch, IoMenu } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "utils/authStore";
import { getColor } from "utils/general";
import { convertToEventTarget } from "utils/input";
import ButtonAuth from "./ButtonAuth";
import { navs, publicNavs } from "./Navigation/constants";
import { MobileWrapper } from "./styled";

function MobileHeader() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const authUser = authStore((state) => state.user);
  const [search, setSearch] = useInput("");

  useEffect(() => {
    const token = authUser || getToken();

    if (token) {
      setLoggedIn(true);
    }
  }, [authUser]);

  const handleSearch = () => {
    setSearch(convertToEventTarget(""));
    navigate(`/search?q=${search}`);
  };

  return (
    <MobileWrapper as="header" id="mobile-wrapper">
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        p="16px"
        position="relative"
      >
        <Link to="/">
          <Image src="/logo.png" height="30px" alt="Sigapp logo" />
        </Link>
        <Popover
          trigger={["click"]}
          showArrow={false}
          // @ts-ignore
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentElement;
          }}
          overlayStyle={{
            width: "100%",
            left: 0,
            top: "64px",
            border: "none",
          }}
          overlayInnerStyle={{
            borderRadius: "0px",
            border: "none",
          }}
          content={
            <Flex w="100%" direction="column" gap="20px" p="8px">
              <InputSearch
                w="100%"
                value={search}
                onChange={setSearch}
                onPressEnter={handleSearch}
              />
              <If condition={loggedIn}>
                {navs.map((nav, idx) => (
                  <Link key={`mobile-nav-login-${idx}`} to={nav.url}>
                    <Text color="$black" weight="bold">
                      {nav.label}
                    </Text>
                  </Link>
                ))}
              </If>
              <If condition={!loggedIn}>
                {publicNavs.map((nav, idx) => (
                  <Flex direction="column" w="100%">
                    <Link key={`mobile-nav-public-${idx}`} to={nav.url}>
                      <Text size="$lg" color="$black" weight="bold">
                        {nav.label}
                      </Text>
                    </Link>
                    <Divider style={{ margin: "12px 0 0 0" }} />
                  </Flex>
                ))}
                <ButtonAuth />
              </If>
            </Flex>
          }
        >
          <Flex align="center" gap="20px">
            <IoSearch color={getColor("secondary")} size="24px" />
            <IoMenu color={getColor("secondary")} size="24px" />
          </Flex>
        </Popover>
      </Flex>
    </MobileWrapper>
  );
}

export default MobileHeader;
