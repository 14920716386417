import { Image, Text } from "components/Base";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useMengenal7DStore from "./mengenal7dStore";

function Detail7D() {
  const { id } = useParams();
  const [list, getList] = useMengenal7DStore((s) => [s.list, s.getList]);

  useEffect(() => {
    if (list.length === 0) {
      getList();
    }
  }, [list]);

  const item = list.find((item) => item.id === id);

  if (!item) {
    return null;
  }

  return (
    <>
      <Image
        height="405px"
        w="100%"
        autofit
        src={item.image}
        my="45px"
        br="$rounded-md"
      />
      <Text size="$2xl" color="$secondary2" weight="$bold" mb="16px">
        {item.title}
      </Text>
      <Text color="$coolGray500" fontSize="18px" lineHeight="25px" mb="80px">
        {item.content}
      </Text>
    </>
  );
}

export default Detail7D;
