import { APP_URL } from "constants/api";
import { message } from "antd";
import { ErrorBox } from "components/Auth";
import AuthCard from "components/Auth/AuthCard";
import { Card, Flex, Text } from "components/Base";
import { Input, Password } from "components/Form";
import If from "global/ErrorHandlers/If";
import useInput from "hooks/useInput";
import authStore from "lib/stores/entities/auth";
import { useState } from "react";
import { HiOutlineQrcode } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { getColor } from "utils/general";
import ButtonBlock from "./components/ButtonBlock";
import ModalContact from "./components/ModalContact";
import useLoginStore from "./loginStore";

function FormLogin() {
  const [email, setEmail] = useInput("");
  const [password, setPassword] = useInput("");

  const [visible, setVisible] = useState(false);

  const setLoginState = useLoginStore((s) => s.setState);
  const loginUser = authStore((s) => s.login);
  const errorLogin = authStore((s) => s.error);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password) {
      message.error("Cek kembali email dan password anda");
      return;
    }

    loginUser(email, password);
  };

  const handleClickQRCode = () => {
    setLoginState("code");
  };

  return (
    <>
      <AuthCard
        title="Selamat Datang di SIGAP"
        subtitle="Selamat mengubah warga desa menjadi luar biasa!"
      >
        <If condition={errorLogin}>
          <ErrorBox error="Email/No. Handphone atau Kata sandi salah" />
        </If>
        <form onSubmit={handleSubmit}>
          <Input
            label="Email atau nomor handphone"
            value={email}
            onChange={setEmail}
          />
          <Password
            label="Kata sandi"
            value={password}
            onChange={setPassword}
          />
          <Link to="/forgot-password">
            <Text align="right" color="$secondary" weight="$bold" mb="24px">
              Lupa Kata Sandi?
            </Text>
          </Link>
          <ButtonBlock color="secondary" htmlType="submit">
            Login
          </ButtonBlock>
          <Text my="12px" align="center">
            atau
          </Text>
          <ButtonBlock
            color="secondary-outline"
            htmlType="button"
            onClick={handleClickQRCode}
          >
            <HiOutlineQrcode />
            <Text as="span" ml="8px">
              Login dengan QR Code
            </Text>
          </ButtonBlock>
        </form>
        <Text my="16px" align="center">
          Belum punya akun?{" "}
          <Link to="/register">
            <Text as="span" weight="$bold" color="$secondary">
              Daftar di sini
            </Text>
          </Link>
        </Text>
        <Flex
          justify="center"
          align="center"
          gap="8px"
          css={{ cursor: "pointer" }}
          onClick={() => setVisible(true)}
        >
          <IoLogoWhatsapp color={getColor("secondary")} />
          <Text weight="bold" color="$secondary">
            Hubungi Kami
          </Text>
        </Flex>
        <ModalContact visible={visible} onCancel={() => setVisible(false)} />
      </AuthCard>
      <a
        href={`${APP_URL}/terms-and-condition.html`}
        target="_blank"
        rel="noreferrer"
      >
        <Text align="center" color="$secondary" weight="$bold" mt="24px">
          Kebijakan Privasi
        </Text>
      </a>
    </>
  );
}

export default FormLogin;
