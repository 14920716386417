import { DOCUMENT_FILES_EXTENSION } from "constants/app";
import { Card, Flex } from "components/Base";
import useReportStore from "features/reports/reportStore";
import If from "global/ErrorHandlers/If";
import DocumentList from "global/Post/components/DocumentList";
import ImageGroup from "global/Post/components/ImageGroup";
import TextContent from "global/Post/components/TextContent";
import { useMemo } from "react";
import { I7DReport } from "types/responses/7d";
import { getMediaExtension } from "utils/media";
import Header from "./Header";

interface ReportCardProps {
  report: I7DReport;
  isOffline?: boolean;
}

function ReportCard({ report }: ReportCardProps) {
  const [setActiveReport, setShowEdit, setShowDete] = useReportStore((s) => [
    s.setActiveReport,
    s.setShowModal,
    s.setShowDelete,
  ]);

  const filterMedia = (source: Array<any>, equality: boolean) => {
    const mediaList = report.media.filter((media) => {
      const ext = getMediaExtension(media.media);

      return source.includes(ext) === equality;
    });

    return mediaList.map((item) => item.media);
  };

  const handleEdit = () => {
    setActiveReport(report);
    setShowEdit(true);
  };

  const handleDelete = () => {
    setActiveReport(report);
    setShowDete(true);
  };

  const imageList = useMemo(() => {
    return filterMedia(DOCUMENT_FILES_EXTENSION, false);
  }, [report.media]);

  const documentList = useMemo(() => {
    return filterMedia(DOCUMENT_FILES_EXTENSION, true);
  }, [report.media]);

  return (
    <>
      <Card p="32px" w="100%">
        <Flex direction="column" gap="14px">
          <Header
            id={report.id}
            title={report.title}
            createdAt={report.created_at}
            updatedAt={report.updated_at}
            location={report.location}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <TextContent text={report.content} />
          <If condition={imageList.length > 0}>
            <ImageGroup images={imageList} />
          </If>
          <If condition={documentList.length > 0}>
            <DocumentList documents={documentList} />
          </If>
        </Flex>
      </Card>
    </>
  );
}

export default ReportCard;
