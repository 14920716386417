import { Flex } from "components/Base";
import CardContent from "global/Profile/CardContent";
import ProfileHero from "global/Profile/ProfileHero";
import StreamWidget from "global/StreamWidget";
import usePostStore from "lib/stores/entities/stream";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SideContent from "./components/SideContent";
import useUserStore from "./userStore";

function User() {
  const { id } = useParams();
  const [user, getUserDetail] = useUserStore((s) => [s.user, s.getUserDetail]);
  const [userPost] = usePostStore((s) => [s.userPost]);

  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, [id]);

  if (!id || !user[id]) {
    return null;
  }

  const detail = user[id];
  const postTotal = userPost[id]?.total || 0;

  return (
    <>
      <ProfileHero
        cover={detail.cover}
        photo={detail.photo}
        name={detail.name}
        verified={detail.verified === "1"}
      />
      <Flex w="100%" gap="16px">
        <SideContent user={detail} />
        <Flex as="main" flex="1">
          <CardContent title="Inspirasi Desa" transparent={postTotal > 0}>
            <StreamWidget userId={Number(id)} />
          </CardContent>
        </Flex>
      </Flex>
    </>
  );
}

export default User;
