import produce from "immer";
import profileApi, {
  UpdatePasswordParams,
  UpdateProfileParams,
} from "lib/api/profile";
import { GetListParams } from "types/api";
import { IUserPointHistory } from "types/responses/users";
import create from "zustand";

export type ProfileState =
  | "edit-profile"
  | "inspirasi"
  | "story"
  | "about"
  | "help"
  | "change-password"
  | "point-history"
  | "privacy-policy"
  | "level";

interface ProfileStore {
  profileState: ProfileState;
  points: Array<IUserPointHistory>;
  totalPointData: number;
  error: string;
  setProfileState: (state: ProfileState) => void;
  getPointHistory: (params: GetListParams) => void;
  updateProfile: (data: UpdateProfileParams) => Promise<boolean>;
  updatePassword: (data: UpdatePasswordParams) => Promise<boolean>;
}

const useProfileStore = create<ProfileStore>((set) => ({
  profileState: "edit-profile",
  points: [],
  error: "",
  totalPointData: 0,
  setProfileState: (state) => set({ profileState: state }),
  getPointHistory: async (params) => {
    try {
      const res = await profileApi.getUserPointHistory(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set({ points: res.data.data, totalPointData: res.data.total });
      }

      set(
        produce((draft) => {
          draft.points.push(...res.data.data);
          draft.totalPointData = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  updateProfile: async (data) => {
    try {
      const res = await profileApi.updateProfile(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  updatePassword: async (data) => {
    try {
      const res = await profileApi.updatePassword(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        set({ error: res.data.message });
        throw new Error(res.data.message);
      }

      set({ error: "" });
      return true;
    } catch (error: any) {
      if (error.response) {
        set({ error: error.response.data.message });
      }
      return false;
    }
  },
}));

export default useProfileStore;
