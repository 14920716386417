import { Card } from "components/Base";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import { useEffect } from "react";
import ChatContent from "./ChatContent";
import ChatHeader from "./ChatHeader";
import EmptyChat from "../EmptyChat";

function MainContent() {
  const activeChatRoom = useChatStore((s) => s.activeChatRoom);
  const [getDetailChat] = useChatStore((s) => [
    s.getChatRoomDetail,
    s.getChatList,
  ]);

  useEffect(() => {
    if (activeChatRoom) {
      getDetailChat(activeChatRoom);
    }
  }, [activeChatRoom]);

  return (
    <Card p="0px" flex="1" minHeight="690px">
      <If condition={!activeChatRoom}>
        <EmptyChat isInRoom />
      </If>
      <If condition={!!activeChatRoom}>
        <ChatHeader />
        <ChatContent />
      </If>
    </Card>
  );
}

export default MainContent;
