import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import { Text } from "components/Base";
import { styled } from "configs/stitches.config";
import { FC } from "react";
import { IoClose } from "react-icons/io5";
import BaseProps from "types/base";

export interface ModalProps extends AntModalProps {
  titleColor?: BaseProps["color"];
  css?: BaseProps["css"];
}

const Component = styled(AntModal, {
  "& .ant-modal-content": {
    br: "$rounded-lg",
  },
  "& .ant-modal-close-x": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .ant-modal-header": {
    borderTopLeftRadius: "$rounded-lg",
    borderTopRightRadius: "$rounded-lg",
    pb: "16px",
    borderBottom: "solid 1px #dcdcdc",
  },
  "& .ant-modal-body": {
    fontFamily: '"Quicksand", sans-serif',
    p: "16px",
  },
  "& .ant-modal-footer": {
    button: {
      br: "$rounded-md",
      height: "36px",
      padding: "0 16px",
      fontWeight: "$medium",
    },
  },
});

const Modal: FC<ModalProps> = ({ title = null, titleColor, ...props }) => {
  return (
    <Component
      title={
        title ? (
          <Text weight="$semibold" align="center" color={titleColor}>
            {title}
          </Text>
        ) : null
      }
      closeIcon={<IoClose size="20px" />}
      zIndex={1050}
      {...props}
    />
  );
};

Modal.defaultProps = {
  footer: null,
};

export default Modal;
