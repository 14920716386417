import authStore from "lib/stores/entities/auth";
import Mengenal7DPage from "pages/7d";
import Detail7DPage from "pages/7d/detail";
import ChatPage from "pages/chat";
import ExplorePage from "pages/explore";
import ForgotPasswordPage from "pages/forgot-password";
import HomePage from "pages/home";
import LoginPage from "pages/login";
import LogoutPage from "pages/logout";
import NotFoundPage from "pages/not-found";
import NotifPage from "pages/notification";
import PostDetailPage from "pages/post-detail";
import ProfilePage from "pages/profile";
import RegisterPage from "pages/register";
import ReportsPage from "pages/reports";
import DetailReportPage from "pages/reports/detail";
import PrintReportsPage from "pages/reports/print";
import ResetPasswordPage from "pages/reset-password";
import SearchPage from "pages/search";
import UserPage from "pages/user";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getFcmToken } from "utils/authStore";
import { requestToken } from "utils/firebase";
import PrivateRoute from "./PrivateRoute";
import RestrictedRoute from "./RestrictedRoute";

function AppRoutes() {
  const [token, setHasToken] = authStore((s) => [s.fcmToken, s.setHasFcmToken]);

  useEffect(() => {
    const localToken = getFcmToken();
    if (!localToken || !token) {
      requestToken(setHasToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Router>
      {/* basename is used when deploying to server */}
      <Routes>
        <Route path="/logout" element={<LogoutPage />} />

        <Route element={<RestrictedRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        </Route>

        <Route path="/reset-password/:code" element={<ResetPasswordPage />} />

        {/* Private routes will be wrapped here */}
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<ExplorePage />} />
          <Route path="/cerita/:id" element={<ExplorePage />} />
          <Route path="/notification" element={<NotifPage />} />

          <Route path="/7d" element={<Mengenal7DPage />} />
          <Route path="/7d/:id" element={<Detail7DPage />} />

          {/* 7d report */}
          <Route path="/reports/:id" element={<ReportsPage />} />
          <Route path="/reports/print/:id" element={<PrintReportsPage />} />
          <Route path="/report/detail/:id" element={<DetailReportPage />} />

          {/* User profile */}
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/user/:id" element={<UserPage />} />

          {/* Detail inspirasi desa */}
          <Route path="/inspirasi/:id" element={<PostDetailPage />} />

          <Route path="/chat" element={<ChatPage />} />
          <Route path="/search" element={<SearchPage />} />
        </Route>

        {/* Admin/User management */}
        {/* <Route path="/user-management" element={<UserManagementPage />} /> */}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
