import { Flex, Text } from "components/Base";

interface SectionTitleProps {
  title: string;
  subtitle?: string;
}

const DEFAULT_SUBTITLE =
  "Lorem ipsum dolor sit amet consectetur. Urna eleifend tellus sagittis ullamcorper velit nulla rhoncus tristique.";

function SectionTitle({ title, subtitle }: SectionTitleProps) {
  return (
    <Flex direction="column" align="center" gap="12px" mb="40px">
      <Text color="$secondary" weight="bold" fontSize="$2xl" lineHeight="32px">
        {title}
      </Text>
      <Text color="$coolGray700" fontSize="$2xl" lineHeight="25px">
        {subtitle || ""}
      </Text>
    </Flex>
  );
}

export default SectionTitle;
