import { Popover } from "antd";
import { Flex, Text } from "components/Base";
import useReportStore from "features/reports/reportStore";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import OptionItem from "./OptionItem";

interface HeaderMoreProps {
  onEdit: () => void;
  onDelete: () => void;
}

function HeaderMore({ onDelete, onEdit }: HeaderMoreProps) {
  return (
    <Popover
      trigger={["click"]}
      placement="bottomRight"
      content={
        <Flex direction="column" gap="8px">
          <OptionItem
            label="Edit"
            icon={<HiOutlinePencilAlt />}
            onClick={onEdit}
          />
          <OptionItem label="Hapus" icon={<HiTrash />} onClick={onDelete} />
        </Flex>
      }
    >
      <BsThreeDotsVertical cursor="pointer" />
    </Popover>
  );
}

export default HeaderMore;
