import { Flex, Image } from "components/Base";
import ComposePost from "global/Compose";
import If from "global/ErrorHandlers/If";
import ModalDelete from "global/Modals/ModalDelete";
import { GetPostListParams } from "lib/api/post";
import usePostStore from "lib/stores/entities/stream";
import { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Post from "./components/Post";

interface StreamWidgetProps {
  userId?: number;
  keyword?: string;
  guestMode?: boolean;
  category?: string;
}

const LIMIT = 10;
function StreamWidget({
  userId,
  keyword,
  guestMode = false,
  category,
}: StreamWidgetProps) {
  const [posts, userPost, total, getPost, getUserPost] = usePostStore((s) => [
    s.posts,
    s.userPost,
    s.total,
    s.getPostList,
    s.getUserPostList,
  ]);
  const [updatePost, deletePost] = usePostStore((s) => [
    s.updatePost,
    s.deletePost,
  ]);

  const [page, setPage] = useState(1);

  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);

  const [showEdit, setShowEdit] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const handleGetPost = (p = 1) => {
    const getter = userId ? getUserPost : getPost;
    const params: GetPostListParams = {
      page: p,
      limit: LIMIT,
    };

    if (userId) {
      params["user"] = String(userId);
    }

    if (keyword) {
      params["query"] = keyword;
    }

    if (category) {
      params["category"] = category;
    }

    if (page !== p) {
      setPage(p);
    }

    getter(params);
  };

  useEffect(() => {
    handleGetPost();
  }, [keyword, category]);

  const handleLoadMore = (isVisible: boolean) => {
    const postList = userId ? userPost[userId]?.data : posts;
    const totalPost = userId ? userPost[userId]?.total : total;

    if (isVisible) {
      if (postList.length >= totalPost) {
        return;
      }

      const newPage = page + 1;
      handleGetPost(newPage);
    }
  };

  const handleSubmitEdit = async (data: any) => {
    setIsLoadingEdit(true);
    const post = await updatePost(data);

    if (post) {
      const page = 1;
      handleGetPost(page);

      setIsLoadingEdit(false);
      handleCloseEdit();
    }
  };

  const handleEdit = (post: any) => {
    setSelectedPost(post);
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectedPost(null);
    setShowEdit(false);
    setIsLoadingEdit(false);
  };

  const handleShowDelete = (id: number) => {
    setShowDelete(true);
    setSelectedId(id);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedId(null);
  };

  const handleDelete = async () => {
    const del = await deletePost(selectedId);

    if (del) {
      handleCloseDelete();

      const page = 1;
      handleGetPost(page);
    }
  };

  const postList = userId ? userPost[userId]?.data : posts;
  const totalPost = userId ? userPost[userId]?.total : total;

  return (
    <Flex w="100%">
      <If condition={totalPost === 0}>
        <Flex h="500px" w="100%" align="center" justify="center">
          <Image src="/images/empty-post.png" />
        </Flex>
      </If>
      <If condition={totalPost > 0}>
        <Flex w="100%" direction="column">
          {(postList || [])?.map((post, index) => {
            return (
              <Post
                post={post}
                key={`post-${index}-${post.id}`}
                onEdit={() => handleEdit(post)}
                onDelete={() => handleShowDelete(Number(post.id))}
                showComment={!guestMode}
              />
            );
          })}

          <VisibilitySensor onChange={handleLoadMore}>
            <div>&nbsp;</div>
          </VisibilitySensor>
        </Flex>
      </If>
      <ModalDelete
        visible={showDelete}
        onCancel={handleCloseDelete}
        onDelete={handleDelete}
      />
      <ComposePost
        open={showEdit}
        onCancel={handleCloseEdit}
        initialValues={selectedPost}
        onSubmit={handleSubmitEdit}
        isLoading={isLoadingEdit}
      />
    </Flex>
  );
}

export default StreamWidget;
