import { Flex, Text } from "components/Base";
import useChatStore from "features/chat/chatStore";
import Avatar from "global/Avatar";
import If from "global/ErrorHandlers/If";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function ChatHeader() {
  const navigate = useNavigate();
  const [activeId, activeUser, roomDetail] = useChatStore((s) => [
    s.activeChatRoom,
    s.activeChatRoomUser,
    s.chatRoomDetail,
  ]);
  const [chatState, setChatState] = useChatStore((s) => [
    s.mainContentState,
    s.setMainContentState,
  ]);

  const handleClick = () => {
    if (!activeId) {
      return;
    }

    const activeChat = roomDetail[activeId];

    const id = activeChat?.id || activeUser?.id;

    if (activeChat.multi === "1") {
      setChatState("group-detail");
      return;
    }

    navigate(`/user/${id}`);
  };

  const handleCloseClick = () => {
    setChatState("chat-list");
  };

  if (!activeId) {
    return null;
  }

  const activeChat = roomDetail[activeId];

  const photo = activeChat?.media || activeUser?.photo || "";
  const name = activeChat?.name || activeUser?.name;

  return (
    <Flex p="15px 32px" w="100%" borderBottom="solid 1px $coolGray300">
      <If condition={chatState !== "group-detail"}>
        <Flex
          alignItems="center"
          gap="8px"
          cursor="pointer"
          onClick={handleClick}
        >
          <Avatar size={40} src={photo} alt={name} />
          <Text weight="$bold" size="$md">
            {name}
          </Text>
        </Flex>
      </If>
      <If condition={chatState === "group-detail"}>
        <Flex alignItems="center" py="11px" gap="12px">
          <IoMdClose cursor="pointer" onClick={handleCloseClick} />
          <Text weight="$bold">Info Grup</Text>
        </Flex>
      </If>
    </Flex>
  );
}

export default ChatHeader;
