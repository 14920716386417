import { Flex, Image, Text } from "components/Base";
import { IPostItem } from "types/responses/post";

interface CategoryProps {
  category: IPostItem["category"];
}

function Category({ category }: CategoryProps) {
  return (
    <Flex alignItems="center" gap="8px">
      <Image src="/icons/category.svg" css={{ w: 16 }} />
      <Text color="$blue2" weight="$semibold">
        {category.name}
      </Text>
    </Flex>
  );
}

export default Category;
