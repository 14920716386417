import { Button, Flex, Text } from "components/Base";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import { BiChevronLeft } from "react-icons/bi";
import { BsChatDotsFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";

function SideHeader() {
  const [state, setState] = useChatStore((s) => [
    s.sideContentState,
    s.setSideContentState,
  ]);

  return (
    <Flex alignItems="center" w="100%" gap="20px" mb="20px">
      <If condition={state === "home"}>
        <Button
          block
          color="secondary"
          shape="circle"
          css={{ fontWeight: "$bold", gap: "10px" }}
          onClick={() => setState("add-chat")}
        >
          <BsChatDotsFill /> Buat Chat
        </Button>
        <Button
          block
          color="secondary-outline"
          shape="circle"
          css={{ fontWeight: "$bold", gap: "10px" }}
          onClick={() => setState("add-group")}
        >
          <HiUserGroup /> Buat Grup
        </Button>
      </If>
      <If condition={state !== "home"}>
        <BiChevronLeft cursor="pointer" onClick={() => setState("home")} />
        <Text size="$lg">
          {state === "add-chat" ? "Kirim Pesan" : "Undang Warga ke Grup"}
        </Text>
      </If>
    </Flex>
  );
}

export default SideHeader;
