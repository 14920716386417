import { Card, Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { getColor } from "utils/general";

interface AuthCardProps {
  title: string;
  children: React.ReactNode;
  subtitle?: string | React.ReactNode;
  onBack?: () => void;
}

function AuthCard({ title, children, subtitle, onBack }: AuthCardProps) {
  return (
    <Card p="24px" w="590px" m="auto">
      <Flex direction="column" mb="16px" gap="8px">
        <Flex align="center" gap="24px">
          <If condition={!!onBack}>
            <HiArrowNarrowLeft
              color={getColor("secondary")}
              size={30}
              cursor="pointer"
              onClick={onBack}
            />
          </If>
          <Text
            fontSize="32px"
            as="h2"
            lineHeight="42px"
            weight="bold"
            color="$secondary"
          >
            {title}
          </Text>
        </Flex>
        <If condition={!!subtitle}>
          <If condition={typeof subtitle === "string"}>
            <Text fontSize="18px" lineHeight="25px">
              {subtitle}
            </Text>
          </If>
          <If condition={typeof subtitle !== "string"}>{subtitle}</If>
        </If>
      </Flex>
      {children}
    </Card>
  );
}

export default AuthCard;
