import PostDetail from "features/post/detail";
import MainLayout from "global/MainLayout";

function PostDetailPage() {
  return (
    <MainLayout>
      <PostDetail />
    </MainLayout>
  );
}

export default PostDetailPage;
