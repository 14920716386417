import { Flex } from "components/Base";
import PointCard from "features/explore/components/PointCard";
import profileStore from "lib/stores/entities/profile";
import Sidebar from "./Sidebar";
import useProfileStore from "../profileStore";

function SideContent() {
  const profile = profileStore((s) => s.user);
  const setProfileState = useProfileStore((s) => s.setProfileState);

  if (!profile) {
    return null;
  }

  return (
    <Flex as="aside" direction="column" w="388px" gap="18px">
      <PointCard onClick={() => setProfileState("level")} />
      <Sidebar />
    </Flex>
  );
}

export default SideContent;
