import Register from "features/register";
import MainLayout from "global/MainLayout";

function RegisterPage() {
  return (
    <MainLayout>
      <div style={{ margin: "30px 0 90px" }}>
        <Register />
      </div>
    </MainLayout>
  );
}

export default RegisterPage;
