import { Button, Flex, Text } from "components/Base";
import { Input, Textarea } from "components/Form";
import Avatar from "global/Avatar";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { BsCameraFill } from "react-icons/bs";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IAddChatRoom } from "types/responses/chat";
import { IUserList } from "types/responses/users";
import { getColor } from "utils/general";
import { convertToEventTarget } from "utils/input";

interface GroupInfoProps {
  selectedUsers: Array<IUserList>;
  onSubmit: (data: any) => void;
}

function GroupInfo({ selectedUsers, onSubmit }: GroupInfoProps) {
  const [name, setName] = useInput("");
  const [description, setDescription] = useInput("");

  const [media, setMedia] = useState<File | null>(null);

  useEffect(() => {
    return () => {
      setName(convertToEventTarget(""));
      setDescription(convertToEventTarget(""));
      setMedia(null);
    };
  }, []);

  const handleChangeMedia = (e: any) => {
    try {
      const file = e.target.files[0];
      if (file) {
        setMedia(file);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    const data: Omit<IAddChatRoom, "users"> = {
      name,
      description,
    };

    if (media) {
      data.media = media;
    }

    onSubmit(data);
  };

  const mediaUrl = media ? URL.createObjectURL(media) : "";

  return (
    <>
      <Text mb="12px">Tambahkan icon dan nama grup</Text>
      <Flex alignItems="center" gap="16px" mb="32px">
        <label htmlFor="group-media">
          <Avatar
            size={52}
            style={{ backgroundColor: getColor("primary"), cursor: "pointer" }}
            src={mediaUrl}
          >
            <BsCameraFill
              style={{ marginTop: 16 }}
              color={getColor("coolGray600")}
            />
          </Avatar>
        </label>
        <Input
          placeholder="Ketikan nama grup"
          maxLength={30}
          helper={`Maksimal ${name.length}/30`}
          value={name}
          onChange={setName}
        />
      </Flex>
      <Textarea
        label="Deskripsi grup"
        rows={5}
        placeholder="Ketikan penjelasan grup ini"
        value={description}
        onChange={setDescription}
      />
      <Flex direction="column" gap="12px" mt="32px" minHeight="300px">
        <Text weight="$bold" color="$secondary">
          Anggota Grup
        </Text>
        {selectedUsers.map((user) => (
          <Flex alignItems="center" gap="16px" key={`selected-user-${user.id}`}>
            <Avatar size={40} src={user.photo} name={user.name} />
            <Text weight="$bold">{user.name}</Text>
          </Flex>
        ))}
      </Flex>
      <Flex w="115px" mx="auto" mt="24px">
        <Button
          disabled={!name}
          color="secondary"
          shape="circle"
          m="auto"
          onClick={handleSubmit}
          block
        >
          <HiArrowNarrowRight />
        </Button>
      </Flex>
      <input
        type="file"
        id="group-media"
        hidden
        onChange={handleChangeMedia}
        accept="image/jpg, image/png, image/jpeg"
        style={{ display: "none" }}
      />
    </>
  );
}

export default GroupInfo;
