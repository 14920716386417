import request from "configs/request";
import requestBackoffice from "configs/requestBackoffice";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import { IUserDetail, IUserList } from "types/responses/users";

const getUserList = (
  params: GetListParams,
): APIPromisePagination<Array<IUserList>> => {
  return request.get("/get_user_list.php", {
    params: {
      query: params.query,
      page: params.page,
      size: params.limit,
    },
  });
};

const getUserDetail = (id: string): APIPromiseResponse<IUserDetail> => {
  return request.get("/get_user_detail.php", { params: { id } });
};

const getUserStats = () => requestBackoffice("/users/stats");

export default {
  getUserList,
  getUserDetail,
  getUserStats,
};
