import { Popover } from "antd";
import { Flex, Tag, Text } from "components/Base";
import Avatar from "global/Avatar";
import If from "global/ErrorHandlers/If";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IChatGetMemberList } from "types/responses/chat";

interface MemberItemProps {
  member: IChatGetMemberList;
  isAdmin?: boolean;
  onSetAdmin?: () => void;
  onRemove?: () => void;
}

function MemberItem({
  member,
  onRemove = () => {},
  onSetAdmin = () => {},
  isAdmin = false,
}: MemberItemProps) {
  return (
    <Flex alignItems="center" w="100%" justify="space-between" py="8px">
      <Flex alignItems="center" gap="16px">
        <Avatar src={member.photo} name={member.name} size={40} />
        <Flex direction="column" gap="4px">
          <Text weight="$bold" italic={!member.name}>
            {member.name || "User Tanpa Nama"}
          </Text>
          <If condition={member.admin === "1"}>
            <Tag rounded color="yellow">
              Admin Grup
            </Tag>
          </If>
        </Flex>
      </Flex>
      <If condition={member.admin === "0" && isAdmin}>
        <Popover
          trigger={["click"]}
          placement="bottomRight"
          content={
            <Flex direction="column" gap="8px" w="175px">
              <Text cursor="pointer" onClick={onSetAdmin}>
                Jadikan Admin
              </Text>
              <Text cursor="pointer" onClick={onRemove}>
                Keluarkan dari Grup
              </Text>
            </Flex>
          }
        >
          <BsThreeDotsVertical cursor="pointer" />
        </Popover>
      </If>
    </Flex>
  );
}

export default MemberItem;
