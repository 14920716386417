import { message, ModalProps } from "antd";
import { Button, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import { Textarea } from "components/Form";
import If from "global/ErrorHandlers/If";
import DocumentList from "global/Post/components/DocumentList";
import ImageGroup from "global/Post/components/ImageGroup";
import useInput from "hooks/useInput";
import { useCallback, useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { IOpenMapLocation } from "types/responses/location";
import { IAddPost, IPostItem, IPostUser } from "types/responses/post";
import { convertToEventTarget } from "utils/input";
import {
  filterDocumentMedia,
  filterImageMedia,
  filterVideoMedia,
} from "utils/media";
import FooterCompose from "./Footer";
import ComposeHeader from "./Header";

interface ComposePostProps extends ModalProps {
  initialValues?: IPostItem;
  onSubmit: (data: IAddPost) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

function ComposePost({
  initialValues,
  onSubmit,
  onCancel = () => {},
  isLoading = false,
  ...props
}: ComposePostProps) {
  const [content, setContent] = useInput("");

  const [categoryId, setCategoryId] = useState<any>(null);

  const [selectedUsers, setSelectedUsers] = useState<Array<IPostUser>>([]);
  const [location, setLocation] = useState<Partial<IOpenMapLocation> | null>(
    null,
  );

  // Assets
  const [imageList, setImageList] = useState<Array<any>>([]);
  const [documentList, setDocumentList] = useState<Array<any>>([]);

  const { open } = props;

  useEffect(() => {
    if (open && initialValues) {
      setContent(convertToEventTarget(initialValues.content));
      setCategoryId(initialValues.id_category);

      setSelectedUsers(initialValues.users_tag || []);

      if (initialValues.location && initialValues.lat && initialValues.lng) {
        setLocation({
          display_name: initialValues.location,
          lat: initialValues.lat,
          lon: initialValues.lng,
        });
      }

      const mediaList = initialValues.media.map((item) => item.media);
      const documents = filterDocumentMedia(mediaList);
      const images = filterImageMedia(mediaList);
      const videos = filterVideoMedia(mediaList);

      setDocumentList(documents);
      setImageList([...images, ...videos]);
    }
  }, [open, initialValues]);

  useEffect(() => {
    if (!open) {
      setContent(convertToEventTarget(""));
      setCategoryId(null);
      setSelectedUsers([]);
      setLocation(null);

      setImageList([]);
      setDocumentList([]);
    }
  }, [open]);

  const handleChangeDocs = (docs: Array<any>) => {
    setDocumentList([...documentList, ...docs]);
  };

  const handleChangeImages = (images: Array<any>) => {
    setImageList([...imageList, ...images]);
  };

  const handleCancel = useCallback(() => {
    setContent(convertToEventTarget(""));
    setCategoryId(null);
    setSelectedUsers([]);
    setLocation(null);

    setImageList([]);
    setDocumentList([]);

    onCancel();
  }, []);

  const handleSubmit = () => {
    if (!content || !categoryId) {
      message.error({
        content: "Kontent dan kategori harus diisi",
        className: "important-message",
      });
      return;
    }

    const media = [...imageList, ...documentList];
    const data: IAddPost = {
      id_category: categoryId,
      content,
      location: location?.display_name,
      lat: location?.lat,
      lng: location?.lon,
      // @ts-ignore
      media: media,
    };

    if (selectedUsers.length > 0) {
      data.users = `[${selectedUsers.map((item) => item.id).join(",")}]`;
    }

    if (initialValues) {
      const removedMedia = initialValues.media.filter(
        (item) => !media.includes(item.media),
      );
      const removedMediaIds = removedMedia.map((item) => item.id);

      const removedUsers = initialValues.users_tag.filter((user) => {
        const isExist = selectedUsers.find((item) => item.id === user.id);
        return !isExist;
      });

      const removedUserIds = removedUsers.map((item) => item.id);

      data.id = initialValues.id;
      data.removed_media = `[${removedMediaIds.join(",")}]`;
      data.removed_users = `[${removedUserIds.join(",")}]`;
    }

    onSubmit(data);
  };

  const isEdit = !!initialValues;
  return (
    <Modal
      width={650}
      title={`${isEdit ? "Edit" : "Buat"} Inspirasi Desa`}
      onCancel={handleCancel}
      {...props}
    >
      <ComposeHeader
        selectedUsers={selectedUsers}
        selectedCategory={categoryId}
        onChangeCategory={setCategoryId}
      />
      <Flex direction="column" w="100%" gap="16px" mb="16px">
        <If condition={!!location}>
          <Flex alignItems="center" gap="10px" color="$secondary">
            <MdLocationPin />
            <Text>{location?.display_name}</Text>
          </Flex>
        </If>
        <Textarea
          rows={5}
          placeholder="Apa yang ingin kamu ceritakan?"
          autoSize={{ minRows: 3 }}
          css={{ border: "none" }}
          value={content}
          onChange={setContent}
        />
        <Flex direction="column" gap="16px">
          <If condition={imageList.length > 0}>
            <ImageGroup
              images={imageList}
              onChangeImages={setImageList}
              removable
            />
          </If>
          <If condition={documentList.length > 0}>
            <DocumentList
              removable
              documents={documentList}
              onChangeDocs={setDocumentList}
            />
          </If>
        </Flex>
        <FooterCompose
          withLocation
          withPeople
          onChangeLocation={setLocation}
          onChangeDocs={handleChangeDocs}
          onChangeImage={handleChangeImages}
          onChangeUser={setSelectedUsers}
        />
      </Flex>
      <Button
        color="secondary"
        size="large"
        block
        shape="circle"
        onClick={handleSubmit}
        loading={isLoading}
      >
        <Text weight="$bold">Posting</Text>
      </Button>
    </Modal>
  );
}

export default ComposePost;
