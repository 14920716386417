import { APP_URL } from "constants/api";
import { ProfileState } from "./profileStore";

interface ProfileNav {
  icon: string;
  label: React.ReactNode;
  value: ProfileState;
  onClick?: () => void;
  idInput?: string;
}

export const PROFILE_HEADER: Record<ProfileState, any> = {
  "edit-profile": "Edit Profile",
  inspirasi: "Inspirasi Desa Saya",
  story: "Cerita Kamu",
  about: "Tentang Sigapp",
  help: "Bantuan",
  "change-password": "Ubah Kata Sandi",
  "point-history": "Riwayat Poin",
  "privacy-policy": "Kebijakan Privasi",
  level: "Level Kamu",
};

export const PROFILE_SIDE_NAVS: Array<ProfileNav> = [
  {
    icon: "pencil",
    label: PROFILE_HEADER["edit-profile"],
    value: "edit-profile",
  },
  {
    icon: "docs",
    label: PROFILE_HEADER.inspirasi,
    value: "inspirasi",
  },
  {
    icon: "point",
    label: PROFILE_HEADER["point-history"],
    value: "point-history",
  },
  {
    icon: "story",
    label: "Cerita Kamu",
    value: "story",
    idInput: "add-story-profile",
  },
  {
    icon: "sigapp",
    label: PROFILE_HEADER.about,
    value: "about",
  },
  {
    icon: "help",
    label: PROFILE_HEADER.help,
    value: "help",
  },
  {
    icon: "policy",
    label: PROFILE_HEADER["privacy-policy"],
    value: "privacy-policy",
    onClick: () => {
      window.open(`${APP_URL}/terms-and-condition.html`, "_blank");
    },
  },
  {
    icon: "lock",
    label: PROFILE_HEADER["change-password"],
    value: "change-password",
  },
];
