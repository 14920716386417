import { Card, Text } from "components/Base";
import If from "global/ErrorHandlers/If";

interface BoxBioProps {
  bio: string;
}

function BoxBio({ bio }: BoxBioProps) {
  return (
    <Card p="16px" w="100%">
      <Text color="$secondary" weight="$bold" lineHeight="23px" mb="4px">
        Biodata
      </Text>
      <Text color="$coolGray600" lineHeight="22px" align="justify">
        <If condition={!!bio}>{bio}</If>
        <If condition={!bio}>
          <Text italic color="$coolGray400">
            Tidak ada biodata
          </Text>
        </If>
      </Text>
    </Card>
  );
}

export default BoxBio;
