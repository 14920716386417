import { Flex } from "components/Base";
import ProfileHero from "global/Profile/ProfileHero";
import profileStore from "lib/stores/entities/profile";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import MainContent from "./components/MainContent";
import SideContent from "./components/SideContent";
import useProfileStore from "./profileStore";

function Profile() {
  const [search] = useSearchParams();
  const tab = search.get("tab") || "";

  const [profile] = profileStore((s) => [s.user]);
  const setProfileState = useProfileStore((s) => s.setProfileState);

  useEffect(() => {
    if (tab && tab === "level") {
      setProfileState("level");
      return;
    }

    setProfileState("edit-profile");
  }, [tab]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <ProfileHero
        cover={profile.cover}
        photo={profile.photo}
        name={profile.name}
        verified={profile.verified === "1"}
      />
      <Flex w="100%" gap="16px">
        <SideContent />
        <Flex as="main" flex="1">
          <MainContent />
        </Flex>
      </Flex>
    </>
  );
}

export default Profile;
