import produce from "immer";
import { GetPostlikeParams, GetPostListParams } from "lib/api/post";
import postApi from "lib/api/post";
import {
  IAddComment,
  IAddPost,
  IPostCategory,
  IPostComment,
  IPostItem,
  IPostUser,
} from "types/responses/post";
import create from "zustand";

interface UserPost {
  data: Array<IPostItem>;
  total: number;
}

interface PostLikes {
  data: Array<IPostUser>;
  total: number;
}

interface PostComments {
  data: Array<IPostComment>;
  total: number;
}

interface PostStore {
  postCategories: Array<IPostCategory>;

  posts: Array<IPostItem>;
  post: Record<string, IPostItem>;
  total: number;

  userPost: Record<string, UserPost>;

  postLikes: Record<string, PostLikes>;
  postComments: Record<string, PostComments>;

  getPostCategory: () => void;

  getPostList: (params: GetPostListParams) => void;
  getPostDetail: (id: string) => void;
  getUserPostList: (params: GetPostListParams) => void;
  addPost: (data: IAddPost) => Promise<boolean>;
  updatePost: (data: IAddPost) => Promise<boolean>;
  deletePost: (id: number) => Promise<boolean>;

  getPostLikes: (params: GetPostlikeParams) => void;
  likePost: (id: number) => Promise<boolean>;
  unlikePost: (id: number) => Promise<boolean>;

  getPostComments: (params: GetPostlikeParams) => void;
  addPostComment: (data: IAddComment) => Promise<boolean>;
  deletePostComment: (id: number) => Promise<boolean>;
}

const usePostStore = create<PostStore>((set, get) => ({
  postCategories: [],
  posts: [],
  post: {},
  total: 0,
  userPost: {},

  postLikes: {},
  postComments: {},

  getPostCategory: async () => {
    try {
      const res = await postApi.getPostCategory();

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      set({ postCategories: res.data });
    } catch (error) {
      console.log(error);
    }
  },

  getPostList: async (params) => {
    try {
      const res = await postApi.getPostList(params);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set({ posts: res.data.data, total: res.data.total });
      }

      set(
        produce((draft) => {
          draft.posts.push(...res.data.data);
          draft.total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  getPostDetail: async (id) => {
    try {
      const res = await postApi.getPostDetail(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      set(
        produce((draft) => {
          draft.post[id] = res.data;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  getUserPostList: async (params) => {
    try {
      if (!params.user) {
        throw new Error("user is required");
      }

      const res = await postApi.getPostList(params);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        console.log("Reset user post");
        return set(
          produce((draft: PostStore) => {
            // @ts-ignore
            draft.userPost[params.user] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      set(
        produce((draft) => {
          // @ts-ignore
          draft.userPost[params.user].data.push(...res.data.data);

          // @ts-ignore
          draft.userPost[params.user].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addPost: async (data) => {
    try {
      const res = await postApi.addPost(data);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  updatePost: async (data) => {
    try {
      const res = await postApi.updatePost(data);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deletePost: async (id) => {
    try {
      const res = await postApi.deletePost(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  getPostLikes: async (params) => {
    try {
      if (!params.id) {
        throw new Error("id is required");
      }

      const res = await postApi.getPostLikes(params);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set(
          produce((draft: PostStore) => {
            // @ts-ignore
            draft.postLikes[params.id] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      set(
        produce((draft: PostStore) => {
          draft.postLikes[params.id].data = res.data.data;
          draft.postLikes[params.id].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  likePost: async (id) => {
    try {
      const res = await postApi.likePost(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  unlikePost: async (id) => {
    try {
      const res = await postApi.unlikePost(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  getPostComments: async (params) => {
    try {
      if (!params.id) {
        throw new Error("id is required");
      }

      const res = await postApi.getPostComments(params);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set(
          produce((draft: PostStore) => {
            // @ts-ignore
            draft.postComments[params.id] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      set(
        produce((draft: PostStore) => {
          // @ts-ignore
          draft.postComments[params.id].data.push(...res.data.data);
          // @ts-ignore
          draft.postComments[params.id].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addPostComment: async (data) => {
    try {
      const res = await postApi.addPostComment(data);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deletePostComment: async (id) => {
    try {
      const res = await postApi.deletePostComment(id);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
}));

export default usePostStore;
