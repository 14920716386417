import { APP_URL } from "constants/api";
import { message } from "antd";

export const copyToClipboard = (urlParam: string) => {
  const url = APP_URL + urlParam;
  navigator.clipboard.writeText(url).then(() => {
    message.info("Link berhasil disalin");
  });
};

export const shareToWhatsapp = (urlParam: string, content: string) => {
  const url = APP_URL + urlParam;
  const title = content.length > 100 ? content.slice(0, 100) + "..." : content;

  const params = { text: `${title} \nBaca selengkapnya di ${url}` };
  const urlParams = new URLSearchParams(params).toString();

  window.open(`https://wa.me/?${urlParams}`, "_blank");
};
