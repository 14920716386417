import PrintReports from "features/reports/print";
import SEO from "global/SEO";

function PrintReportsPage() {
  return (
    <main className="printable-page">
      <SEO />
      <PrintReports />
    </main>
  );
}

export default PrintReportsPage;
