import AuthCard from "components/Auth/AuthCard";
import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import auth from "lib/api/auth";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useCountdownTimer } from "use-countdown-timer";
import { notifySuccess } from "utils/notification";
import useRegisterStore from "./registerStore";

const OTP_FIELD_LENGTH = 4;

function OTP() {
  const navigate = useNavigate();
  const email = useRegisterStore((s) => s.email);
  const verify = useRegisterStore((s) => s.verifyOTP);

  const [otp, setOtp] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (otp.length === OTP_FIELD_LENGTH) {
      verifyOtp();
    }
  }, [otp]);

  const { countdown, isRunning, start } = useCountdownTimer({
    timer: 1000 * 59,
    autostart: true,
  });

  const resendOTP = () => {
    auth.resendOtp(email);
    start();
  };

  const verifyOtp = async () => {
    const res = await verify(otp);

    if (res) {
      notifySuccess({
        message: "Berhasil memverifikasi kode OTP",
        description: "Silakan login untuk melanjutkan",
      });

      navigate("/login");
    } else {
      setIsError(true);
    }
  };

  return (
    <AuthCard
      title="Verifikasi"
      subtitle={
        <Text fontSize="18px" lineHeight="25px">
          Kode telah dikirimkan ke: <b>{email}</b>
        </Text>
      }
    >
      <Flex direction="column" alignItems="center" gap="10px" mb="40px">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          containerStyle="otp-container"
          inputStyle="otp-input"
          hasErrored={isError}
          errorStyle={{ borderColor: "red" }}
        />
        <If condition={isError}>
          <Text align="center" color="$red500">
            OTP yang kamu masukkan salah
          </Text>
        </If>
      </Flex>
      <Text align="center" color="$coolGray600" mb="8px">
        Belum menerima kode?
      </Text>
      <If condition={isRunning}>
        <Text align="center" color="$coolGray600" weight="$bold">
          00:{String(countdown / 1000).padStart(2, "0")}
        </Text>
      </If>
      <If condition={!isRunning}>
        <Text
          align="center"
          color="$secondary"
          css={{ cursor: "pointer" }}
          weight="bold"
          onClick={resendOTP}
        >
          Kirim ulang kode
        </Text>
      </If>
    </AuthCard>
  );
}

export default OTP;
