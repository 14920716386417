import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import profileStore from "lib/stores/entities/profile";
import { IPostItem } from "types/responses/post";
import PostCategory from "./PostCategory";

interface ComposeHeaderProps {
  selectedUsers: Array<IPostItem["user"]>;
  selectedCategory: string;
  onChangeCategory: (category: string) => void;
}

function ComposeHeader({
  selectedUsers,
  selectedCategory,
  onChangeCategory,
}: ComposeHeaderProps) {
  const user = profileStore((s) => s.user);

  if (!user) {
    return null;
  }

  return (
    <Flex w="100%" gap="14px">
      <Avatar size={42} src={user.photo} alt={user.name} />
      <Flex direction="column" flex="1" gap="8px">
        <Flex w="100%" gap="0 8px" wrap="wrap">
          <Text fontSize="$lg" weight="$bold">
            {user.name}
          </Text>
          <If condition={selectedUsers.length > 0}>
            <Text fontSize="$lg">bersama</Text>
            <Text weight="$bold" fontSize="$lg">
              {selectedUsers
                .slice(0, 2)
                .map((u) => u.name)
                .join(", ")}
            </Text>
            <If condition={selectedUsers.length > 2}>
              <Text fontSize="$lg">dan {selectedUsers.length - 2} lainnya</Text>
            </If>
          </If>
        </Flex>
        <PostCategory category={selectedCategory} onChange={onChangeCategory} />
      </Flex>
    </Flex>
  );
}

export default ComposeHeader;
