import { Avatar } from "antd";
import { Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";

interface ProfileHeroProps {
  cover: string;
  photo: string;
  name: string;
  verified?: boolean;
}

function ProfileHero({
  cover,
  photo,
  name,
  verified = false,
}: ProfileHeroProps) {
  return (
    <Flex
      w="100%"
      h="323px"
      br="$rounded-lg"
      align="center"
      justify="center"
      mb="40px"
      css={{
        backgroundImage: `url('${cover || "/images/cover-placeholder.jpg"}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Flex direction="column" alignItems="center" gap="32px">
        <Flex
          w="97px"
          h="97px"
          border="solid 5px #51AD4A"
          alignItems="center"
          justify="center"
          br="$rounded-full"
          position="relative"
        >
          <Avatar size={85} src={photo} />
          <If condition={verified}>
            <Image
              src="/icons/verified.svg"
              position="absolute"
              bottom="0"
              right="0"
              size="33px"
              autofit
            />
          </If>
        </Flex>
        <Text
          size="$2xl"
          weight="$bold"
          lineHeight="32px"
          align="center"
          color="$white"
          w="100%"
        >
          {name}
        </Text>
      </Flex>
    </Flex>
  );
}

export default ProfileHero;
