import { Flex, Grid } from "components/Base";
import { styled } from "configs/stitches.config";

const FRAME_MOBILE_WIDTH = "320px";
const FRAME_MOBILE_HEIGHT = "266px";
const FRAME_WIDTH = "547px";
const FRAME_HEIGHT = "296px";

export const SingleFlex = styled(Flex, {
  cursor: "pointer",
  w: "100%",
  h: FRAME_MOBILE_HEIGHT,

  "@sm": {
    w: FRAME_MOBILE_WIDTH,
  },

  "@md": {
    w: FRAME_WIDTH,
    h: FRAME_HEIGHT,
  },
});

export const MultipleGrid = styled(Grid, {
  cursor: "pointer",
  w: "100%",
  h: FRAME_MOBILE_HEIGHT,
  "@sm": {
    w: FRAME_MOBILE_WIDTH,
  },

  "@md": {
    w: FRAME_WIDTH,
    h: FRAME_HEIGHT,
  },
});

export const TopFullGrid = styled(Grid, {
  w: "100%",
  h: `calc(${FRAME_MOBILE_WIDTH} / 2)`,
  gap: "1px",
  mb: "1px",
  columns: "repeat(2, 1fr)",

  "@sm": {
    w: FRAME_MOBILE_WIDTH,
  },

  "@md": {
    w: FRAME_WIDTH,
    h: `calc(${FRAME_WIDTH} / 2)`,
  },
});

export const BottomFullGrid = styled(Grid, {
  w: "100%",
  h: `calc(${FRAME_MOBILE_WIDTH} / 3)`,
  gap: "1px",
  columns: "repeat(3, 1fr)",

  "@sm": {
    w: FRAME_MOBILE_WIDTH,
  },

  "@md": {
    w: FRAME_WIDTH,
    h: `calc(${FRAME_WIDTH} / 3)`,
  },
});
