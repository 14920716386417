import produce from "immer";
import chatApi from "lib/api/chat";
import { IChatDetail } from "types/responses/chat";
import useChatStore from ".";
import { ChatStore } from "./chatStore.types";

export const addNewChat = (chat: IChatDetail) => {
  const currentActiveRoom = useChatStore.getState().activeChatRoom;
  const isActiveRoom = Number(currentActiveRoom) === Number(chat.id_chat_room);

  useChatStore.setState(
    produce((draft: ChatStore) => {
      // Push new chat to chat list if it exists
      if (isActiveRoom) {
        draft.chatList[chat.id_chat_room].data.unshift(chat);
        return;
      }

      // find from chat room list if it exists
      const chatRoomIndex = draft.chatRoomList.findIndex(
        (item) => item.id === chat.id_chat_room,
      );
      if (chatRoomIndex !== -1) {
        // Set last message to newest chat message and add total unread count
        draft.chatRoomList[chatRoomIndex].unread_count += 1;
        draft.chatRoomList[chatRoomIndex].message = {
          id: chat.id,
          chat: chat.chat,
          created_at: chat.created_at,
          // @ts-ignore
          id_user: chat.id_user,
          media: chat.media,
        };

        // then push current id to top of chat room list
        const [chatRoom] = draft.chatRoomList.splice(chatRoomIndex, 1);
        draft.chatRoomList.unshift(chatRoom);

        return;
      }

      // if it doesn't exist, then add it to chat room list
      draft.chatRoomList.unshift({
        id: chat.id_chat_room,
        name: chat.room.name,
        media: chat.room.media,
        multi: chat.room.multi,
        created_at: chat.room.created_at,
        is_member: "1",
        id_chat: chat.id,
        c: chat.created_at,
        unread_count: 1,
        total: 0,
        total_read: 0,
        message: {
          id: chat.id,
          chat: chat.chat,
          created_at: chat.created_at,
          // @ts-ignore
          id_user: chat.id_user,
          media: chat.media,
        },
      });
    }),
  );
};

export const addNewGroupChat = async (id: number) => {
  // fetch chat room detail
  // add it to chat room list
  const chatListParam = {
    page: 1,
    limit: 1,
    room: String(id),
  };
  try {
    const [roomDetail, chatList] = await Promise.allSettled([
      chatApi.getChatRoomDetail(String(id)),
      chatApi.getChatList(chatListParam),
    ]);

    if (roomDetail.status !== "fulfilled" || chatList.status !== "fulfilled") {
      throw new Error("Error");
    }

    if (roomDetail.value.data.message || chatList.value.data.message) {
      throw new Error(
        roomDetail.value.data.message || chatList.value.data.message,
      );
    }

    const room = roomDetail.value.data;
    const lastChat = chatList.value.data.data[0];

    // Push it to chat room list
    useChatStore.setState(
      produce((draft: ChatStore) => {
        draft.chatRoomList.unshift({
          id: room.id,
          name: room.name,
          media: room.media,
          multi: room.multi,
          created_at: room.created_at,
          is_member: "1",
          id_chat: lastChat.id,
          c: lastChat.created_at,
          unread_count: 1,
          total: 0,
          total_read: 0,
          message: {
            id: lastChat.id,
            chat: lastChat.chat,
            created_at: lastChat.created_at,
            // @ts-ignore
            id_user: lastChat.id_user,
            media: lastChat.media,
          },
        });
      }),
    );
  } catch (error) {
    console.log(error);
  }
};
