import { GOOGLE_MAP_KEY } from "constants/app";
import GoogleMap from "google-map-react";

interface MapProps {
  lat: number;
  lng: number;
}

const MAP_PROPS = {
  // Monas
  center: {
    lat: -6.1754,
    lng: 106.827153,
  },
  zoom: 11,
};

function Map({ lat, lng }: MapProps) {
  return (
    <GoogleMap
      bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
      defaultCenter={{ ...MAP_PROPS.center }}
      center={{ lat, lng }}
      defaultZoom={MAP_PROPS.zoom}
    />
  );
}

export default Map;
