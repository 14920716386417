import { Flex, Tag, Text } from "components/Base";
import usePostStore from "lib/stores/entities/stream";
import { useEffect } from "react";

interface PostCategoryProps {
  selectedId: string;
  onChange: (value: string) => void;
}

function PostCategory({ selectedId, onChange }: PostCategoryProps) {
  const [categories, getCategories] = usePostStore((s) => [
    s.postCategories,
    s.getPostCategory,
  ]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
  }, [categories]);

  if (categories.length === 0) {
    return null;
  }

  return (
    <Flex w="100%" direction="column" gap="16px" mb="16px">
      <Text fontSize="$lg" weight="$bold" color="$coolGray700">
        Kategori Inspirasi
      </Text>
      <Flex w="100%" gap="8px" wrap="wrap">
        <Tag
          rounded
          key="all"
          color={selectedId === "" ? "yellow" : "gray"}
          onClick={() => onChange("")}
          css={{ cursor: "pointer" }}
        >
          Semua
        </Tag>
        {categories.map((c) => (
          <Tag
            rounded
            key={c.id}
            color={c.id === selectedId ? "yellow" : "gray"}
            onClick={() => onChange(c.id)}
            css={{ cursor: "pointer" }}
          >
            {c.name}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
}

export default PostCategory;
