import { Badge } from "antd";
import { Flex, Text } from "components/Base";
import { formatDistanceToNow } from "date-fns";
import Avatar from "global/Avatar";
import If from "global/ErrorHandlers/If";
import parse from "html-react-parser";
import { HiUserGroup } from "react-icons/hi";
import { IChatRoomList } from "types/responses/chat";
import { getColor } from "utils/general";

interface RoomItemProps {
  room: IChatRoomList;
  isActive?: boolean;
  onClick?: () => void;
}

function RoomItem({
  room,
  isActive = false,
  onClick = () => {},
}: RoomItemProps) {
  return (
    <Flex
      alignItems="center"
      w="100%"
      p="20px"
      bgColor={isActive ? "$secondary5" : "$white"}
      cursor="pointer"
      gap="16px"
      onClick={onClick}
      css={{
        "&:hover": {
          bgColor: "$secondary5",
        },
      }}
    >
      <Avatar src={room.media} name={room.name} size={52} />
      <Flex direction="column" gap="8px" flex="1">
        <Flex justify="space-between" w="100%">
          <Flex alignItems="center" gap="8px" w="180px">
            <If condition={room.multi === "1"}>
              <HiUserGroup color={getColor("secondary")} />
            </If>
            <Text weight="$bold" ellipsis>
              {room.name}
            </Text>
          </Flex>
          <Text color="$coolGray400" fontSize="$sm">
            {formatDistanceToNow(new Date(room.message.created_at))}
          </Text>
        </Flex>
        <Flex justify="space-between" w="100%" maxWidth="100%">
          <Text fontSize="$sm" color="$coolGray400" ellipsis w="230px">
            {parse(room.message.chat)}
          </Text>
          <If condition={room.unread_count > 0}>
            <Badge count={room.unread_count > 99 ? "99+" : room.unread_count} />
          </If>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default RoomItem;
