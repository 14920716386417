import auth from "lib/api/auth";
import { ILoginData, IRegisterData } from "types/responses/auth";
import create from "zustand";

interface RegisterStore {
  state: "form" | "otp";
  email: string;
  userId: string;
  setState: (state: "form" | "otp") => void;
  setEmail: (email: string) => void;
  register: (data: IRegisterData) => Promise<any>;
  verifyOTP: (code: string) => Promise<boolean>;
}

const useRegisterStore = create<RegisterStore>((set, get) => ({
  state: "form",
  email: "",
  userId: "",
  setState: (state) => set({ state }),
  setEmail: (email) => set({ email }),
  register: async (data) => {
    try {
      const res = await auth.register(data);

      if (res.status !== 200) {
        throw new Error("Something went wrong");
      }

      // login user data to get user id
      const loginData: ILoginData = {
        credentials: data.email,
        password: data.password,
        device_id: "web",
        fcm_token: "UNKNOWN",
        platform: "web-register",
        device: "web",
      };

      const login = await auth.login(loginData);

      if (login.status !== 200) {
        throw new Error("Something went wrong");
      }

      set({ email: data.email, state: "otp", userId: login.data.id_user });
      return res;
    } catch (error: any) {
      return error?.respose?.data;
    }
  },
  verifyOTP: async (code) => {
    const userId = get().userId;

    const res = await auth.verifyOtp(code, userId);

    if (res.status !== 200) {
      return false;
    }

    if (res.data.message) {
      return false;
    }

    return true;
  },
}));

export default useRegisterStore;
