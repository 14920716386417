import request from "configs/request";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import {
  I7DReport,
  I7DStep,
  I7DTake,
  IAdd7DReport,
  IDownload7DReport,
} from "types/responses/7d";
import { convertRequestToFormData } from "utils/request";

const get7dList = (): APIPromiseResponse<Array<I7DStep>> => {
  return request.get("/get_7d_list.php");
};

const get7dTake = (): APIPromiseResponse<Array<I7DTake>> => {
  return request.get("/get_7d_take_list.php");
};

const add7dTake = (id: number): APIPromiseResponse => {
  return request.post("/add_7d_take.php", {
    id,
  });
};

const update7dTakeStatus = (id: number): APIPromiseResponse => {
  return request.post("/update_7d_take_status.php", {
    id_7d_take: id,
    status: "done",
  });
};

export interface GetPostListParams extends Omit<GetListParams, "query"> {
  id: string;
  from?: string;
  to?: string;
}

const get7dPostList = (
  params: GetPostListParams,
): APIPromisePagination<Array<I7DReport>> => {
  return request.get("/get_7d_post_list.php", {
    params: {
      id_7d_take: params.id,
      page: params.page,
      size: params.limit,
    },
  });
};

const download7dPostList = (
  params: GetPostListParams,
): APIPromiseResponse<Array<IDownload7DReport>> => {
  return request.get("/get_7d_post_export.php", {
    params: {
      id_7d_take: params.id,
      start: params.from,
      end: params.to,
    },
  });
};

const get7dPostDetail = (id: string): APIPromiseResponse<I7DReport> => {
  return request.get("/get_7d_post_detail.php", {
    params: {
      id,
    },
  });
};

const add7dPost = (data: IAdd7DReport): APIPromiseResponse => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/add_7d_post.php", bodyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const update7dPost = (data: IAdd7DReport): APIPromiseResponse => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/update_7d_post.php", bodyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const delete7dPost = (id: number): APIPromiseResponse => {
  return request.post("/delete_7d_post.php", {
    id,
  });
};

export default {
  get7dList,
  get7dTake,
  add7dTake,
  update7dTakeStatus,

  get7dPostList,
  download7dPostList,
  get7dPostDetail,
  add7dPost,
  update7dPost,
  delete7dPost,
};
