import { AxiosResponse } from "axios";
import request from "configs/request";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import { IUserPointHistory, IUserProfile } from "types/responses/users";
import { convertToFormData } from "utils/general";

const getProfile = (): Promise<AxiosResponse<IUserProfile>> => {
  return request.get(`/get_me.php`);
};

export interface UpdatePasswordParams {
  oldPassword: string;
  newPassword: string;
}

/**
 * Update password will reset authentication, so after success
 * we expect user to re-login
 */
const updatePassword = ({
  oldPassword,
  newPassword,
}: UpdatePasswordParams): APIPromiseResponse => {
  return request.post("/update_password.php", {
    old_password: oldPassword,
    new_password: newPassword,
  });
};

const getUserProfile = (
  userId: number,
): Promise<AxiosResponse<IUserProfile>> => {
  return request.get("/get_user_detail.php", { params: { id: userId } });
};

const updateProfilePhoto = (media: File): APIPromiseResponse => {
  const formData = new FormData();
  formData.append("media", media);
  return request.post("/update_profile_photo.php", formData);
};

export interface UpdateProfileParams {
  name: string;
  bio: string;
  photo?: File | string;
  cover?: File | string;
}

const updateProfile = (data: UpdateProfileParams): APIPromiseResponse => {
  const bodyData = convertToFormData(data);

  return request.post("/update_profile.php", bodyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getUserPointHistory = (
  params: GetListParams,
): APIPromisePagination<Array<IUserPointHistory>> => {
  return request.get("/get_point_history_list.php", {
    params: {
      size: params.limit,
      page: params.page,
    },
  });
};

export default {
  getProfile,
  getUserProfile,
  getUserPointHistory,
  updatePassword,
  updateProfilePhoto,
  updateProfile,
};
