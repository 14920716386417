import { Box, Card, Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";

interface CardContentProps {
  title: string;
  transparent?: boolean;
  headerContent?: React.ReactNode;
}

function CardContent({
  title,
  transparent,
  children,
  headerContent = null,
}: React.PropsWithChildren<CardContentProps>) {
  if (transparent) {
    return (
      <Flex direction="column" w="100%">
        <Flex
          w="100%"
          p="25px 32px"
          borderBottom="solid 1px $coolGray300"
          bgColor="$white"
          br="8px 8px 0 0"
          justify="space-between"
          alignItems="center"
        >
          <Text weight="$bold">{title}</Text>
          <If condition={!!headerContent}>
            <Flex w="225px">{headerContent}</Flex>
          </If>
        </Flex>
        <Box w="100%">{children}</Box>
      </Flex>
    );
  }

  return (
    <Card p="0px" w="100%">
      <Flex
        w="100%"
        p="25px 32px"
        borderBottom="solid 1px $coolGray300"
        justify="space-between"
        alignItems="center"
      >
        <Text weight="$bold">{title}</Text>
        <If condition={!!headerContent}>
          <Flex w="225px">{headerContent}</Flex>
        </If>
      </Flex>
      <Box p="32px">{children}</Box>
    </Card>
  );
}

export default CardContent;
