import { Button, Flex, Text } from "components/Base";
import Avatar from "global/Avatar";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import userApi from "lib/api/user";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import { IPostUser } from "types/responses/post";
import { getColor } from "utils/general";

interface SearchUserProps {
  initialValues?: Array<IPostUser>;
  onDone: (users: Array<IPostUser>) => void;
  buttonWidth?: string;
  buttonText?: React.ReactNode;
  minimumMember?: number;
}

function SearchUser({
  initialValues,
  onDone,
  buttonWidth = "100%",
  buttonText,
  minimumMember = 2,
}: SearchUserProps) {
  const [query, setQuery] = useState("");

  const [users, setUsers] = useState<Array<IPostUser>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<IPostUser>>(
    initialValues || [],
  );

  const [timeInterval, setTimeInterval] = useState<any>(0);

  useEffect(() => {
    setQuery("");
  }, []);

  const handleSearch = (keyword: string) => {
    const params = {
      query: keyword,
      page: 1,
      limit: 15,
    };
    userApi
      .getUserList(params)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);

    if (timeInterval) {
      clearTimeout(timeInterval);
    }

    setTimeInterval(
      setTimeout(() => {
        handleSearch(value);
      }, 500),
    );
  };

  const handleSelectUser = (user: IPostUser) => {
    const isExist = selectedUsers.find((item) => item.id === user.id);
    if (!isExist) {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const handleRemoveUser = (user: IPostUser) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
  };

  const handleDone = () => {
    // Send selected users data back to parent component
    onDone(selectedUsers);
  };

  return (
    <>
      <Flex w="100%" alignItems="center" gap="8px" mb="16px">
        <InputSearch
          placeholder="Cari warga"
          w="100%"
          onChange={handleChange}
        />
      </Flex>
      <If condition={selectedUsers.length > 0}>
        <Flex
          direction="column"
          gap="16px"
          w="100%"
          pb="16px"
          mb="32px"
          borderBottom="solid 1px $coolGray600"
        >
          <Text fontSize="$sm" weight="$bold">
            Ditandai
          </Text>
          <Flex w="100%" gap="24px" wrap="wrap">
            {selectedUsers.map((user, index) => (
              <Flex
                direction="column"
                alignItems="center"
                gap="8px"
                css={{ maxWidth: 100 }}
                key={`tagged-users-${index}`}
              >
                <Flex w="40px" h="40px" position="relative">
                  <Avatar size={40} src={user.photo} name={user.name} />
                  <IoCloseCircle
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    color={getColor("red500")}
                    size={16}
                    onClick={() => handleRemoveUser(user)}
                    cursor="pointer"
                  />
                </Flex>
                <Text align="center">{user.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </If>
      <If condition={query.length === 0}>
        <Flex justify="center" align="center">
          <Text fontSize="$md" color="$coolGray400">
            Silakan input nama warga terlebih dahulu
          </Text>
        </Flex>
      </If>
      <If condition={query.length > 0 && users.length > 0}>
        <Flex
          direction="column"
          gap="16px"
          w="100%"
          minHeight="300px"
          maxHeight="500px"
          overflow="auto"
        >
          {users.map((user, index) => {
            const isSelected = selectedUsers.find(
              (item) => item.id === user.id,
            );

            return (
              <Flex
                alignItems="center"
                gap="16px"
                key={`${index}`}
                cursor="pointer"
                onClick={() => handleSelectUser(user)}
              >
                <Flex w="40px" h="40px" position="relative">
                  <Avatar size={40} src={user.photo} />
                  <If condition={!!isSelected}>
                    <IoIosCheckmarkCircle
                      style={{ position: "absolute", bottom: 0, right: 0 }}
                      color={getColor("secondary")}
                      size={16}
                    />
                  </If>
                </Flex>
                <Text weight="$bold" fontSize="$md">
                  {user.name}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </If>

      <Flex w={buttonWidth} mx="auto" mt="24px">
        <Button
          disabled={selectedUsers.length < minimumMember}
          color="secondary"
          shape="circle"
          m="auto"
          onClick={handleDone}
          block
        >
          {buttonText || "Selanjutnya"}
        </Button>
      </Flex>
    </>
  );
}

export default SearchUser;
