import request from "configs/request";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import { IPostUser } from "types/responses/post";
import { IAddStory, IStory, IStoryItem } from "types/responses/story";
import { convertRequestToFormData } from "utils/request";

const getStories = (): APIPromiseResponse<{
  mine: Array<IStory>;
  stories: Array<IStoryItem>;
}> => {
  return request.get("/get_story_list.php");
};

export interface GetStoryViewParams extends GetListParams {
  id: string;
}

const getStoryViewers = (
  params: GetStoryViewParams,
): APIPromisePagination<Array<IPostUser>> => {
  return request.get("/get_story_view_list.php", {
    params: {
      id: params.id,
      page: params.page,
      size: params.limit,
    },
  });
};

const addStory = (data: IAddStory): APIPromiseResponse<IStory> => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/add_story.php", bodyData);
};

const viewStory = (id: string): APIPromiseResponse => {
  return request.post("/add_story_view.php", { id });
};

const deleteStory = (id: string): APIPromiseResponse => {
  return request.post("/delete_story.php", { id });
};

const getStoryLikes = (
  params: GetStoryViewParams,
): APIPromisePagination<Array<IPostUser>> => {
  return request.get("/get_story_like_list.php", {
    params: {
      id: params.id,
      page: params.page,
      size: params.limit,
    },
  });
};

const likeStory = (id: string): APIPromiseResponse => {
  return request.post("/add_story_like.php", { id });
};

const unlikeStory = (id: string): APIPromiseResponse => {
  return request.post("/delete_story_like.php", { id });
};

const getStoryArchive = (params: GetListParams): APIPromiseResponse => {
  return request.get("/get_archive_story_list.php", { params });
};

export default {
  getStories,
  getStoryViewers,
  addStory,
  viewStory,
  deleteStory,

  getStoryLikes,
  likeStory,
  unlikeStory,

  getStoryArchive,
};
