import { Flex, Text } from "components/Base";
import { format } from "date-fns";
import If from "global/ErrorHandlers/If";
import parse from "html-react-parser";
import { IChatDetail } from "types/responses/chat";

interface ChatInfoProps {
  chat: IChatDetail;
  isMessage?: boolean;
}

function ChatInfo({ chat, isMessage = false }: ChatInfoProps) {
  return (
    <Flex
      w="fit-content"
      mx="auto"
      my="6px"
      bgColor="$secondary4"
      p={isMessage ? "10px 20px" : "5px 10px"}
      br="$rounded-md"
    >
      <Text color="$secondary" weight={!isMessage ? "$bold" : "$regular"}>
        <If condition={isMessage}>{parse(chat.chat)}</If>
        <If condition={!isMessage}>
          {format(new Date(chat.created_at), "dd MMMM yyyy")}
        </If>
      </Text>
    </Flex>
  );
}

export default ChatInfo;
