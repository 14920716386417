import Card7D from "features/home/components/list-7d/Card7D";
import dApi from "lib/api/7d";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMengenal7DStore from "../mengenal7dStore";

const sliderSetting = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  swipeToSlide: true,
  className: "slider-7d",
};

function List7D() {
  const navigate = useNavigate();

  const [list, getList] = useMengenal7DStore((s) => [s.list, s.getList]);

  useEffect(() => {
    getList();
  }, []);

  const handleClick = (id: string) => {
    navigate(`/7d/${id}`);
  };

  return (
    <Slider {...sliderSetting}>
      {list.map((item, index) => (
        <Card7D
          title={item.title}
          color={item.color}
          position={item.circle_position}
          key={`7d-card-${index}`}
          css={{ w: "170px", cursor: "pointer" }}
          onClick={() => handleClick(item.id)}
        />
      ))}
    </Slider>
  );
}

export default List7D;
