import { differenceInDays } from "date-fns";
import produce from "immer";
import notif from "lib/api/notif";
import { INotifItem } from "types/responses/notif";
import create from "zustand";

export interface NotifGroup {
  title: string;
  data: Array<INotifItem>;
}

interface NotifStore {
  notifList: Array<NotifGroup>;
  getNotifList: () => void;
  readNotif: (id: number) => void;
}

const groupDataByRange = (
  data: Array<INotifItem>,
  minRange: number,
  maxRange: number,
) => {
  const today = new Date();

  const filetedNotif = data.filter((notif) => {
    const notifDate = new Date(notif.created_at);
    const diffDay = differenceInDays(today, notifDate);

    return diffDay >= minRange && diffDay <= maxRange;
  });

  return filetedNotif;
};

const useNotifStore = create<NotifStore>((set) => ({
  notifList: [],
  getNotifList: async () => {
    try {
      const res = await notif.getNotifList();

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set(
        produce((draft: NotifStore) => {
          // group data by its created date to today, weekly, monthly, and older

          const today = groupDataByRange(data.data, 0, 0);
          const weekly = groupDataByRange(data.data, 1, 7);
          const monthly = groupDataByRange(data.data, 8, 30);
          const older = data.data.filter(
            (notif) =>
              differenceInDays(new Date(), new Date(notif.created_at)) > 30,
          );

          draft.notifList = [
            {
              title: "Hari ini",
              data: today,
            },
            {
              title: "Minggu ini",
              data: weekly,
            },
            {
              title: "Bulan ini",
              data: monthly,
            },
            {
              title: "Lebih lama",
              data: older,
            },
          ];
        }),
      );
    } catch (err) {
      return err;
    }
  },
  readNotif: async (id) => {
    try {
      const res = await notif.readNotif(id);

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set(
        produce((draft: NotifStore) => {
          draft.notifList = draft.notifList.map((group) => {
            const newData = group.data.map((notif) => {
              if (Number(notif.id) === Number(id)) {
                return {
                  ...notif,
                  opened: "1",
                };
              }

              return notif;
            });

            return {
              ...group,
              data: newData,
            };
          });
        }),
      );
    } catch (err) {
      return err;
    }
  },
}));

export default useNotifStore;
