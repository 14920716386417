import { Card, Flex } from "components/Base";
import { styled } from "configs/stitches.config";

export const CardWrapper = styled(Flex, {
  justify: "center",
  flexWrap: "wrap",
  gap: "20px",

  "@lg": {
    gap: "30px 20px",
  },

  "@xl": {
    gap: "40px 20px",
  },
});

export const StyledCard = styled(Card, {
  boxShadow: "none",
  br: "18px",
  p: "20px",
  position: "relative",
  overflow: "hidden",
  w: "165px",
  h: "196px",

  "& .caption-7d": {
    fontWeight: "$bold",
    lineHeight: "28px",
    fontSize: "$xl",
  },

  "@md": {
    w: "183px",
    h: "220px",

    "& .caption-7d": {
      lineHeight: "30px",
      fontSize: "22px",
    },
  },

  "@xl": {
    w: "223px",
    h: "265px",

    "& .caption-7d": {
      lineHeight: "32px",
      fontSize: "$2xl",
    },
  },
});

export const OuterCircle = styled(Flex, {
  w: "160px",
  h: "160px",
  br: "$rounded-full",
  p: "16px",
  position: "absolute",
  bottom: "-50%",

  "@md": {
    bottom: "-40%",
  },

  "@lg": {
    bottom: "-30%",
  },
});

export const InnerCircle = styled(Flex, {
  w: "96px",
  h: "96px",
  br: "$rounded-full",
});
