import Detail7D from "features/7d/detail";
import MainLayout from "global/MainLayout";
import PageHeader from "global/PageHeader";
import PageTitle from "global/PageTitle";

function Detail7DPage() {
  return (
    <MainLayout>
      <PageTitle backUrl="/7d" title="Detail" />
      <Detail7D />
    </MainLayout>
  );
}

export default Detail7DPage;
