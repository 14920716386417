import { Image, Flex, Box, Button } from "components/Base";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import useInput from "hooks/useInput";
import authStore from "lib/stores/entities/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "utils/authStore";
import { convertToEventTarget } from "utils/input";
import ButtonAuth from "./ButtonAuth";
import Navigation from "./Navigation";
import { DesktopWrapper } from "./styled";
import UserInfo from "./UserInfo";

// Header for logged in users
function Header() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const authUser = authStore((state) => state.user);
  const [search, setSearch] = useInput("");

  useEffect(() => {
    const token = authUser || getToken();

    if (token) {
      setLoggedIn(true);
    }
  }, [authUser]);

  const handleSearch = () => {
    setSearch(convertToEventTarget(""));
    navigate(`/search?q=${search}`);
  };

  return (
    <DesktopWrapper as="header">
      <Box w="1196px" h="100%" m="0 auto">
        <Flex justify="space-between" align="center" h="100%">
          <If condition={loggedIn}>
            <Flex align="center" gap="32px">
              <Link to="/home">
                <Image
                  src="/logo-squared.png"
                  height="40px"
                  alt="Sigapp logo"
                />
              </Link>
              <InputSearch
                w="268px"
                value={search}
                onChange={setSearch}
                onPressEnter={handleSearch}
              />
            </Flex>
            <Navigation />
            <UserInfo />
          </If>

          <If condition={!loggedIn}>
            <Flex align="center" gap="32px">
              <Link to="/">
                <Image src="/logo.png" height="40px" alt="Sigapp logo" />
              </Link>
            </Flex>
            <InputSearch
              w="580px"
              value={search}
              onChange={setSearch}
              onPressEnter={handleSearch}
            />
            <ButtonAuth />
          </If>
        </Flex>
      </Box>
    </DesktopWrapper>
  );
}

export default Header;
