import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { formatDistanceToNow } from "date-fns";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { INotifItem } from "types/responses/notif";
import useNotifStore from "../notifStore";

interface NotifItemProps {
  notif: INotifItem;
}

function NotifItem({ notif }: NotifItemProps) {
  const navigate = useNavigate();
  const readNotif = useNotifStore((s) => s.readNotif);

  const isOpened = notif.opened === "1";

  const handleClick = () => {
    if (!isOpened) {
      readNotif(Number(notif.id));
    }

    if (notif.action === "OPEN_CHAT_DETAIL") {
      navigate(`/chat`);
      return;
    }

    if (notif.action === "OPEN_POST_DETAIL") {
      navigate(`/inspirasi/${notif.data}`);
      return;
    }
  };

  return (
    <Flex
      p="16px"
      gap="16px"
      alignItems="center"
      cursor="pointer"
      css={{ background: !isOpened ? "$secondary4" : "transparent" }}
      onClick={handleClick}
      w="100%"
    >
      <Avatar size={52} src={notif.photo} />
      <Flex direction="column" width="calc(100% - 68px)" gap="8px">
        <Text>{parse(notif.message)}</Text>
        <Text size="$sm" color="$coolGray400">
          {formatDistanceToNow(new Date(notif.created_at))}
        </Text>
      </Flex>
    </Flex>
  );
}

export default NotifItem;
