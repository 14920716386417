import dApi from "lib/api/7d";
import { I7DStep, I7DTake } from "types/responses/7d";
import create from "zustand";

interface Mengenal7DStore {
  list: Array<I7DStep>;
  takeList: Array<I7DTake>;
  getList: () => void;
  getTakeList: () => void;
}

const useMengenal7DStore = create<Mengenal7DStore>((set) => ({
  list: [],
  takeList: [],
  getList: async () => {
    try {
      const res = await dApi.get7dList();

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set({ list: data });
    } catch (error) {
      return error;
    }
  },
  getTakeList: async () => {
    try {
      const res = await dApi.get7dTake();

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set({ takeList: data });
    } catch (error) {
      return error;
    }
  },
}));

export default useMengenal7DStore;
