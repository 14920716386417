import { Avatar } from "antd";
import { Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { IPostUser } from "types/responses/post";
import { IStory } from "types/responses/story";

interface StoryItemProps {
  user: IPostUser;
  stories: Array<IStory>;
  mine?: boolean;
  onClick?: () => void;
}

function StoryItem({
  mine = false,
  user,
  stories,
  onClick = () => {},
}: StoryItemProps) {
  const viewed = stories.every((story) => story.viewed === "1");
  const borderColor = viewed ? "$coolGray300" : "#51AD4A";
  return (
    <Flex
      direction="column"
      css={{ maxWidth: "84px" }}
      gap="8px"
      alignItems="center"
    >
      <Flex
        w="84px"
        h="84px"
        br="$rounded-full"
        border={`3px solid ${borderColor}`}
        align="center"
        justify="center"
        position="relative"
        cursor="pointer"
      >
        <Avatar size={76} src={user.photo} onClick={onClick} />
        <If condition={mine}>
          <label htmlFor="add-story">
            <Image
              src="/icons/add-story.png"
              position="absolute"
              bottom="-10px"
              right="-10px"
              style={{ cursor: "pointer" }}
            />
          </label>
        </If>
      </Flex>
      <Text fontSize="$sm" w="100%" ellipsis align="center">
        {mine ? "Cerita Kamu" : user.name}
      </Text>
    </Flex>
  );
}

export default StoryItem;
