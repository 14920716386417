import { Button, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";

interface ModalDeleteProps {
  visible: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  onDelete: (params?: any) => void;
  onCancel: (params?: any) => void;
}

function ModalDelete({
  visible,
  title = "Konfirmasi Hapus",
  onDelete,
  onCancel,
  children,
}: ModalDeleteProps) {
  return (
    <Modal
      title={
        <Text weight="$bold" align="center">
          {title}
        </Text>
      }
      open={visible}
      onOk={onDelete}
      onCancel={onCancel}
      footer={null}
    >
      <Flex justify="center">
        {children ? children : "Ini akan menghapus data secara permanen"}
      </Flex>

      <Flex justify="center" alignItems="center" gap="12px" mt="20px">
        <Button shape="circle" color="secondary-outline" onClick={onCancel}>
          Batalkan
        </Button>
        <Button shape="circle" color="secondary" onClick={onDelete}>
          Ya, Hapus
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalDelete;
