import {
  AUDIO_FILES_EXTENSION,
  DOCUMENT_FILES_EXTENSION,
  IMAGE_FILES_EXTENSION,
  VIDEO_FILES_EXTENSION,
} from "constants/app";
import { capitalizeFirstLetter } from "./general";

export const getMediaName = (unparsedUrl: string) => {
  if (!unparsedUrl) return "-";

  let url = unparsedUrl.substr(1 + unparsedUrl.lastIndexOf("/"));
  const splittedUrl = url.split("-");
  url = splittedUrl.length > 3 ? url.split("-").slice(3).join(" ") : url;

  return capitalizeFirstLetter(url);
};

export const getMediaExtension = (unparsedUrl: string) => {
  if (!unparsedUrl) return "-";

  const url = unparsedUrl.substr(1 + unparsedUrl.lastIndexOf("/"));
  const splittedUrl = url.split(".");
  return splittedUrl[splittedUrl.length - 1].toLowerCase();
};

export const downloadMedia = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", getMediaName(url));
  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const filterImageMedia = (media: Array<string>) => {
  return media.filter((mediaUrl) => {
    const extension = getMediaExtension(mediaUrl);
    return IMAGE_FILES_EXTENSION.includes(extension);
  });
};

export const filterVideoMedia = (media: Array<string>) => {
  return media.filter((mediaUrl) => {
    const extension = getMediaExtension(mediaUrl);
    return VIDEO_FILES_EXTENSION.includes(extension);
  });
};

export const filterAudioMedia = (media: Array<string>) => {
  return media.filter((mediaUrl) => {
    const extension = getMediaExtension(mediaUrl);
    return AUDIO_FILES_EXTENSION.includes(extension);
  });
};

export const filterDocumentMedia = (media: Array<string>) => {
  return media.filter((mediaUrl) => {
    const extension = getMediaExtension(mediaUrl);
    return DOCUMENT_FILES_EXTENSION.includes(extension);
  });
};
