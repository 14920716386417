import Explore from "features/explore";
import MainLayout from "global/MainLayout";

function ExplorePage() {
  return (
    <MainLayout>
      <Explore />
    </MainLayout>
  );
}

export default ExplorePage;
