import request from "configs/request";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import {
  IAddComment,
  IAddPost,
  IPostCategory,
  IPostComment,
  IPostItem,
  IPostUser,
} from "types/responses/post";
import { convertRequestToFormData } from "utils/request";

const getPostCategory = (): APIPromiseResponse<Array<IPostCategory>> => {
  return request.get("/get_post_category_list.php");
};

export interface GetPostListParams extends GetListParams {
  user?: string;
  category?: string;
}

const getPostList = (
  params: GetPostListParams,
): APIPromisePagination<Array<IPostItem>> => {
  return request.get("/get_post_list.php", {
    params: {
      query: params.query,
      page: params.page,
      size: params.limit,
      user: params.user,
      category: params.category,
    },
  });
};

const getPostDetail = (id: string): APIPromiseResponse<IPostItem> => {
  return request.get("/get_post_detail.php", { params: { id } });
};

const addPost = (data: IAddPost): APIPromiseResponse<IPostItem> => {
  const formData = convertRequestToFormData(data);

  return request.post("/add_post.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updatePost = (data: IAddPost): APIPromiseResponse<IPostItem> => {
  const formData = convertRequestToFormData(data);

  return request.post("/update_post.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const deletePost = (id: number): APIPromiseResponse<IPostItem> => {
  return request.post("/delete_post.php", { id });
};

export interface GetPostlikeParams extends GetListParams {
  id: string;
}

const getPostLikes = (
  params: GetPostlikeParams,
): APIPromisePagination<Array<IPostUser>> => {
  return request.get("/get_post_like_list.php", {
    params: {
      id: params.id,
      page: params.page,
      size: params.limit,
    },
  });
};

const likePost = (id: number): APIPromiseResponse => {
  return request.post("/add_post_like.php", { id });
};

const unlikePost = (id: number): APIPromiseResponse => {
  return request.post("/delete_post_like.php", { id });
};

const getPostComments = (
  params: GetPostlikeParams,
): APIPromisePagination<Array<IPostComment>> => {
  return request.get("/get_post_comment_list.php", {
    params: {
      id: params.id,
      page: params.page,
      size: params.limit,
    },
  });
};

const addPostComment = (data: IAddComment): APIPromiseResponse => {
  return request.post("/add_post_comment.php", data);
};

const deletePostComment = (id: number): APIPromiseResponse => {
  return request.post("/delete_post_comment.php", { id });
};

export default {
  getPostCategory,
  getPostList,
  getPostDetail,
  addPost,
  updatePost,
  deletePost,

  getPostLikes,
  likePost,
  unlikePost,

  getPostComments,
  addPostComment,
  deletePostComment,
};
