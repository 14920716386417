export const convertRequestToFormData = (data: Record<string, any>) => {
  let convertedData = new FormData();

  // eslint-disable-next-line guard-for-in
  for (let key in data) {
    if (!data[key]) {
      continue;
    }

    if (!Array.isArray(data[key])) {
      convertedData.append(key, data[key]);
    } else {
      data[key].forEach((item: any) => {
        convertedData.append(`${key}[]`, item);
      });
    }
  }

  return convertedData;
};
