import { AxiosResponse } from "axios";
import request from "configs/request";

const getContactList = (): Promise<AxiosResponse> => {
  return request.get("/get_contact_us_list.php");
};

interface ILevelItem {
  color: string;
  created_at: string;
  end_score: string;
  id: string;
  media: string;
  name: string;
  slug: string;
  speciality: string;
  start_score: string;
}

const getBadgeList = (): Promise<AxiosResponse<ILevelItem[]>> => {
  return request.get("/get_badge_list.php");
};

export default {
  getContactList,
  getBadgeList,
};
