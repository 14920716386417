import { Spin } from "antd";
import { Flex, Text } from "components/Base";
import Modal, { ModalProps } from "components/Feedback/Modal";
import { useEffect, useState } from "react";
import { useModalReportStore } from "./modalReportStore";
import ModalInfo from "../ModalInfo";

interface ModalReportProps extends ModalProps {}

function ModalReport({ ...props }: ModalReportProps) {
  // Report reasons
  const [reportReasons, getReportReasons] = useModalReportStore((s) => [
    s.reportReasons,
    s.getReportReasons,
  ]);

  const [reportedItemId, reportType] = useModalReportStore((s) => [
    s.idReportedItem,
    s.reportedItemType,
  ]);
  const [isLoading, reportCerita, reportUser, reportStory] =
    useModalReportStore((s) => [
      s.isLoading,
      s.postReportCerita,
      s.postReportUser,
      s.postReportStory,
    ]);

  // Modal effects handler
  const [showSucces, setShowSuccess] = useState(false);
  const [showModalReport, setReportedItem] = useModalReportStore((s) => [
    s.showModalReport,
    s.setReportedItem,
  ]);

  useEffect(() => {
    getReportReasons();
  }, []);

  const handleCloseModal = () => {
    setReportedItem("");
  };

  const handleReport = async (reasonId: string) => {
    const data = {
      id: Number(reportedItemId),
      id_reason: Number(reasonId),
      reason: "",
    };

    if (reportType === "user") {
      // @ts-ignore
      data.block = true;
    }

    const handler: Record<string, any> = {
      post: reportCerita,
      user: reportUser,
      story: reportStory,
    };

    if (!handler[reportType]) {
      return;
    }

    const res = await handler[reportType](data);

    if (res) {
      handleCloseModal();
      setShowSuccess(true);
    }
  };

  return (
    <>
      <Modal
        open={showModalReport}
        title="Mengapa kamu ingin melaporkan ini?"
        titleColor="$red500"
        onCancel={handleCloseModal}
        centered
        {...props}
      >
        <Spin spinning={isLoading}>
          <Flex direction="column" gap="16px">
            {reportReasons.map((reason) => {
              if (reason.id === "0") {
                return null;
              }

              return (
                <Text
                  key={`report-reason${reason.id}`}
                  style={{ cursor: "pointer" }}
                  size="$md"
                  onClick={() => handleReport(reason.id)}
                >
                  {reason.reason}
                </Text>
              );
            })}
          </Flex>
        </Spin>
      </Modal>
      <ModalInfo
        visible={showSucces}
        onConfirm={() => setShowSuccess(false)}
        title="Terima kasih telah memberi tahu kami"
        confirmText="Tutup"
      >
        Laporan kamu akan kami validasi
      </ModalInfo>
    </>
  );
}

export default ModalReport;
