import { VIDEO_FILES_EXTENSION } from "constants/app";
import { Flex, Grid } from "components/Base";
import { useEffect, useState } from "react";
import { getMediaExtension } from "utils/media";
import ImageItem from "./ImageItem";
import ModalImages from "./ModalImages";
import {
  BottomFullGrid,
  MultipleGrid,
  SingleFlex,
  TopFullGrid,
} from "./styled";

interface ImageGroupProps {
  images: Array<string | File>;
  removable?: boolean;
  onChangeImages?: (images: any[]) => void;
}

const FRAME_WIDTH = "547px";
const FRAME_HEIGHT = "296px";

function ImageGroup({
  images: imgs,
  removable = false,
  onChangeImages = () => {},
}: ImageGroupProps) {
  const [images, setImages] = useState<Array<string | File>>([]);
  const dataLength = imgs.length;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (dataLength > 0) {
      setImages(imgs);
    }
  }, [dataLength]);

  const openModal = () => {
    setVisible(true);
  };

  const handleRemove = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    onChangeImages(newImages);
  };

  const renderContent = () => {
    if (dataLength === 1) {
      return (
        <SingleFlex>
          {images.map((image, index) => {
            const isFile = image instanceof File;
            const imageSource = isFile ? URL.createObjectURL(image) : image;
            const imageExt = isFile ? getMediaExtension(image?.name) : "";
            const isVideoFile =
              isFile && VIDEO_FILES_EXTENSION.includes(imageExt);

            return (
              <ImageItem
                isSingle
                src={imageSource}
                br="$rounded-lg"
                onClick={openModal}
                isVideoFile={isVideoFile}
                key={`single-image-${index}`}
              />
            );
          })}
        </SingleFlex>
      );
    }

    if (dataLength < 5 || dataLength === 6) {
      const columnTotal = dataLength === 3 || dataLength === 6 ? 3 : 2;
      return (
        <MultipleGrid
          columns={`repeat(${columnTotal}, 1fr)`}
          className={`photo-grid-${dataLength}`}
          cursor="pointer"
          onClick={openModal}
          gap="1px"
        >
          {images.map((image, index) => {
            const isFile = image instanceof File;
            const imageSource = isFile ? URL.createObjectURL(image) : image;
            return (
              <ImageItem
                key={`image-group-col-${columnTotal}-${index}`}
                src={imageSource}
              />
            );
          })}
        </MultipleGrid>
      );
    }

    if (images.length >= 5) {
      return (
        <div style={{ cursor: "pointer" }} onClick={openModal}>
          <TopFullGrid className="photo-grid-5a">
            {images.slice(0, 2).map((image, index) => {
              const isFile = image instanceof File;
              const imageSource = isFile ? URL.createObjectURL(image) : image;
              return (
                <ImageItem
                  key={`image-group-row-1-${index}`}
                  src={imageSource}
                />
              );
            })}
          </TopFullGrid>
          <BottomFullGrid className="photo-grid-5b">
            {images.slice(2, 5).map((image, index) => {
              const isFile = image instanceof File;
              const imageSource = isFile ? URL.createObjectURL(image) : image;
              return (
                <ImageItem
                  key={`image-group-row-2-${index}`}
                  src={imageSource}
                />
              );
            })}
          </BottomFullGrid>
        </div>
      );
    }
  };

  return (
    <>
      {renderContent()}
      {/* Add modal images here */}
      <ModalImages
        images={images}
        visible={visible}
        onCancel={() => setVisible(false)}
        removable={removable}
        onRemove={handleRemove}
      />
    </>
  );
}

export default ImageGroup;
