import { Avatar } from "antd";
import { Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { useModalReportStore } from "global/Modals/ModalReport/modalReportStore";
import profileStore from "lib/stores/entities/profile";
import { useNavigate } from "react-router-dom";
import { IPostItem } from "types/responses/post";
import { formatDate } from "utils/date";
import Option from "./Option";

interface HeaderProps {
  post: IPostItem;
  onDelete?: () => void;
  onEdit?: () => void;
}

function Header({ post, onDelete, onEdit }: HeaderProps) {
  const navigate = useNavigate();
  const currentUser = profileStore((s) => s.user);
  const setReportedItem = useModalReportStore((s) => s.setReportedItem);
  const { user, created_at } = post;

  const handleUserClick = () => {
    navigate(`/user/${user.id}`);
  };

  const handleClick = () => {
    navigate(`/inspirasi/${post.id}`);
  };

  const handleShowReport = () => {
    setReportedItem(post.id, "post");
  };

  const isAuthor = currentUser?.id === user.id;

  return (
    <Flex justifyContent="space-between" w="100%" mb="12px">
      <Flex alignItems="center" gap="14px" flex="1">
        <Flex position="relative">
          <Avatar size={42} src={user.photo} />
          <If condition={!!user.verified}>
            <Image
              src="/icons/verified.svg"
              position="absolute"
              bottom="0"
              right="0"
              size="16px"
              autofit
            />
          </If>
        </Flex>
        <Flex direction="column" gap="4px" flex="1">
          <Text
            as="h4"
            fontSize="$lg"
            weight="$bold"
            color="$coolGray800"
            lineHeight="25px"
            cursor="pointer"
            onClick={handleUserClick}
            italic={!user.name}
          >
            {user.name || "User Tanpa Nama"}
          </Text>
          <Text
            color="$coolGray400"
            lineHeight="22px"
            cursor="pointer"
            onClick={handleClick}
          >
            {formatDate(created_at, "d MMM yyyy, HH:mm a")}
          </Text>
        </Flex>
      </Flex>
      {!!onDelete && !!onEdit && (
        <Option
          isAuthor={isAuthor}
          onDelete={onDelete}
          onEdit={onEdit}
          onReport={handleShowReport}
        />
      )}
    </Flex>
  );
}

export default Header;
