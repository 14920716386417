import { Box, Card, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import infoApi from "lib/api/info";
import { useEffect, useMemo, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { IHelpList } from "types/responses/info";
import FilterHelp from "./FilterHelp";

function Help() {
  const [helps, setHelps] = useState<Array<IHelpList>>([]);

  const [value, setValue] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [activeTitle, setActiveTitle] = useState<string>("");
  const [activeContent, setActiveContent] = useState<string>("");

  useEffect(() => {
    infoApi
      .getHelpList()
      .then((res) => setHelps(res.data.data))
      .catch(() => {});
  }, []);

  const handleOpenModal = async (title: string, id: string) => {
    setActiveTitle(title);

    const res = await infoApi.getHelpDetail(id);

    if (res.status === 200 && res.data) {
      setActiveContent(res.data.content);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveTitle("");
    setActiveContent("");
  };

  const filteredHelps = useMemo(() => {
    if (!value) {
      return helps;
    }

    return helps.filter((help) =>
      help.title.toLowerCase().includes(value.toLowerCase()),
    );
  }, [helps, value]);

  return (
    <>
      <FilterHelp value={value} onChange={setValue} />
      <Card w="100%" br="0 0 8px 8px" p="32px">
        <Text weight="$bold" mb="12px" color="$secondary">
          Topik
        </Text>
        <Box w="100%">
          {filteredHelps.map((help) => (
            <Flex
              alignItems="center"
              justify="space-between"
              py="15px"
              cursor="pointer"
              onClick={() => handleOpenModal(help.title, help.id)}
              w="100%"
            >
              <Text weight="$semibold">{help.title}</Text>
              <FiChevronRight />
            </Flex>
          ))}
        </Box>
      </Card>
      <Modal
        title={activeTitle}
        open={showModal}
        onCancel={handleCloseModal}
        width={490}
      >
        <Text align="justify">{activeContent}</Text>
      </Modal>
    </>
  );
}

export default Help;
