import ResetPassword from "features/reset-password";
import MainLayout from "global/MainLayout";

function ResetPasswordPage() {
  return (
    <MainLayout>
      <div style={{ margin: "30px 0 90px" }}>
        <ResetPassword />
      </div>
    </MainLayout>
  );
}

export default ResetPasswordPage;
