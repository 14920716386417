import { Popover } from "antd";
import { Flex } from "components/Base";
import If from "global/ErrorHandlers/If";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlinePencilAlt, HiPencilAlt } from "react-icons/hi";
import { HiTrash } from "react-icons/hi2";
import OptionItem from "./OptionItem";

interface HeaderMoreProps {
  isAuthor: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onReport: () => void;
}

function Option({ isAuthor, onDelete, onEdit, onReport }: HeaderMoreProps) {
  return (
    <Popover
      trigger={["click"]}
      placement="bottomRight"
      content={
        <Flex direction="column" gap="8px">
          <If condition={isAuthor}>
            <OptionItem
              label="Edit"
              icon={<HiOutlinePencilAlt />}
              onClick={onEdit}
            />
            <OptionItem label="Hapus" icon={<HiTrash />} onClick={onDelete} />
          </If>
          <If condition={!isAuthor}>
            <OptionItem
              label="Laporkan"
              icon={<HiPencilAlt />}
              onClick={onReport}
              color="$red500"
            />
          </If>
        </Flex>
      }
    >
      <BsThreeDotsVertical cursor="pointer" />
    </Popover>
  );
}

export default Option;
