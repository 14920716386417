import { Box, Flex } from "components/Base";
import { styled } from "configs/stitches.config";

export const MobileWrapper = styled(Box, {
  w: "100%",
  h: "64px",
  backgroundColor: "$white",
  position: "fixed",
  top: 0,
  zIndex: 3,

  "@lg": {
    display: "none",
  },
});

export const DesktopWrapper = styled(Box, {
  w: "100%",
  h: "64px",
  backgroundColor: "$white",
  position: "fixed",
  top: 0,
  zIndex: 3,
  display: "none",

  "@lg": {
    display: "block",
  },
});

export const ButtonAuthWrapper = styled(Flex, {
  alignItems: "center",
  gap: "20px",
  width: "100%",

  "@lg": {
    width: "270px",
  },
});
