import useChatStore from "features/chat/chatStore";
import ChatConvo from "./ChatConvo";
import GroupDetail from "../GroupDetail";

function ChatContent() {
  const chatState = useChatStore((s) => s.mainContentState);

  if (chatState === "group-detail") {
    return <GroupDetail />;
  }

  return (
    <>
      <ChatConvo />
    </>
  );
}

export default ChatContent;
