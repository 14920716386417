import { Box, Flex } from "components/Base";
import { styled } from "configs/stitches.config";
import Post from "global/StreamWidget/components/Post";
import postApi from "lib/api/post";
import { useEffect, useState } from "react";
import { IPostItem } from "types/responses/post";
import Container from "./Container";
import SectionTitle from "./SectionTitle";

const InspirationWrapper = styled(Flex, {
  direction: "column",
  w: "100%",
  maxWidth: "874px",
  mx: "auto",
  px: "24px",

  "@xl": {
    p: "0px",
  },
});

function Inspiration() {
  const [posts, setPosts] = useState<Array<IPostItem>>([]);

  useEffect(() => {
    postApi
      .getPostList({ page: 1, limit: 3 })
      .then((res) => {
        setPosts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box as="section" py="60px">
      <Container>
        <SectionTitle title="Inspirasi Desa" />
        <InspirationWrapper>
          {posts.map((item) => (
            <Post
              post={item}
              key={`homepage-post-${item.id}`}
              showComment={false}
            />
          ))}
        </InspirationWrapper>
      </Container>
    </Box>
  );
}

export default Inspiration;
