import { Flex } from "components/Base";
import BoxBio from "global/Profile/BoxBio";
import UserBadge from "global/Profile/UserBadge";
import { IUserDetail } from "types/responses/users";

interface SideContentProps {
  user: IUserDetail;
}

function SideContent({ user }: SideContentProps) {
  return (
    <Flex as="aside" direction="column" w="388px" gap="18px">
      <UserBadge
        color={user.badge.color}
        name={user.badge.name}
        points={user.total_point}
      />
      <BoxBio bio={user.bio} />
    </Flex>
  );
}

export default SideContent;
