import request from "configs/request";
import { APIPromisePagination, APIPromiseResponse } from "types/api";
import { IHelpDetail, IHelpList } from "types/responses/info";

const getAbout = (): APIPromiseResponse<{ value: string }> => {
  return request.get("/get_about.php");
};

const getHelpList = (): APIPromisePagination<Array<IHelpList>> => {
  return request.get("/get_help_topic_list.php");
};

const getHelpDetail = (id: string): APIPromiseResponse<IHelpDetail> => {
  return request.get("/get_help_topic_detail.php", { params: { id } });
};

export default {
  getAbout,
  getHelpList,
  getHelpDetail,
};
