import { Flex } from "components/Base";
import useBasePath from "hooks/useBasePath";
import globalNotifStore from "lib/stores/entities/notif";
import { useEffect } from "react";
import { navs } from "./constants";
import NavItem from "./NavItem";

function Navigation() {
  const getNotifBadge = globalNotifStore((s) => s.getNotifBadge);
  const basePath = useBasePath() || "/";

  useEffect(() => {
    getNotifBadge();
  }, []);

  return (
    <Flex gap="22px">
      {navs.map((nav, index) => {
        const isInPaths = nav.paths && nav.paths.includes(basePath);
        const isSamePath = nav.url === basePath;
        const isActive = isInPaths || isSamePath;

        return <NavItem nav={nav} key={index} active={isActive} />;
      })}
    </Flex>
  );
}

export default Navigation;
