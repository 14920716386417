import { DOCUMENT_FILES_EXTENSION } from "constants/app";
import { Card, Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import Map from "global/Map";
import PageTitle from "global/PageTitle";
import DocumentList from "global/Post/components/DocumentList";
import ImageGroup from "global/Post/components/ImageGroup";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMediaExtension } from "utils/media";
import Header from "./components/ReportCard/Header";
import useReportStore from "./reportStore";

function DetailReport() {
  const { id } = useParams();
  const [reports, getDetail] = useReportStore((s) => [
    s.report,
    s.getReportDetail,
  ]);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  if (!id || !reports[id]) {
    return null;
  }

  // Set variable
  const detail = reports[id];

  const filterMedia = (source: Array<any>, equality: boolean) => {
    const mediaList = detail.media.filter((media) => {
      const ext = getMediaExtension(media.media);

      return source.includes(ext) === equality;
    });

    return mediaList.map((item) => item.media);
  };

  const handleEdit = () => {};

  const handleDelete = () => {};

  const imageList = filterMedia(DOCUMENT_FILES_EXTENSION, false);

  const documentList = filterMedia(DOCUMENT_FILES_EXTENSION, true);

  return (
    <>
      <PageTitle
        title="Detail Laporan"
        backUrl={`/reports/${detail.id_7d_take}`}
      />
      <Flex w="850px" m="auto" mt="32px">
        <Card p="32px" w="100%">
          <Flex direction="column" gap="14px">
            <Header
              title={detail.title}
              createdAt={detail.created_at}
              updatedAt={detail.updated_at}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
            <Text color="$coolGray600" lineHeight="20px">
              {detail.content}
            </Text>
            <If condition={imageList.length > 0}>
              <ImageGroup images={imageList} />
            </If>
            <If condition={documentList.length > 0}>
              <DocumentList documents={documentList} />
            </If>
            <If condition={!!detail.location}>
              <Text mt="14px" color="$cyan500">
                Peta Kegiatan
              </Text>
              <Flex w="547px" h="215px" br="$rounded-lg">
                <Map lat={Number(detail.lat)} lng={Number(detail.lng)} />
              </Flex>
            </If>
          </Flex>
        </Card>
      </Flex>
    </>
  );
}

export default DetailReport;
