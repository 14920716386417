import { APP_URL } from "constants/api";
import { Box, Text } from "components/Base";
import parse from "html-react-parser";
import { useState } from "react";
import { convertNewLines, getContentLines } from "utils/input";

interface TextContentProps {
  text: string;
}

const COLLAPSED_STYLE = {
  display: "-webkit-box",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
};
const MAX_TEXT_LENGTH = 320;
const MAX_TEXT_LINES = 3;

function TextContent({ text: post }: TextContentProps) {
  const [expanded, setExpanded] = useState(false);

  const style = expanded ? {} : COLLAPSED_STYLE;
  const textLines = getContentLines(post);
  const convertedContent = convertNewLines(post);

  const isLongText =
    post.length > MAX_TEXT_LENGTH || textLines >= MAX_TEXT_LINES;

  return (
    <Box w="100%" mb="12px">
      <Box css={{ ...style }}>
        <Text color="$coolGray600" lineHeight="20px" className="text-comment">
          {parse(convertedContent, {
            replace: (domNode) => {
              // @ts-ignore
              if (domNode.attribs && domNode.name === "mention") {
                // @ts-ignore
                const userId = domNode.attribs.id;
                return (
                  <a
                    href={`${APP_URL}/user/${userId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* @ts-ignore */}
                    {domNode.children[0].data}
                  </a>
                );
              }
            },
          })}
        </Text>
      </Box>
      {isLongText && !expanded && (
        <Text
          color="$secondary"
          weight="$bold"
          css={{ cursor: "pointer" }}
          onClick={() => setExpanded(true)}
          mt="4px"
        >
          Lihat selengkapnya
        </Text>
      )}
    </Box>
  );
}

export default TextContent;
