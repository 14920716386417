import { APP_URL } from "constants/api";
import { message, Popover } from "antd";
import { Flex, Grid, Image, Text } from "components/Base";
import ModalLike from "global/Post/PostItem/ModalLike";
import usePostStore from "lib/stores/entities/stream";
import { useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaCommentDots, FaShareAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FooterButton from "./FooterButton";
import Stats from "./Stats";

interface FooterProps {
  postId: string;
  totalComment: number;
  totalLike: number;
  liked: boolean;
  content: string;
}

function Footer({
  totalComment,
  totalLike,
  postId,
  liked = false,
  content,
}: FooterProps) {
  const navigate = useNavigate();

  const [postLiked, setPostLiked] = useState(liked);
  const [likes, setLikes] = useState(totalLike);
  const [likePost, unlinkePost, post] = usePostStore((s) => [
    s.likePost,
    s.unlikePost,
    s.post,
  ]);

  const [visible, setVisible] = useState(false);

  const copyToClipboard = () => {
    const url = APP_URL + "/inspirasi/" + postId;
    navigator.clipboard.writeText(url).then(() => {
      message.info("Link berhasil disalin");
    });
  };

  const handleLike = async () => {
    const req = postLiked ? unlinkePost : likePost;

    const res = await req(Number(postId));

    if (res) {
      setPostLiked(!postLiked);
      setLikes(postLiked ? likes - 1 : likes + 1);
    }
  };

  const handleClickComment = () => {
    navigate(`/inspirasi/${postId}`);
  };

  const handleShareWa = () => {
    const url = APP_URL + "/inspirasi/" + postId;
    const title =
      content.length > 100 ? content.slice(0, 100) + "..." : content;

    const params = { text: `${title} \nBaca selengkapnya di ${url}` };
    const urlParams = new URLSearchParams(params).toString();

    window.open(`https://wa.me/?${urlParams}`, "_blank");
  };

  const likeButtonBg = postLiked ? "$secondary" : "$secondary4";
  const likeButtonColor = postLiked ? "$white" : "$secondary";
  return (
    <>
      <Flex direction="column" w="100%" gap="14px" my="16px">
        <Stats
          totalComment={totalComment}
          totalLike={likes}
          onClickLike={() => setVisible(true)}
          onClickComment={handleClickComment}
        />
        <Grid columns="repeat(3, 1fr)" gap="12px" w="100%">
          <FooterButton
            color={likeButtonBg}
            textColor={likeButtonColor}
            onClick={handleLike}
          >
            <AiFillLike />
            <Text weight="$bold">{likes || 0}</Text>
          </FooterButton>
          <FooterButton
            color="$primary3"
            textColor="$primary"
            onClick={handleClickComment}
          >
            <FaCommentDots />
            <Text weight="$bold">{totalComment || 0}</Text>
          </FooterButton>
          <Popover
            trigger={["click"]}
            placement="topRight"
            content={
              <Flex direction="column" gap="8px">
                <Flex
                  gap="16px"
                  alignItems="center"
                  w="350px"
                  cursor="pointer"
                  onClick={handleShareWa}
                >
                  <Image src="/icons/logo-whatsapp.png" w="29px" />
                  <Text color="$coolGray600">Whatsapp</Text>
                </Flex>
                <Flex
                  gap="16px"
                  alignItems="center"
                  w="350px"
                  cursor="pointer"
                  onClick={copyToClipboard}
                >
                  <Image src="/icons/logo-share.png" w="29px" />
                  <Text color="$coolGray600">Salin Link</Text>
                </Flex>
              </Flex>
            }
          >
            <FooterButton color="$blue4" textColor="$blue1">
              <FaShareAlt />
            </FooterButton>
          </Popover>
        </Grid>
      </Flex>
      <ModalLike
        id={Number(postId)}
        visible={visible}
        likes={totalLike}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}

export default Footer;
