import authApi from "lib/api/auth";
import { ILoginData } from "types/responses/auth";
import { getFcmToken, saveToken } from "utils/authStore";
import create from "zustand";

interface AuthSlice {
  user: null | Record<string, any> | undefined;
  fcmToken: string;
  error: null | any;
  loggedIn: boolean;
  hasFcmToken: boolean;
  loading: boolean;
  setHasFcmToken: (hasFcmToken: boolean) => void;
  login: (email: string, password: string) => void;
  loginQr: (code: string) => void;
  reset: () => void;
}

const authStore = create<AuthSlice>((set, get) => ({
  user: null,
  error: null,
  loggedIn: false,
  fcmToken: "",
  loading: false,
  hasFcmToken: false,
  setHasFcmToken: (hasFcmToken) => set({ hasFcmToken }),
  login: async (email, password) => {
    try {
      set({ loading: true });
      const { fcmToken } = get();

      const notifToken = fcmToken || getFcmToken() || "UNKNOWN";

      const loginData: ILoginData = {
        credentials: email,
        password,
        device_id: "web",
        fcm_token: notifToken,
        platform: "web",
        device: "web",
      };

      const res = await authApi.login(loginData);

      if (res.status !== 200) {
        return set({ error: res.data });
      }

      const data = res.data;

      // Save token to local storage
      saveToken({
        access_token_exp: 0,
        access_token: data.token,
        access_user: data,
      });

      return set({
        // do not set user data upon login
        // should get user data after logged in via `useProfile`
        user: data,
        loggedIn: true,
        error: null,
      });
    } catch (err) {
      return set({ error: "An error occurred" });
    } finally {
      set({ loading: false });
    }
  },
  loginQr: async (code) => {
    try {
      const { fcmToken } = get();

      const notifToken = fcmToken || getFcmToken() || "UNKNOWN";

      const res = await authApi.loginQr(code, notifToken);

      if (res.status !== 200) {
        // return set({ error: res.data });
        return;
      }

      const data = res.data;

      // Save token to local storage
      saveToken({
        access_token_exp: 0,
        access_token: data.token,
        access_user: data,
      });

      return set({
        // do not set user data upon login
        // should get user data after logged in via `useProfile`
        user: data,
        error: null,
      });
    } catch (err) {
      return;
      // return set({ error: "An error occurred" });
    }
  },
  reset: () => {
    return set({ user: null, error: null });
  },
}));

export default authStore;
