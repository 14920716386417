import { useQuery } from "@tanstack/react-query";
import { Progress } from "antd";
import { Box, Flex, Grid, Image, Text } from "components/Base";
import { getUserBadge } from "features/explore/utils";
import useProfile from "hooks/useProfile";
import config from "lib/api/config";
import { FaCheckCircle } from "react-icons/fa";
import { getColor } from "utils/general";
import { USER_LEVEL_MAPPING } from "./Level.constants";
import LevelItem from "./LevelItem";

function LevelUser() {
  const user = useProfile();
  const { data: levels, isLoading } = useQuery({
    queryKey: ["getLevelList"],
    queryFn: async () => {
      try {
        const res = await config.getBadgeList();

        if (res.status !== 200) {
          throw new Error("Gagal mengambil data");
        }

        if (!res.data) {
          throw new Error("Data tidak ditemukan");
        }

        return res.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    initialData: [],
  });

  if (!user || levels.length === 0) {
    return null;
  }

  return (
    <>
      <Box css={{ background: "#FFFBE7", p: "48px 16px 32px 16px", w: "100%" }}>
        <Flex
          direction="column"
          alignItems="center"
          gap="16px"
          w="375px"
          m="auto"
        >
          <Image
            src={user.badge.media}
            css={{ height: "142px", width: "92px" }}
            alt="Sigap Level"
          />
          <Text color="$sunrise" weight="bold" size="$lg">
            {user.badge.name}
          </Text>
          <Flex alignItems="center" gap="12px" w="100%">
            <Progress
              percent={(user.total_point / Number(user.badge.end_score)) * 100}
              showInfo={false}
              strokeColor="#37A555"
              style={{ width: 275 }}
            />
            <Text color="$secondary" size="$md" weight="bold">
              {user.total_point} Poin
            </Text>
          </Flex>
          <Text align="center">
            Kamu membutuhkan{" "}
            {Number(user.badge.end_score) - Number(user.total_point)} poin lagi
            untuk naik level
          </Text>
          <Grid columns="repeat(4, 1fr)" gap="16px" w="100%">
            {levels.map((level, index) => (
              <LevelItem
                isActive={Number(user.total_point) > Number(level.start_score)}
                name={level.name}
                media={level.media}
              />
            ))}
          </Grid>
        </Flex>
      </Box>
      <Box pt="24px">
        <Text weight="bold" mb="24px">
          Keuntungan Level
        </Text>
        <Flex direction="column" gap="16px">
          {user.badge.speciality.split("\n").map((benefit, index) => (
            <Flex
              key={`benefit-${index}`}
              gap="12px"
              alignItems="center"
              pb="16px"
              borderBottom="solid 1px #e5e7eb"
              w="100%"
            >
              <FaCheckCircle color={getColor("secondary")} size="20px" />
              <Text color="$coolGray600" weight="600" style={{ flex: 1 }}>
                {benefit}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    </>
  );
}

export default LevelUser;
