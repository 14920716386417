import { Badge } from "antd";
import { Flex } from "components/Base";
import If from "global/ErrorHandlers/If";
import globalNotifStore from "lib/stores/entities/notif";
import { Link } from "react-router-dom";

interface NavItemProps {
  nav: {
    label: string;
    url: string;
    icon: React.ReactNode;
    withNotif?: boolean;
    storeKey?: string;
  };
  active?: boolean;
}

function NavItem({ nav, active }: NavItemProps) {
  const notifBadge = globalNotifStore();
  const defaultStyle = {
    fontWeight: "$bold",
    color: "$coolGray400",
    transition: "all 0.2s ease-in-out",

    "& .ant-badge": {
      color: "inherit !important",
    },

    "&:hover": {
      bg: "$coolGray50",
      color: "$coolGray500",
    },
  };
  const style = active
    ? {
        bg: "$secondary4",
        color: "$secondary1",

        "&:hover": {
          bg: "$secondary4",
          color: "$secondary1",
        },
      }
    : {};

  // @ts-ignore
  const notifCount = nav.storeKey ? notifBadge[nav.storeKey] : 0;
  return (
    <Link to={nav.url}>
      <Flex
        br="$rounded-lg"
        p="8px 10px"
        align="center"
        gap="8px"
        css={{ ...defaultStyle, ...style }}
      >
        <If condition={!!nav.withNotif && notifCount > 0}>
          <Badge dot>{nav.icon}</Badge>
        </If>
        <If condition={!nav.withNotif || notifCount === 0}>{nav.icon}</If>
        <span>{nav.label}</span>
      </Flex>
    </Link>
  );
}

export default NavItem;
