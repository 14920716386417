import Profile from "features/profile";
import MainLayout from "global/MainLayout";

function ProfilePage() {
  return (
    <MainLayout>
      <Profile />
    </MainLayout>
  );
}

export default ProfilePage;
