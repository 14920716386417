import { Box, Flex, Image, Text } from "components/Base";
import useProfileStore from "features/profile/profileStore";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const LIMIT = 100;

function PointHistory() {
  const [points, total, getHistory] = useProfileStore((s) => [
    s.points,
    s.totalPointData,
    s.getPointHistory,
  ]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getHistory({ page, limit: LIMIT });
  }, []);

  const handleLoadMore = () => {
    if (points.length >= total) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);

    getHistory({ page: newPage, limit: LIMIT });
  };

  const hasMore = points.length < total;

  return (
    <Box w="100%">
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={total}
        hasMore={hasMore}
        loader={
          <Text color="$coolGray300" size="$xs" align="center">
            Loading...
          </Text>
        }
        endMessage={null}
        style={{ maxHeight: "500px" }}
      >
        {points.map((point, index) => (
          <Flex
            alignItems="center"
            py="21px"
            key={`point-history-${index}`}
            justify="space-between"
            css={{
              borderBottom: "1px solid $gray200",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
            w="100%"
          >
            <Flex alignItems="center" gap="14px">
              <Image src="/icons/point.svg" css={{ w: "20px" }} />
              <Text weight="$semibold">{point.name}</Text>
            </Flex>
            <Text
              color={point.subtract === "1" ? "$red500" : "$secondary"}
              weight="$bold"
            >
              {point.subtract === "1" ? "-" : "+"} {point.point}
            </Text>
          </Flex>
        ))}
      </InfiniteScroll>
    </Box>
  );
}

export default PointHistory;
