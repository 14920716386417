import { Card } from "components/Base";
import { PROFILE_SIDE_NAVS } from "features/profile/constants";
import useProfileStore from "features/profile/profileStore";
import ModalStory from "global/Modals/ModalStory";
import { useState } from "react";
import SideNav from "./SideNav";

function Sidebar() {
  const profileState = useProfileStore((s) => s.profileState);

  const [showAdd, setShowAdd] = useState(false);
  const [selectedStory, setSelectedStory] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];

      if (!file) return;

      setShowAdd(true);
      setSelectedStory(file);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModalAdd = () => {
    const modal = document.getElementById("add-story-profile");

    if (modal) {
      // @ts-ignore
      modal.value = "";
    }

    setShowAdd(false);
    setSelectedStory(null);
  };

  const handleSuccessAdd = () => {
    handleCloseModalAdd();
  };

  return (
    <>
      <Card p="0px" w="100%">
        {PROFILE_SIDE_NAVS.map((nav) => (
          <SideNav
            label={nav.label}
            value={nav.value}
            icon={nav.icon}
            isActive={nav.value === profileState}
            onClick={nav.onClick}
            idInput={nav.idInput}
          />
        ))}
      </Card>
      <input
        type="file"
        id="add-story-profile"
        style={{ display: "none" }}
        accept="image/jpg, image/jpeg, image/png"
        onChange={handleChange}
      />
      <ModalStory
        visible={showAdd}
        onCancel={handleCloseModalAdd}
        onSuccessSubmit={handleSuccessAdd}
        story={selectedStory}
      />
    </>
  );
}

export default Sidebar;
