import produce from "immer";
import chatApi from "lib/api/chat";
import { IChatGetMemberList } from "types/responses/chat";
import { ChatMemberStore, StoreSlice } from "./chatStore.types";

const chatMemberSlice: StoreSlice<ChatMemberStore> = (set) => ({
  chatMemberList: {},
  nonMemberList: {},
  getChatMemberList: async (params) => {
    try {
      const res = await chatApi.getMemberList(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      if (params.page === 1) {
        return set(
          produce((state: ChatMemberStore) => {
            state.chatMemberList[params.room] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      // @ts-ignore
      set(
        produce((state: ChatMemberStore) => {
          state.chatMemberList[params.room].data.push(...res.data.data);
          state.chatMemberList[params.room].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  getNonMemberList: async (params) => {
    try {
      const res = await chatApi.getNonMemberList(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      if (params.page === 1) {
        return set(
          produce((state: ChatMemberStore) => {
            state.nonMemberList[params.room] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      // @ts-ignore
      set(
        produce((state: ChatMemberStore) => {
          state.nonMemberList[params.room].data.push(...res.data.data);
          state.nonMemberList[params.room].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addChatMember: async (data) => {
    try {
      const res = await chatApi.addChatRoomMember(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      // set(
      //   produce((state: ChatMemberStore) => {
      //     state.chatMemberList[data.room].data.push(res.data);
      //   }),
      // );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deleteChatMember: async (data) => {
    try {
      const res = await chatApi.deleteChatRoomMember(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      set(
        produce((state: ChatMemberStore) => {
          state.chatMemberList[data.room].data = state.chatMemberList[
            data.room
          ].data.filter((member) => Number(member.id) !== Number(data.id_user));
        }),
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  updateChatAdmin: async (data) => {
    try {
      const res = await chatApi.updateChatRoomAdmin(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      set(
        produce((state: ChatMemberStore) => {
          state.chatMemberList[data.room].data = state.chatMemberList[
            data.room
          ].data.map((member: IChatGetMemberList) => {
            if (Number(member.id) === Number(data.id_user)) {
              return {
                ...member,
                admin: "1",
              };
            }

            return member;
          });
        }),
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
});

export default chatMemberSlice;
