import { DOCUMENT_FILES_EXTENSION, IMAGE_FILES_EXTENSION } from "constants/app";
import { Box, Flex, Image, Text } from "components/Base";
import { format } from "date-fns";
import useMengenal7DStore from "features/7d/mengenal7dStore";
import SEO from "global/SEO";
import useProfile from "hooks/useProfile";
import parse from "html-react-parser";
import dApi from "lib/api/7d";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { HiPrinter } from "react-icons/hi";
import { useParams, useSearchParams } from "react-router-dom";
import { IDownload7DReport } from "types/responses/7d";
import { formatDate } from "utils/date";
import { getMediaExtension } from "utils/media";

function PrintReports() {
  const { id } = useParams();
  const [params] = useSearchParams();

  const user = useProfile();

  const [list, getList] = useMengenal7DStore((s) => [
    s.takeList,
    s.getTakeList,
  ]);

  const from = params.get("from");
  const to = params.get("to");

  const [reports, setReports] = useState<Array<IDownload7DReport>>([]);

  const reportLength = reports.length;
  const listLength = list.length;

  const getReports = useCallback(async () => {
    if (!id || !from || !to) {
      return;
    }

    const param = {
      id,
      from,
      to,
    };

    const res = await dApi.download7dPostList(param);

    if (res.status !== 200) {
      return;
    }

    setReports(res.data);
  }, [id, from, to]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    getList();
  }, []);

  useLayoutEffect(() => {
    if (listLength > 0 && reportLength > 0) {
      setTimeout(() => {
        window.print();
      }, 2 * 1000); // 2s
    }
  }, [listLength, reportLength]);

  const handlePrint = () => {
    window.print();
  };

  if (!id || !from || !to) {
    return null;
  }

  const item = list.find((item) => item.id_7d_take === id);

  return (
    <>
      <SEO title={`Laporan 7D ${from} - ${to}`} />
      {/* Cover */}
      <Box w="100%" h="296.8mm" position="relative">
        <Image src="/images/cover.png" w="100%" h="100%" />
        <Image
          src="/logo-squared.png"
          position="absolute"
          top="25%"
          right="64px"
          w="70px"
        />
        <Flex
          direction="column"
          position="absolute"
          top="50%"
          right="64px"
          css={{ transform: "translateY(-50%)" }}
          alignItems="flex-end"
          gap="8pt"
        >
          <Text fontSize="12pt" fontWeight="$semibold" color="$coolGray500">
            {formatDate(from, "d MMMM yyyy") +
              " - " +
              formatDate(to, "d MMMM yyyy")}
          </Text>
          <Text fontSize="28pt" fontWeight="$bold">
            {item?.title}
          </Text>
          <Text fontSize="14pt" fontWeight="$bold">
            {user?.name}
          </Text>
        </Flex>
        <Flex
          direction="column"
          position="absolute"
          bottom="32pt"
          left="64pt"
          color="$white"
          gap="4pt"
          fontWeight="$bold"
        >
          <Text fontSize="12pt">Dibuat oleh</Text>
          <Text fontSize="12pt">Web Sigap - Inspirasi Desa</Text>
          <Text fontSize="12pt">
            pada {format(new Date(), "d MMM yyyy HH:mm a")}
          </Text>
        </Flex>
      </Box>
      <Box w="100%" minHeight="297mm" position="relative">
        {/* Report list */}
        {reports.map((report) => {
          const images = report.media.filter((media) => {
            const ext = getMediaExtension(media.media);

            return IMAGE_FILES_EXTENSION.includes(ext);
          });

          const docs = report.media.filter((media) => {
            const ext = getMediaExtension(media.media);

            return DOCUMENT_FILES_EXTENSION.includes(ext);
          });

          return (
            <Box
              w="100%"
              css={{
                p: "2cm",

                "@media print": {
                  paddingTop: "1pt",
                },
              }}
            >
              <Text
                weight="$semibold"
                fontSize="12pt"
                color="$secondary"
                mb="12pt"
              >
                {report["7d_name"]}
              </Text>
              <Text
                weight="$bold"
                fontSize="24pt"
                w="100%"
                borderBottom="1px solid #000"
                pb="12pt"
                mb="12pt"
              >
                {report.title}
              </Text>
              <Flex direction="column" gap="4pt" mb="12pt">
                <Text fontSize="12pt">Waktu Kegiatan</Text>
                <Text fontSize="12pt">
                  {formatDate(report.created_at, "dd MMM yyyy HH:mm a")}
                </Text>
              </Flex>
              {report.location && (
                <Flex direction="column" gap="4pt" mb="12pt">
                  <Text fontSize="12pt">Lokasi di: </Text>
                  <Text fontSize="12pt" color="$lightBlue500">
                    {report.location}
                  </Text>
                </Flex>
              )}

              {docs.length > 0 && (
                <Flex direction="column" gap="4pt" mb="12pt">
                  <Text fontSize="12pt">Dokumen: </Text>

                  {docs.map((doc) => (
                    <Text fontSize="12pt" color="$lightBlue500" key={doc.id}>
                      {doc.media}
                    </Text>
                  ))}
                </Flex>
              )}

              {images.length > 0 && (
                <Flex direction="column" gap="10pt" mt="4pt" mb="16pt">
                  {images.map((image) => (
                    <Image
                      css={{
                        maxWidth: "100%",
                        maxHeight: 450,
                        marginX: "auto",
                      }}
                      src={image.media}
                      key={image.id}
                    />
                  ))}
                </Flex>
              )}
              <Box w="100%" lineHeight="1.5">
                {parse(report.content)}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Flex
        w="50px"
        h="50px"
        br="$rounded-full"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        bottom="50px"
        right="50px"
        bgColor="$secondary"
        onClick={handlePrint}
        css={{ "@media print": { display: "none" } }}
        cursor="pointer"
        boxShadow="$shadow-xl"
      >
        <HiPrinter color="white" />
      </Flex>
    </>
  );
}

export default PrintReports;
