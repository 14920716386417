import { Flex } from "components/Base";
import { styled } from "configs/stitches.config";

export const BannerContainer = styled(Flex, {
  bgColor: "$secondary5",
  p: "32px",
  mt: "64px",
  "@md": {
    py: "95px",
  },
});

export const BannerWrapper = styled(Flex, {
  w: "100%",
  maxWidth: "1196px",
  m: "0 auto",
  flexDirection: "column-reverse",
  gap: "32px",

  "& img": {
    width: "100%",
    height: "auto",
  },
  "& .title-app": {
    fontSize: "$3xl",
    mb: "16px",
  },
  "& .subtitle-app": {
    fontSize: "$md",
    lineHeight: "24px",
    mb: "16px",
  },

  "@md": {
    flexDirection: "row",
    alignItems: "center",
    justify: "space-between",

    "& img": {
      width: "auto",
      height: "225px",
    },

    "& .title-app": {
      fontSize: "$4xl",
    },

    "& .subtitle-app": {
      fontSize: "$lg",
      mb: "20px",
    },
  },

  "@lg": {
    "& img": {
      height: "300px",
    },

    "& .subtitle-app": {
      fontSize: "$xl",
      lineHeight: "28px",
      mb: "24px",
    },
  },

  "@xl": {
    "& img": {
      height: "402px",
    },

    "& .title-app": {
      fontSize: "$5xl",
      mb: "20px",
    },

    "& .subtitle-app": {
      fontSize: "$2xl",
      lineHeight: "32px",
      mb: "32px",
    },
  },
});
