import { Box, Flex } from "components/Base";
import dApi from "lib/api/7d";
import { useEffect, useState } from "react";
import Card7D from "./Card7D";
import { CardWrapper } from "./List7D.styled";
import Container from "../Container";
import SectionTitle from "../SectionTitle";

function List7D() {
  const [list, setList] = useState<Array<any>>([]);

  const getList = async () => {
    const res = await dApi.get7dList();

    if (res.data) {
      setList(res.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box as="section" py="60px" bgColor="$white">
      <Container>
        <SectionTitle
          title="Panduan 7D"
          subtitle="Panduan Aksi Inspiratif Warga untuk Perubahan"
        />
        <CardWrapper>
          {list.map((item, index) => (
            <Card7D
              title={item.title}
              color={item.color}
              position={item.circle_position}
              key={`7d-card-${index}`}
            />
          ))}
        </CardWrapper>
      </Container>
    </Box>
  );
}

export default List7D;
