import AuthCard from "components/Auth/AuthCard";
import { Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import authStore from "lib/stores/entities/auth";
import { useEffect, useState } from "react";
import { GrRefresh } from "react-icons/gr";
import { HiOutlineQrcode } from "react-icons/hi";
import QR from "react-qr-code";
import { getRandomNumber } from "utils/number";
import useLoginStore from "./loginStore";

const CODE_LENGTH = 15;
const MAX_CALL = 5;
const REGENERATE_CODE_DURATION = 60; // 60 seconds
const REFETCH_QR_DURATION = 2;

function QRCode() {
  const setLoginState = useLoginStore((s) => s.setState);
  const login = authStore((s) => s.loginQr);

  const [randomCode, setRandomCode] = useState<number>(
    getRandomNumber(CODE_LENGTH),
  );
  const [called, setCalled] = useState<number>(0);

  useEffect(() => {
    if (called < MAX_CALL) {
      const interval = setInterval(() => {
        setRandomCode(getRandomNumber(CODE_LENGTH));
        setCalled((prev) => prev + 1);
      }, REGENERATE_CODE_DURATION * 1000);

      return () => clearInterval(interval);
    }
  }, [called]);

  useEffect(() => {
    const interval = setInterval(() => {
      login(String(randomCode));
    }, REFETCH_QR_DURATION * 1000);

    if (called >= MAX_CALL) clearInterval(interval);

    return () => clearInterval(interval);
  }, [called, randomCode]);

  const handleBack = () => {
    setLoginState("form");
  };

  const handleReset = () => {
    setCalled(0);
    setRandomCode(getRandomNumber(CODE_LENGTH));
  };

  return (
    <AuthCard title="Scan QR Code untuk Login" onBack={handleBack}>
      <ol style={{ listStyle: "decimal", marginLeft: 24 }}>
        <li>
          <Text fontSize="18px" lineHeight="25px" mb="20px">
            Buka aplikasi Sigap di Handphone Kamu
          </Text>
        </li>
        <li>
          <Flex align="center" mb="20px" gap="8px">
            <Text fontSize="18px" lineHeight="25px">
              Masuk ke <b>Profile</b>
            </Text>
            <Image src="/icons/profile-nav.svg" />
          </Flex>
        </li>
        <li>
          <Flex align="center" mb="20px" gap="4px">
            <Text fontSize="18px" lineHeight="25px">
              Pilih menu
            </Text>
            <HiOutlineQrcode />
            <b>Scan untuk Login Website</b>
          </Flex>
        </li>
        <li>
          <Text fontSize="18px" lineHeight="25px" mb="20px">
            Arahkan handphone kamu ke layar ini untuk scan QR Code
          </Text>
        </li>
      </ol>

      <Flex alignItems="center" justify="center" direction="column">
        <QR
          value={`SIGAP_LOGIN_CODE:${randomCode}`}
          style={{ margin: "auto" }}
        />
        <If condition={called >= MAX_CALL}>
          <Flex
            w="256px"
            h="256px"
            align="center"
            justify="center"
            background="rgba(255, 255, 255, 0.7)"
            position="absolute"
          >
            <Flex
              align="center"
              justify="center"
              w="52px"
              h="52px"
              br="$rounded-full"
              background="rgba(255, 255, 255, 0.5)"
              css={{ cursor: "pointer" }}
              onClick={handleReset}
            >
              <GrRefresh size={32} />
            </Flex>
          </Flex>
        </If>
      </Flex>
    </AuthCard>
  );
}

export default QRCode;
