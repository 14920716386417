import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import useStoryStore from "features/explore/components/Story/storyStore";
import If from "global/ErrorHandlers/If";
import { useEffect } from "react";

interface ModalViewerProps {
  id: string;
  totalView: number;
  totalLikes: number;
  visible: boolean;
  onCancel: () => void;
  [key: string]: any;
}

function ModalViewer({
  id,
  totalView,
  totalLikes,
  visible,
  onCancel,
  ...props
}: ModalViewerProps) {
  const [story, getViewers] = useStoryStore((s) => [
    s.storyViewers,
    s.getStoryViewers,
  ]);
  const [likes, getLikes] = useStoryStore((s) => [
    s.storyLikes,
    s.getStoryLikes,
  ]);

  useEffect(() => {
    if (id && visible) {
      const params = {
        page: 1,
        id,
      };

      getViewers(params);
      getLikes(params);
    }
  }, [id, visible]);

  const viewers = story[id] || [];
  const likers = likes[id] || [];

  return (
    <Modal
      title={`Story`}
      open={visible}
      footer={null}
      onCancel={onCancel}
      {...props}
    >
      <If condition={totalLikes > 0}>
        <Text weight="$bold" mb="16px">
          {totalLikes} Suka
        </Text>
        {likers.map((item) => (
          <Flex key={item.id} alignItems="center" gap="12px" mb="12px">
            <Flex alignItems="center" gap="12px">
              <Avatar size={40} src={item.photo} />
              <Text weight="$medium" italic={!item.name}>
                {item.name || "User Tanpa Nama"}
              </Text>
            </Flex>
          </Flex>
        ))}
      </If>
      <If condition={totalLikes > 0 && totalView > 0}>
        <Flex w="100%" mt="16px">
          <Text weight="$bold" my="16px">
            {totalView} Pemirsa
          </Text>
        </Flex>
      </If>
      {viewers.map((item) => (
        <Flex
          key={item.id}
          alignItems="center"
          justify="space-between"
          mb="12px"
        >
          <Flex alignItems="center" gap="12px">
            <Avatar size={40} src={item.photo} />
            <Text weight="$medium" italic={!item.name}>
              {item.name || "User Tanpa Nama"}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Modal>
  );
}

export default ModalViewer;
