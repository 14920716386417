import { LOGIN_STATE } from "constants/app";
import AuthCard from "components/Auth/AuthCard";
import { Password } from "components/Form";
import ButtonBlock from "features/login/components/ButtonBlock";
import If from "global/ErrorHandlers/If";
import { ErrorBox } from "global/StatusBox";
import useInput from "hooks/useInput";
import auth from "lib/api/auth";
import authStore from "lib/stores/entities/auth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { destroyToken } from "utils/authStore";
import { notifyError, notifySuccess } from "utils/notification";

function ResetPassword() {
  const navigate = useNavigate();
  const { code } = useParams();
  const resetAuth = authStore((state) => state.reset);

  const [email, setEmail] = useState("");

  const [password, setPassword] = useInput("");
  const [confirmPassword, setConfirmPassword] = useInput("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verifyCode = async (code: string) => {
    const res = await auth.getEmailVerif(code);

    if (res.status === 200 && res.data.data) {
      setEmail(res.data.data);
      return;
    }

    notifyError({
      message: "Kode verifikasi tidak valid",
      description: "Silahkan request kode verifikasi baru",
    });
    navigate("/login");
  };

  useEffect(() => {
    if (code) {
      verifyCode(code);
    }
  }, [code]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage("Kata sandi tidak sama");
      return;
    }

    if (!code) {
      return;
    }

    const data = {
      email,
      token: code,
      password,
    };

    const res = await auth.resetPassword(data);

    if (res.status === 200) {
      notifySuccess({
        message: "Berhasil mereset kata sandi",
        description: "Silahkan login dengan kata sandi baru",
      });

      destroyToken();
      resetAuth();
      navigate("/login", {
        state: LOGIN_STATE.RESET_PASSWORD,
      });
    }
    // setSuccess(true);
    // setError(true);
  };

  return (
    <AuthCard
      title="Atur Kata Sandi Baru"
      subtitle="Silahkan buat kata sandi baru untuk login ke aplikasi sigap."
    >
      <If condition={error}>
        <ErrorBox reason={errorMessage} />
      </If>
      <form onSubmit={handleSubmit}>
        <Password
          label="Kata Sandi Baru"
          value={password}
          onChange={setPassword}
        />
        <Password
          label="Ulangi Kata Sandi Baru"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
        <ButtonBlock color="secondary" htmlType="submit">
          Perbarui Kata Sandi
        </ButtonBlock>
      </form>
    </AuthCard>
  );
}

export default ResetPassword;
