import request from "configs/request";
import { APIPromiseResponse } from "types/api";
import { INotifBadge, INotifResponse } from "types/responses/notif";

const getNotifList = (
  page?: number,
  limit?: number,
): APIPromiseResponse<INotifResponse> => {
  return request.get("/get_notification_list.php", {
    params: {
      page,
      size: limit,
    },
  });
};

const getNotifBadge = (): APIPromiseResponse<INotifBadge> => {
  return request.get("/get_notification_badge.php");
};

const readNotif = (id: number): APIPromiseResponse => {
  return request.post("/update_notification_read.php", { id });
};

export default {
  getNotifList,
  getNotifBadge,
  readNotif,
};
