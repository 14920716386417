import { MAX_MEDIA_UPLOAD } from "constants/app";
import { Button, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import { Textarea } from "components/Form";
import DatePicker from "components/Form/Datepicker";
import { format } from "date-fns";
import CurrentLocation from "global/Compose/CurrentLocation";
import FooterCompose from "global/Compose/Footer";
import If from "global/ErrorHandlers/If";
import DocumentList from "global/Post/components/DocumentList";
import ImageGroup from "global/Post/components/ImageGroup";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { I7DReport } from "types/responses/7d";
import { IOpenMapLocation } from "types/responses/location";
import { convertToEventTarget } from "utils/input";
import { filterDocumentMedia, filterImageMedia } from "utils/media";

interface ModalComposeProps {
  visible: boolean;
  initialValues?: I7DReport | null;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

function ModalComposeReport({
  visible,
  onCancel,
  onSubmit,
  initialValues,
}: ModalComposeProps) {
  const [title, setTitle] = useInput("");
  const [content, setContent] = useInput("");

  const [date, setDate] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);

  const [imageList, setImageList] = useState<Array<any>>([]);
  const [documentList, setDocumentList] = useState<Array<any>>([]);

  useEffect(() => {
    if (!visible) {
      setTitle(convertToEventTarget(""));
      setContent(convertToEventTarget(""));
      setDate(null);
      setLocation(null);
      setImageList([]);
      setDocumentList([]);
    }
  }, [visible]);

  useEffect(() => {
    if (initialValues) {
      setTitle(convertToEventTarget(initialValues.title));
      setContent(convertToEventTarget(initialValues.content));

      setDate(new Date(initialValues.date));

      const mediaList = initialValues.media.map((item) => item.media);
      const documents = filterDocumentMedia(mediaList);
      const images = filterImageMedia(mediaList);

      setImageList(images);
      setDocumentList(documents);

      const { lat, location: postLoc, lng } = initialValues;
      if (lat && lng && postLoc) {
        const loc: Partial<IOpenMapLocation> = {
          lat,
          lon: lng,
          display_name: postLoc,
        };

        setLocation(loc);
      }
    }
  }, [initialValues]);

  const handleChangeDocs = (docs: Array<any>) => {
    setDocumentList(docs);
  };

  const handleChangeComposeDocs = (docs: Array<any>) => {
    setDocumentList([...documentList, ...docs]);
  };

  const handleChangeImages = (images: Array<any>) => {
    setImageList(images);
  };

  const handleChangeComposeImages = (images: Array<any>) => {
    setImageList([...imageList, ...images]);
  };

  const handleChangeLocation = (loc: Partial<IOpenMapLocation>) => {
    setLocation(loc);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      title,
      content,
      location: location?.display_name,
      lat: location?.lat,
      lng: location?.lon,
      media: [...imageList, ...documentList],
      hash: "hash",
    };

    if (date) {
      // @ts-ignore
      data.date = format(date, "yyyy-MM-dd");
    }

    onSubmit(data);
  };

  const showFooter = imageList.length + documentList.length <= MAX_MEDIA_UPLOAD;

  return (
    <Modal
      title="Buat Laporan"
      open={visible}
      onCancel={onCancel}
      width={650}
      destroyOnClose
    >
      <form onSubmit={handleSubmit}>
        <Textarea
          label="Judul Kegiatan"
          value={title}
          onChange={setTitle}
          rows={2}
          required
        />
        <DatePicker
          type="base"
          label="Tanggal Kegiatan"
          value={date}
          onChange={setDate}
        />
        <Textarea
          label="Catatan Kegiatan"
          value={content}
          onChange={setContent}
          rows={4}
          required
        />
        <Flex direction="column" gap="16px">
          <If condition={imageList.length > 0}>
            <ImageGroup
              images={imageList}
              onChangeImages={handleChangeImages}
              removable
            />
          </If>
          <If condition={documentList.length > 0}>
            <DocumentList
              removable
              documents={documentList}
              onChangeDocs={handleChangeDocs}
            />
          </If>
          <Flex direction="column" w="100%">
            <Text>Peta Kegiatan</Text>
            <CurrentLocation
              initialValues={location}
              onChange={handleChangeLocation}
            />
          </Flex>
          <If condition={showFooter}>
            <FooterCompose
              onChangeImage={handleChangeComposeImages}
              onChangeDocs={handleChangeComposeDocs}
            />
          </If>
        </Flex>
        <Button
          htmlType="submit"
          color="secondary"
          block
          shape="circle"
          css={{ mt: "16px" }}
        >
          Posting
        </Button>
      </form>
    </Modal>
  );
}

export default ModalComposeReport;
