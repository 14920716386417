import If from "global/ErrorHandlers/If";
import FormLogin from "./FormLogin";
import useLoginStore from "./loginStore";
import QRCode from "./QRCode";

function Login() {
  const loginState = useLoginStore((s) => s.state);

  return (
    <>
      <If condition={loginState === "form"}>
        <FormLogin />
      </If>
      <If condition={loginState === "code"}>
        <QRCode />
      </If>
    </>
  );
}

export default Login;
