import { FaBell } from "react-icons/fa";
import {
  IoStorefront,
  IoBookmarks,
  IoChatbubbleEllipses,
} from "react-icons/io5";

export const navs = [
  {
    icon: <IoStorefront />,
    label: "Home",
    url: "/home",
  },
  {
    icon: <IoBookmarks />,
    label: "7D",
    url: "/7d",
    paths: ["/7d", "/reports", "/report"],
  },
  {
    icon: <IoChatbubbleEllipses />,
    label: "Chat",
    url: "/chat",
    withNotif: true,
    storeKey: "chat",
  },
  {
    icon: <FaBell />,
    label: "Notifikasi",
    url: "/notification",
    withNotif: true,
    storeKey: "notification",
  },
];

export const publicNavs = [
  {
    label: "Menu",
    url: "/menu",
  },
  {
    label: "Tentang Sigap",
    url: "/about",
  },
  {
    label: "Bantuan",
    url: "/help",
  },
];
