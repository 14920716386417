import create, { GetState, SetState } from "zustand";
import chatListSlice from "./chatStore.chat";
import chatGeneralSlice from "./chatStore.general";
import chatMemberSlice from "./chatStore.member";
import chatRoomSlice from "./chatStore.room";
import { ChatStore } from "./chatStore.types";

const chatStore = (set: SetState<any>, get: GetState<any>) => ({
  ...chatGeneralSlice(set, get),
  ...chatListSlice(set, get),
  ...chatMemberSlice(set, get),
  ...chatRoomSlice(set, get),
});

const useChatStore = create<ChatStore>(chatStore);

export default useChatStore;
