import useChatStore from "features/chat/chatStore";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import RoomItem from "./RoomItem";

interface RoomListProps {
  keyword: string;
}

const LIMIT = 10;

function RoomList({ keyword }: RoomListProps) {
  const rooms = useChatStore((s) => s.chatRoomList);
  const [activeRoomId, setActiveRoomId] = useChatStore((s) => [
    s.activeChatRoom,
    s.setActiveChatRoom,
  ]);
  const readChat = useChatStore((s) => s.readChat);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [roomList, setRoomList] = useState(rooms);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (debouncedKeyword) {
      const filteredRooms = rooms.filter((room) => {
        const roomName = room.name.toLowerCase();
        const keyword = debouncedKeyword.toLowerCase();

        return roomName.includes(keyword);
      });

      setRoomList(filteredRooms);
    } else {
      setRoomList(rooms);
    }

    const newPage = 1;
    setPage(newPage);
  }, [debouncedKeyword, rooms]);

  const handleClick = (roomId: string) => {
    setActiveRoomId(roomId);
    readChat(Number(roomId));
  };

  return (
    <div style={{ margin: "0 -20px", maxHeight: 500 }}>
      {roomList.slice(page * LIMIT - LIMIT, page * LIMIT).map((room) => (
        <RoomItem
          room={room}
          isActive={activeRoomId === room.id}
          onClick={() => handleClick(room.id)}
        />
      ))}
    </div>
  );
}

export default RoomList;
