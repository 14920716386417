import { ErrorBox } from "components/Auth";
import AuthCard from "components/Auth/AuthCard";
import { Input } from "components/Form";
import ButtonBlock from "features/login/components/ButtonBlock";
import If from "global/ErrorHandlers/If";
import { SuccessBox } from "global/StatusBox";
import useInput from "hooks/useInput";
import auth from "lib/api/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useInput("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleBack = () => {
    navigate("/login");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const res = await auth.forgotPassword(email);

    if (res.status === 200) {
      setSuccess(true);
      return;
    }

    setError(true);
    return;
  };

  return (
    <AuthCard
      title="Lupa Kata Sandi"
      onBack={handleBack}
      subtitle="Silahkan masukan email yang terdaftar untuk membuat kata sandi baru."
    >
      <If condition={success}>
        <SuccessBox msg="Silahkan cek email Kamu untuk melakukan reset kata sandi" />
      </If>
      <If condition={error}>
        <ErrorBox error="Email tidak terdaftar" />
      </If>
      <form onSubmit={handleSubmit}>
        <Input label="Email" type="email" value={email} onChange={setEmail} />
        <ButtonBlock color="secondary" htmlType="submit">
          Reset Kata Sandi
        </ButtonBlock>
      </form>
    </AuthCard>
  );
}

export default ForgotPassword;
