import BaseModal, {
  ModalProps as BaseModalProps,
} from "components/Feedback/Modal";

interface ModalProps extends BaseModalProps {}

export function Modal({ ...props }: ModalProps) {
  return (
    <BaseModal
      {...props}
      css={{
        "& .ant-modal-content": {
          bgColor: "transparent",
          boxShadow: "none",
        },
        "& .ant-modal-body": {
          padding: 0,
        },
        "& .ant-modal-close-x": {
          display: "none",
        },
      }}
    />
  );
}
