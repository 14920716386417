import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import usePostStore from "lib/stores/entities/stream";
import { useEffect, useState } from "react";
import { IAddComment, IPostItem } from "types/responses/post";
import CommentItem from "./CommentItem";
import ComposeComment from "./ComposeComment";

interface CommentProps {
  postId: string;
  user: IPostItem["user"];
  isDetail?: boolean;
}

const LIMIT = 10;

function Comment({ postId, user, isDetail = false }: CommentProps) {
  const [comments, getComments, addComment] = usePostStore((s) => [
    s.postComments,
    s.getPostComments,
    s.addPostComment,
  ]);
  const [page, setPage] = useState(1);
  const [expand, setExpand] = useState(isDetail);
  const limit = isDetail ? LIMIT : 1;

  useEffect(() => {
    if (postId) {
      const params = {
        page,
        limit,
        id: postId,
      };
      getComments(params);
    }
  }, [postId]);

  const handleExpand = () => {
    if (!expand) {
      setExpand(true);
      return;
    }

    const params = {
      page: page + 1,
      limit,
      id: postId,
    };
    getComments(params);
    setPage(page + 1);
  };

  const handleComment = async (comment: string) => {
    const data: IAddComment = {
      post: Number(postId),
      comment,
      hash: "",
      reply: null,
    };

    const res = await addComment(data);

    if (res) {
      const params = {
        page: 1,
        limit,
        id: postId,
      };
      setPage(1);
      getComments(params);

      return true;
    }

    return false;
  };

  if (!postId || !comments[postId]) {
    return null;
  }

  const postComments = comments[postId];
  const commentList = expand
    ? postComments.data
    : postComments.data.slice(0, 1);
  const composed = postComments.data.length < postComments.total || !expand;

  return (
    <>
      <ComposeComment user={user} onComment={handleComment} />
      <Flex direction="column" gap="14px" w="100%">
        {commentList.map((comment) => {
          const replies = comment.replies || [];
          const replyList = expand ? replies : replies.slice(0, 1);
          return (
            <>
              <CommentItem
                comment={comment}
                key={`comment-${postId}-${comment.id}`}
              />
              <If condition={replies.length > 0}>
                <Flex
                  direction="column"
                  gap="8px"
                  w="calc(100% - 64px)"
                  ml="64px"
                  mt="12px"
                >
                  {replyList.map((reply) => (
                    <CommentItem
                      comment={reply}
                      key={`reply-${comment.id}-${reply.id}`}
                    />
                  ))}
                </Flex>
              </If>
            </>
          );
        })}
      </Flex>
      <If condition={composed}>
        <Text
          color="$secondary"
          weight="$bold"
          size="$lg"
          cursor="pointer"
          onClick={handleExpand}
          mt="16px"
        >
          Lihat Komentar Lain
        </Text>
      </If>
    </>
  );
}

export default Comment;
