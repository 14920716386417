import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { CiWifiOff } from "react-icons/ci";
import { HiMapPin } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/date";
import { getColor } from "utils/general";
import HeaderMore from "./HeaderMore";

interface HeaderProps {
  title: string;
  createdAt: string;
  onEdit: () => void;
  onDelete: () => void;
  id?: string;
  updatedAt?: string | null;
  isOffline?: boolean;
  location?: string | null;
}

function Header({
  title,
  createdAt,
  updatedAt,
  isOffline = false,
  location = null,
  id,
  onDelete,
  onEdit,
}: HeaderProps) {
  const navigate = useNavigate();
  const postDate = updatedAt || createdAt;
  const isUpdated = updatedAt !== createdAt;

  const handleClick = () => {
    if (id) {
      navigate(`/report/detail/${id}`);
    }
  };

  return (
    <Flex justifyContent="space-between" w="100%">
      <Flex direction="column" gap="4px" flex="1">
        <Text
          as="h4"
          fontSize="$lg"
          weight="$bold"
          color="$coolGray800"
          lineHeight="25px"
          cursor="pointer"
          onClick={handleClick}
        >
          {title}
        </Text>
        <Text color="$coolGray400" lineHeight="22px" weight="$semibold">
          {formatDate(postDate, "d MMM yyyy")} {isUpdated && "(Edit)"}
        </Text>
        <If condition={isOffline}>
          <Flex alignItems="center" gap="8px">
            <CiWifiOff color={getColor("primary")} />
            <Text italic color="$primary">
              Posting menunggu koneksi internet
            </Text>
          </Flex>
        </If>
        <If condition={!!location}>
          <Flex alignItems="center" gap="8px">
            <HiMapPin color={getColor("secondary")} />
            <Text color="$secondary" weight="$bold">
              {location}
            </Text>
          </Flex>
        </If>
      </Flex>
      <HeaderMore onDelete={onDelete} onEdit={onEdit} />
    </Flex>
  );
}

export default Header;
