import { ErrorBox } from "components/Auth";
import { Button, Flex, Text } from "components/Base";
import { Password } from "components/Form";
import useProfileStore from "features/profile/profileStore";
import If from "global/ErrorHandlers/If";
import useInput from "hooks/useInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";

function ChangePassword() {
  const navigate = useNavigate();

  const [error, updatePassword, setProfileState] = useProfileStore((s) => [
    s.error,
    s.updatePassword,
    s.setProfileState,
  ]);

  const [oldPassword, setOldPassword] = useInput("");
  const [newPassword, setNewPassword] = useInput("");
  const [confirmPassword, setConfirmPassword] = useInput("");

  const [localError, setLocalError] = useState("");

  const handleCancel = () => {
    setProfileState("edit-profile");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setLocalError("Kata sandi baru tidak sama");
      return;
    }

    const reset = await updatePassword({ oldPassword, newPassword });

    if (reset) {
      notifySuccess({
        message: "Berhasil mengubah kata sandi",
        description: "Kamu perlu login kembali untuk melanjutkan",
      });

      navigate("/logout");
      return;
    }
  };

  const errorReason = localError || error;

  return (
    <Flex direction="column" w="100%" gap="24px">
      <Text weight="$medium" color="$coolGray500" lineHeight="22px">
        Untuk mengubah kata sandi, silahkan masukan kata sandi lama terlebih
        dahulu
      </Text>
      <If condition={!!errorReason}>
        <ErrorBox error={errorReason} />
      </If>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Flex direction="column" w="100%" gap="8px" mb="8px">
          <Password
            label="Kata sandi lama"
            value={oldPassword}
            onChange={setOldPassword}
          />
          <Password
            label="Kata sandi baru"
            value={newPassword}
            onChange={setNewPassword}
          />
          <Password
            label="Ulangi kata sandi baru"
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </Flex>
        <Flex justify="flex-end" alignItems="center" gap="16px">
          <Button
            htmlType="button"
            color="secondary-outline"
            size="large"
            shape="circle"
            onClick={handleCancel}
          >
            Batalkan
          </Button>
          <Button
            htmlType="submit"
            color="secondary"
            size="large"
            shape="circle"
          >
            Perbarui Kata Sandi
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}

export default ChangePassword;
