import { Card } from "components/Base";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import GlobalSearchUser from "global/SearchUser";
import useInput from "hooks/useInput";
import profileStore from "lib/stores/entities/profile";
import { useEffect, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IAddChatRoom } from "types/responses/chat";
import { IUserList } from "types/responses/users";
import { convertToEventTarget } from "utils/input";
import AddGroup from "./AddGroup";
import RoomList from "./RoomList";
import SearchUser from "./SearchUser";
import SideHeader from "./SideHeader";

function SideContent() {
  const profile = profileStore((s) => s.user);

  const [query, setQuery] = useInput("");

  const [sideContentState, setState] = useChatStore((s) => [
    s.sideContentState,
    s.setSideContentState,
  ]);
  const [activeUser, setActiveUser] = useChatStore((s) => [
    s.activeChatRoomUser,
    s.setActiveChatRoomUser,
  ]);
  const [rooms, getRooms, addRoom] = useChatStore((s) => [
    s.chatRoomList,
    s.getChatRoomList,
    s.addChatRoom,
  ]);

  const [selectedUsers, setSelectedUsers] = useState<Array<IUserList>>([]);

  useEffect(() => {
    if (rooms.length === 0) {
      getRooms({ page: 1 });
    }
  }, []);

  useEffect(() => {
    if (sideContentState) {
      setQuery(convertToEventTarget(""));
    }
  }, [sideContentState]);

  const handleSelectUser = async (user: IUserList) => {
    const data = {
      name: user.name,
      media: user.photo,
      description: "",
      users: `[${user.id}, ${profile?.id}]`,
    };

    const add = await addRoom(data);

    if (add) {
      setActiveUser(user);
    }
  };

  const handleAddGroup = async (data: IAddChatRoom) => {
    const addData = {
      ...data,
      users: `[${profile?.id},${data.users}]`,
    };

    const add = await addRoom(addData);

    if (!!add) {
      // refetch chat room list
      getRooms({ page: 1 });

      // Reset side content to chat room list
      setState("home");
    }
  };

  return (
    <Card
      p="20px"
      w="388px"
      overflow="auto"
      css={{
        height: sideContentState !== "add-group" ? "690px" : "auto",
        minHeight: sideContentState === "add-group" ? "690px" : "unset",
      }}
    >
      <SideHeader />
      <If condition={sideContentState !== "add-group"}>
        <InputSearch
          placeholder="Cari warga atau grup"
          w="100%"
          mb="10px"
          value={query}
          onChange={setQuery}
        />
      </If>
      <If condition={sideContentState === "home"}>
        <RoomList keyword={query} />
      </If>
      <If condition={sideContentState === "add-chat"}>
        <SearchUser keyword={query} onSelectUser={handleSelectUser} />
      </If>
      <If condition={sideContentState === "add-group"}>
        <AddGroup onAddGroup={handleAddGroup} />
      </If>
      {/* 
        If state = home, show chat room list
        If state = add-chat, show user list component
        If state = add-group, show user list component for group

        all of them accept query as props
      */}
    </Card>
  );
}

export default SideContent;
