import { ModalProps } from "antd";
import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import { GetPostlikeParams } from "lib/api/post";
import usePostStore from "lib/stores/entities/stream";
import { useEffect, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import InfiniteScroll from "react-infinite-scroll-component";

interface ModalLikeProps extends ModalProps {
  id: number; // ID Post
  likes: number;
}

function ModalLike({ id, likes = 0, visible, ...props }: ModalLikeProps) {
  const [list, getLikes] = usePostStore((s) => [s.postLikes, s.getPostLikes]);

  const [page, setPage] = useState(1);
  const limit = 20;

  const postLikers = list[id]?.data || [];
  const total = list[id]?.total || likes;

  useEffect(() => {
    if (visible) {
      const params: GetPostlikeParams = {
        page,
        limit,
        id: String(id),
      };

      getLikes(params);
    }
  }, [id, visible]);

  const handleLoadMore = () => {
    if (postLikers.length >= likes) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);

    const params: GetPostlikeParams = {
      page: newPage,
      limit,
      id: String(id),
    };

    getLikes(params);
  };

  const length = postLikers.length;
  const hasMore = length < total;

  return (
    <Modal
      title={`${total} Warga menyukai`}
      open={visible}
      footer={null}
      {...props}
    >
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={length}
        hasMore={hasMore}
        loader={
          <Text color="$coolGray300" size="$xs" align="center">
            Loading
          </Text>
        }
        endMessage={null}
        style={{ maxHeight: "500px" }}
      >
        {postLikers.map((item) => (
          <Flex
            key={item.id}
            alignItems="center"
            justify="space-between"
            mb="12px"
          >
            <Flex alignItems="center" gap="12px">
              <Avatar size={40} src={item.photo} />
              <Text weight="$medium" italic={!item.name}>
                {item.name || "User Tanpa Nama"}
              </Text>
            </Flex>
          </Flex>
        ))}
      </InfiniteScroll>
    </Modal>
  );
}

export default ModalLike;
