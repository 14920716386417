import DetailReport from "features/reports/detail";
import MainLayout from "global/MainLayout";

function DetailReportPage() {
  return (
    <MainLayout>
      <DetailReport />
    </MainLayout>
  );
}

export default DetailReportPage;
