import { APP_URL } from "constants/api";
import { Button, Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import dApi from "lib/api/7d";
import { useState } from "react";
import { BiDownload, BiPlus, BiListCheck } from "react-icons/bi";
import { useParams } from "react-router-dom";
import ModalDownload from "./ModalDownload";

interface SideButtonProps {
  onClickAdd: () => void;
  status?: string | null;
}

function SideButton({ onClickAdd, status = null }: SideButtonProps) {
  const { id } = useParams();
  const [stat, setStat] = useState<string | null>(status);

  const [showDownload, setShowDownload] = useState(false);

  const handleClickDone = async () => {
    const res = await dApi.update7dTakeStatus(Number(id));

    if (res.status !== 200) {
      return;
    }

    if (res.data.message) {
      return;
    }

    setStat("done");
  };

  const handleDownload = async (from: string, to: string) => {
    if (!id) {
      return;
    }

    window.open(
      `${APP_URL}/reports/print/${id}?from=${from}&to=${to}`,
      "_blank",
    );

    setShowDownload(false);
  };

  const showStatus = stat !== "done";

  return (
    <>
      <Flex direction="column" gap="20px" w="310px">
        <Button color="secondary" shape="circle" block onClick={onClickAdd}>
          <Flex gap="8px" alignItems="center">
            <BiPlus />
            <Text weight="$bold">Buat Laporan</Text>
          </Flex>
        </Button>
        <Button
          color="primary"
          shape="circle"
          block
          onClick={() => setShowDownload(true)}
        >
          <Flex gap="8px" alignItems="center">
            <BiDownload />
            <Text weight="$bold">Download Laporan</Text>
          </Flex>
        </Button>
        {/* <If condition={showStatus}>
          <Button
            color="secondary-outline"
            shape="circle"
            block
            onClick={handleClickDone}
          >
            <Flex gap="8px" alignItems="center">
              <BiListCheck />
              <Text weight="$bold">Selesaikan Laporan</Text>
            </Flex>
          </Button>
        </If> */}
      </Flex>
      <ModalDownload
        visible={showDownload}
        onCancel={() => setShowDownload(false)}
        onOk={handleDownload}
      />
    </>
  );
}

export default SideButton;
