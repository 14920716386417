import { Modal } from "components/Feedback";
import SearchUser from "global/SearchUser";
import { IUserList } from "types/responses/users";

interface ModalAddMemberProps {
  visible: boolean;
  onCancel: () => void;
  onAddMember: (params: Array<IUserList>) => void;
}

function ModalAddMember({
  visible,
  onCancel,
  onAddMember,
}: ModalAddMemberProps) {
  const handleDone = (users: Array<IUserList>) => {
    onAddMember(users);
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title="Tambah Anggota Grup"
      destroyOnClose
    >
      <SearchUser
        onDone={handleDone}
        buttonText="Tambahkan ke Grup"
        minimumMember={1}
      />
    </Modal>
  );
}

export default ModalAddMember;
