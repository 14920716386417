import produce from "immer";
import reports, { IBodyReportCerita, IBodyReportUser } from "lib/api/reports";
import { IReportReason } from "types/responses/reports";
import create from "zustand";

export type IReportedItemType = "post" | "story" | "user" | "";

interface ModalReportStore {
  reportReasons: Array<IReportReason>;

  showModalReport: boolean;
  idReportedItem: string; // Could be post id, story id, or user id
  reportedItemType: IReportedItemType;
  setReportedItem: (id: string, type?: IReportedItemType) => void;

  getReportReasons: () => Promise<void>;

  isLoading: boolean;
  postReportCerita: (body: IBodyReportCerita) => Promise<boolean>;
  postReportUser: (body: IBodyReportUser) => Promise<boolean>;
  postReportStory: (body: IBodyReportCerita) => Promise<boolean>;
}

export const useModalReportStore = create<ModalReportStore>((set) => ({
  reportReasons: [],
  showModalReport: false,

  idReportedItem: "",
  reportedItemType: "",
  isLoading: false,
  setReportedItem: (itemId, type) => {
    // Set reported item and show modal based on the values
    return set(
      produce((state: ModalReportStore) => {
        state.idReportedItem = itemId;

        // These value changed based on the item Id
        // @ts-ignore
        state.reportedItemType = itemId ? type : "";
        state.showModalReport = !!itemId;
      }),
    );
  },

  getReportReasons: async () => {
    try {
      const response = await reports.getReportReasons();

      if (response.status !== 200) {
        return;
      }

      if (response.data.message) {
        return;
      }

      set((state) => ({
        ...state,
        reportReasons: response.data,
      }));
    } catch (error) {
      console.error(error);
    }
  },
  postReportCerita: async (body) => {
    try {
      set((state) => ({
        ...state,
        isLoading: true,
      }));

      const response = await reports.reportCerita(body);

      if (response.status !== 200) {
        throw new Error("Error posting report");
      }

      if (response.data.message) {
        throw new Error("Error posting report");
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      set(
        produce((state: ModalReportStore) => {
          state.isLoading = false;
        }),
      );
    }
  },
  postReportStory: async (body) => {
    try {
      set((state) => ({
        ...state,
        isLoading: true,
      }));

      const response = await reports.reportStory(body);

      if (response.status !== 200) {
        throw new Error("Error posting report");
      }

      if (response.data.message) {
        throw new Error("Error posting report");
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      set(
        produce((state: ModalReportStore) => {
          state.isLoading = false;
        }),
      );
    }
  },
  postReportUser: async (body) => {
    try {
      set((state) => ({
        ...state,
        isLoading: true,
      }));

      const response = await reports.reportUser(body);

      if (response.status !== 200) {
        throw new Error("Error posting report");
      }

      if (response.data.message) {
        throw new Error("Error posting report");
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      set(
        produce((state: ModalReportStore) => {
          state.isLoading = false;
        }),
      );
    }
  },
}));
