import If from "global/ErrorHandlers/If";
import SearchUser from "global/SearchUser";
import { useEffect, useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IAddChatRoom } from "types/responses/chat";
import { IUserList } from "types/responses/users";
import GroupInfo from "./GroupInfo";

interface AddGroupProps {
  onAddGroup: (params: IAddChatRoom) => void;
}

function AddGroup({ onAddGroup }: AddGroupProps) {
  const [addState, setAddState] = useState<"user-list" | "group-info">(
    "user-list",
  );
  const [selectedUsers, setSelectedUsers] = useState<Array<IUserList>>([]);

  useEffect(() => {
    return () => {
      setAddState("user-list");
      setSelectedUsers([]);
    };
  }, []);

  const handleAddUser = (users: Array<IUserList>) => {
    setSelectedUsers(users);
    setAddState("group-info");
  };

  const handleAddGroup = (params: Omit<IAddChatRoom, "users">) => {
    const data = {
      ...params,
      users: selectedUsers.map((u) => u.id).join(","),
    };

    onAddGroup(data);
  };

  return (
    <>
      <If condition={addState === "user-list"}>
        <SearchUser
          onDone={handleAddUser}
          buttonText={<HiArrowNarrowRight size={20} />}
          buttonWidth="115px"
        />
      </If>
      <If condition={addState === "group-info"}>
        <GroupInfo selectedUsers={selectedUsers} onSubmit={handleAddGroup} />
      </If>
    </>
  );
}

export default AddGroup;
