import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import useId from "hooks/useId";
import { BsTrashFill } from "react-icons/bs";
import { ImPlus, ImAttachment } from "react-icons/im";
import { getColor } from "utils/general";

interface UploadMediaProps {
  value: any;
  onChange: (media: any) => void;
  size: string;
  label: string;
}

function UploadMedia({ label, value, onChange, size }: UploadMediaProps) {
  const id = useId();

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        onChange(e.target.files[0]);
      }
    } catch (error) {
      console.log(error);
      if (!value) {
        onChange(null);
      }
    }
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <Flex direction="column" w="100%" gap="8px">
      <Text color="$coolGray600">{label}</Text>
      <Flex
        p="23px"
        alignItems="center"
        color="$secondary"
        bgColor="$secondary5"
        border="dashed 1px $secondary"
        w="100%"
        br="$rounded-lg"
      >
        <label htmlFor={id} style={{ margin: "auto", cursor: "pointer" }}>
          <If condition={!value}>
            <Flex alignItems="center" justify="center" gap="8px" w="100%">
              <ImPlus />
              <Text weight="bold" fontSize="$sm">
                Unggah Gambar
              </Text>
            </Flex>
          </If>
        </label>
        <If condition={value}>
          <Flex alignItems="center" justify="space-between" gap="8px" w="100%">
            <Flex alignItems="center" gap="8px" width="calc(100% - 30px)">
              <ImAttachment />
              <Text
                ellipsis
                weight="$bold"
                fontSize="$sm"
                w="calc(100% - 30px)"
              >
                {value?.name}
              </Text>
            </Flex>
            <BsTrashFill
              color={getColor("red500")}
              onClick={handleClear}
              cursor="pointer"
            />
          </Flex>
        </If>
      </Flex>
      <input
        type="file"
        id={id}
        accept="image/jpeg, image/jpg, image/png"
        style={{ display: "none" }}
        onChange={handleChangeFile}
      />
      <Text mt="4px" fontSize="$sm" color="$coolGray600">
        Dimensi yang direkomendasikan {size}. Maksimal 2MB dengan format PNG,
        JPEG
      </Text>
    </Flex>
  );
}

export default UploadMedia;
