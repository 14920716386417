import { Grid } from "components/Base";
import useMengenal7DStore from "features/7d/mengenal7dStore";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import CardTake from "./CardTake";

function TakeList() {
  const [takeList, getTakeList] = useMengenal7DStore((s) => [
    s.takeList,
    s.getTakeList,
  ]);

  useEffect(() => {
    if (takeList.length === 0) {
      getTakeList();
    }
  }, []);

  return (
    <>
      <PageTitle title="Laporan Tahapan 7D" />
      <Grid columns="repeat(2, 1fr)" gap="22px">
        {takeList.map((item) => {
          return (
            <CardTake
              id={item.id}
              idTake={item.id_7d_take}
              title={item.title}
              description={item.sub_title}
              color={item.color}
              doneAt={item.created_at}
              status={item.status}
            />
          );
        })}
      </Grid>
    </>
  );
}

export default TakeList;
