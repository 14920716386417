import Chat from "features/chat";
import MainLayout from "global/MainLayout";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { onMessageListener } from "utils/firebase";

function ChatPage() {
  useEffect(() => {
    onMessageListener();
  }, []);

  return (
    <MainLayout>
      <PageTitle title="Chats" />
      <Chat />
    </MainLayout>
  );
}

export default ChatPage;
