import Notif from "features/notif";
import MainLayout from "global/MainLayout";
import PageTitle from "global/PageTitle";

function NotifPage() {
  return (
    <MainLayout>
      <PageTitle title="Notifikasi" />
      <Notif />
    </MainLayout>
  );
}

export default NotifPage;
