import { styled, config } from "configs/stitches.config";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import BaseProps from "types/base";
import Flex from "./Flex";
import Text from "./Text";
import type * as Stitches from "@stitches/react";

const Img = styled("img", {});

const ImgSvg = styled(ReactSVG, {});

const ImgWrapper = styled("div", {
  position: "relative",

  "& > img": {
    borderRadius: "inherit",
    height: "100%",
    left: 0,
    objectFit: "cover",
    objectPosition: "center",
    position: "absolute",
    top: 0,
    width: "100%",
  },
});

interface ImageProps
  extends BaseProps,
    Omit<React.ImgHTMLAttributes<ImageProps>, "color" | "height" | "width"> {
  css?: Stitches.CSS<typeof config>;
  caption?: string;
  autofit?: boolean;
  ratio?: string | number;
  size?: string;
}

const Image = ({
  css: cssProp = {},
  caption,
  src: source,
  alt,
  autofit,
  size,
  ...props
}: ImageProps) => {
  const { key, as, children, style, ...rest } = props;
  const [isError, setIsError] = useState(false);
  const [src, setSrc] = useState(source);

  const isBlob = source?.startsWith("blob:");
  const isBase64 = source?.startsWith("data:");
  const isUrl = source?.startsWith("http") || source?.startsWith("//");
  const isSvg = source?.endsWith(".svg");

  useEffect(() => {
    if (!isBlob && !isBase64 && !isUrl) {
      setSrc(`${process.env.PUBLIC_URL}${source}`);
    }
  }, [isBlob, isBase64, isUrl]);

  const handleErrorImage = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    if (!isError) {
      setIsError(true);

      // @ts-ignore
      e.target.onerror = null;
      setSrc(`${process.env.PUBLIC_URL}/images/no-image.png`);
    }
  };

  const componentStyle = {
    ...cssProp,
    ...rest,
  };

  if (props.ratio) {
    componentStyle.aspectRatio = props.ratio;
  }

  if (size) {
    componentStyle.width = size;
    componentStyle.height = size;
  }

  const renderContent = () => {
    if (autofit) {
      return (
        // @ts-ignore
        <ImgWrapper {...props} css={componentStyle}>
          <img alt={alt} src={src} onError={(e) => handleErrorImage(e)} />
        </ImgWrapper>
      );
    }

    if (isSvg) {
      return <ImgSvg src={src} alt={alt} {...props} css={componentStyle} />;
    }

    // @ts-ignore
    return <Img src={src} alt={alt} {...props} css={componentStyle} />;
  };

  if (caption) {
    return (
      <Flex align="center" direction="column">
        {renderContent()}
        <Text color="$coolGray400" size="$sm" marginTop="8px">
          {caption}
        </Text>
      </Flex>
    );
  }

  return renderContent();
};

export default Image;
