import { Flex, Text } from "components/Base";

interface OptionItemProps {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

function OptionItem({ label, icon, onClick }: OptionItemProps) {
  return (
    <Flex
      alignItems="center"
      gap="12px"
      color="$coolGray500"
      cursor="pointer"
      onClick={onClick}
    >
      {icon}
      <Text>{label}</Text>
    </Flex>
  );
}

export default OptionItem;
