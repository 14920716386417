import request from "configs/request";
import { APIPromiseResponse } from "types/api";

const getReportReasons = (): APIPromiseResponse => {
  return request.get("/get_report_reason_list.php");
};

export interface IBodyReportCerita {
  id: number;
  id_reason: number;
  reason?: string;
}

const reportCerita = (body: IBodyReportCerita): APIPromiseResponse => {
  return request.post("/add_post_report.php", body);
};

export interface IBodyReportUser extends IBodyReportCerita {
  block?: boolean;
}
const reportUser = (body: IBodyReportUser): APIPromiseResponse => {
  return request.post("/add_user_report.php", body);
};

const reportStory = (body: IBodyReportCerita): APIPromiseResponse => {
  return request.post("/add_story_report.php", body);
};

export default {
  getReportReasons,
  reportCerita,
  reportUser,
  reportStory,
};
