const defaultProps = {
  title: "Sigap Inspirasi dari Desa",
  description: "Sigap Inspirasi dari Desa Official Website",
  image: "/logo.png",
  url: "https://sigapp.id",
  twitterCard: "summary_large_image",
  ogType: "website",
  ogSiteName: "Sigap Inspirasi dari Desa",
  ogTitle: "Sigap Inspirasi dari Desa",
  ogDescription: "Sigap Inspirasi dari Desa Official Website",
  ogImage: "/logo.png",
  ogUrl: "https://sigapp.id",
  ogLocale: "id_ID",
  ogLocaleAlternate: "id_ID",
};

export default defaultProps;
