import { Button, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import DatePicker from "components/Form/Datepicker";
import { format } from "date-fns";
import { useEffect, useState } from "react";

interface ModalDownloadProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (from: string, to: string) => void;
}

function ModalDownload({ visible, onCancel, onOk }: ModalDownloadProps) {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  useEffect(() => {
    if (!visible) {
      setFrom("");
      setTo("");
    }
  }, [visible]);

  const handleOk = () => {
    if (!from || !to) return;

    onOk(
      format(new Date(from), "yyyy-MM-dd"),
      format(new Date(to), "yyyy-MM-dd"),
    );
  };

  const isDisabled = !from || !to;

  return (
    <Modal
      title="Download Laporan"
      open={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      <Flex direction="column" gap="12px">
        <Text>
          Untuk mendownload laporan silakan filter range terlebih dahulu
        </Text>
        <DatePicker label="Dari tanggal" value={from} onChange={setFrom} />
        <DatePicker label="Sampai tanggal" value={to} onChange={setTo} />
      </Flex>
      <Flex gap="12px" mt="20px">
        <Button block color="red-outline" shape="circle" onClick={onCancel}>
          Batalkan
        </Button>
        <Button
          shape="circle"
          block
          color={isDisabled ? "gray" : "secondary"}
          disabled={isDisabled}
          onClick={handleOk}
        >
          Download
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalDownload;
