import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Header from "global/Header";
import Footer from "global/MainLayout/Footer";
import BannerHome from "./components/banner/Banner";
import Inspiration from "./components/Inspiration";
import List7D from "./components/list-7d/List7D";
import Statistics from "./components/statistic/Statistic";

const queryClient = new QueryClient();

function Home() {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <BannerHome />
      <Statistics />
      <List7D />
      <Inspiration />
      <Footer />
    </QueryClientProvider>
  );
}

export default Home;
