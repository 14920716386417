import { Logo } from "./styled";

function DownloadLogo() {
  return (
    <>
      <a
        href="https://play.google.com/store/apps/details?id=id.sigap.perjuangan&hl=id"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo src="/images/play-store.svg" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.sigap"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo src="/images/app-store.svg" />
      </a>
    </>
  );
}

export default DownloadLogo;
