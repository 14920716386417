import notif from "lib/api/notif";
import create from "zustand";

interface GlobalNotifStore {
  chat: number;
  notification: number;
  getNotifBadge: () => void;
}

const globalNotifStore = create<GlobalNotifStore>((set) => ({
  chat: 0,
  notification: 0,
  getNotifBadge: async () => {
    try {
      const res = await notif.getNotifBadge();

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set({
        chat: data.chat,
        notification: Number(data.notification),
      });
    } catch (error) {
      console.log(error);
    }
  },
}));

export default globalNotifStore;
