import { Flex, Image, Text } from "components/Base";

interface LevelItemProps {
  name: string;
  media: string;
  isActive: boolean;
}

function LevelItem({ isActive, media, name }: LevelItemProps) {
  return (
    <Flex direction="column" alignItems="center" gap="8px">
      <Flex
        alignItems="center"
        justify="center"
        w="67px"
        h="67px"
        css={{
          background: "$white",
          borderWidth: "2px",
          borderStyle: isActive ? "solid" : "dashed",
          borderColor: isActive ? "$sunrise" : "$coolGray400",
          borderRadius: "$rounded-2xl",
        }}
      >
        <Image src={media} alt={name} css={{ w: "40px", h: "60px" }} />
      </Flex>
      <Text
        align="center"
        color={isActive ? "$sunrise" : "$coolGray400"}
        weight="bold"
        size="$xs"
      >
        {name.split(" ")[0]}
        <br />
        SIGAP
      </Text>
    </Flex>
  );
}

export default LevelItem;
