import produce from "immer";
import chatApi from "lib/api/chat";
import { ChatGeneralStore, ChatRoomStore, StoreSlice } from "./chatStore.types";

const chatRoomSlice: StoreSlice<ChatRoomStore, ChatGeneralStore> = (set) => ({
  chatRoomList: [],
  totalChatRoom: 0,
  chatRoomDetail: {},
  activeChatRoom: "",
  activeChatRoomUser: null,
  setActiveChatRoom: (id) => set((state) => ({ ...state, activeChatRoom: id })),
  setActiveChatRoomUser: (user) => set({ activeChatRoomUser: user }),
  getChatRoomList: async (params) => {
    try {
      const res = await chatApi.getChatRoomList(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        // @ts-ignore
        return set({
          chatRoomList: res.data.data,
          totalChatRoom: res.data.total,
        });
      }

      // @ts-ignore
      set(
        produce((state: ChatRoomStore) => {
          state.chatRoomList.push(...res.data.data);
          state.totalChatRoom = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  getChatRoomDetail: async (id) => {
    try {
      const res = await chatApi.getChatRoomDetail(id);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      set(
        produce((state: ChatRoomStore) => {
          state.chatRoomDetail[id] = res.data;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addChatRoom: async (data) => {
    try {
      const res = await chatApi.addChatRoom(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (res.data.id) {
        set({ activeChatRoom: res.data.id });
      }

      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  updateChatRoom: async (data) => {
    try {
      const res = await chatApi.updateChatRoom(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deleteChatRoom: async (ids) => {
    try {
      const res = await chatApi.deleteChatRoom(ids);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
});

export default chatRoomSlice;
