import request from "configs/request";
import {
  APIPromisePagination,
  APIPromiseResponse,
  GetListParams,
} from "types/api";
import {
  IAddChat,
  IAddChatResponse,
  IAddChatRoom,
  IAddChatRoomMember,
  IAddChatRoomResponse,
  IChatDetail,
  IChatGetMemberList,
  IChatRoomList,
  IDeleteChatMember,
  IDetailChatRoom,
  IUpdateChatAdmin,
} from "types/responses/chat";
import { convertRequestToFormData } from "utils/request";

const getChatRoomList = (
  params: GetListParams,
): APIPromisePagination<Array<IChatRoomList>> => {
  return request.get("/get_chat_room_list.php", {
    params: {
      page: params.page,
      size: params.limit,
      query: params.query,
    },
  });
};

const getChatRoomDetail = (id: string): APIPromiseResponse<IDetailChatRoom> => {
  return request.get("/get_chat_room_detail.php", { params: { id } });
};

const addChatRoom = (
  data: IAddChatRoom,
): APIPromiseResponse<IAddChatRoomResponse> => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/add_chat_room.php", bodyData);
};

const updateChatRoom = (
  data: IAddChatRoom,
): APIPromiseResponse<IAddChatRoomResponse> => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/update_chat_room.php", bodyData);
};

const deleteChatRoom = (ids: Array<number>): APIPromiseResponse => {
  return request.post("/delete_chat_room.php", { ids });
};

const addChatRoomMember = (data: IAddChatRoomMember): APIPromiseResponse => {
  return request.post("/add_chat_room_member.php", data);
};

const deleteChatRoomMember = (data: IDeleteChatMember): APIPromiseResponse => {
  return request.post("/delete_chat_room_member.php", data);
};

const updateChatRoomAdmin = (data: IUpdateChatAdmin): APIPromiseResponse => {
  return request.post("/update_chat_room_admin.php", data);
};

export interface GetChatList extends GetListParams {
  room: string;
}

const getChatList = (
  params: GetChatList,
): APIPromisePagination<Array<IChatDetail>> => {
  return request.get("/get_chat_list.php", {
    params: {
      page: params.page,
      size: params.limit,
      room: params.room,
    },
  });
};

const addChat = (data: IAddChat): APIPromiseResponse<IAddChatResponse> => {
  const bodyData = convertRequestToFormData(data);

  return request.post("/add_chat.php", bodyData);
};

const readChat = (room: number): APIPromiseResponse => {
  return request.post("/add_chat_read.php", { room });
};

const deleteChat = (ids: Array<number>): APIPromiseResponse => {
  return request.post("/delete_chat.php", { ids });
};

const getNonMemberList = (
  params: GetChatList,
): APIPromisePagination<Array<IChatGetMemberList>> => {
  return request.get("/get_room_user_list.php", {
    params: {
      page: params.page,
      size: params.limit,
      room: params.room,
      query: params.query,
    },
  });
};

const getMemberList = (
  params: GetChatList,
): APIPromisePagination<Array<IChatGetMemberList>> => {
  return request.get("/get_room_member_list.php", {
    params: {
      page: params.page,
      size: params.limit,
      room: params.room,
      query: params.query,
    },
  });
};

export default {
  getChatRoomList,
  getChatRoomDetail,
  addChatRoom,
  updateChatRoom,
  deleteChatRoom,
  addChatRoomMember,
  deleteChatRoomMember,
  updateChatRoomAdmin,

  getChatList,
  addChat,
  readChat,
  deleteChat,

  getNonMemberList,
  getMemberList,
};
