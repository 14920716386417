import { Flex, Image, Text } from "components/Base";
import useProfileStore, { ProfileState } from "features/profile/profileStore";
import If from "global/ErrorHandlers/If";
import { AiFillPlusCircle } from "react-icons/ai";
import { getColor } from "utils/general";

interface SideNavProps {
  label: React.ReactNode;
  value: ProfileState;
  icon: string;
  isActive?: boolean;
  onClick?: () => void;
  idInput?: string;
}

function SideNav({
  label,
  value,
  icon,
  isActive,
  onClick,
  idInput,
}: SideNavProps) {
  const setProfileState = useProfileStore((s) => s.setProfileState);

  const color = isActive ? "$secondary" : "$coolGray400";
  const bg = isActive ? "$secondary5" : "$white";

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    setProfileState(value);
  };

  return (
    <Flex
      w="100%"
      p="20px"
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      br="$rounded-lg"
      backgroundColor={bg}
      css={{
        "&:hover": {
          background: isActive ? "$secondary5" : "$coolGray50",
        },
      }}
      onClick={handleClick}
    >
      <Flex alignItems="center" gap="16px">
        <Flex w="22px" justify="center">
          <Image
            src={`/icons/profile/${icon}.svg`}
            alt={"sidenav-icon"}
            css={{ h: "20px", color }}
          />
        </Flex>
        <If condition={typeof label === "string"}>
          <Text size="$lg" weight="$bold" lineHeight="24px" color={color}>
            {label}
          </Text>
        </If>
        <If condition={typeof label !== "string"}>{label}</If>
      </Flex>
      <If condition={value === "story"}>
        <label htmlFor={idInput}>
          <AiFillPlusCircle
            size={24}
            color={getColor("secondary")}
            cursor="pointer"
          />
        </label>
      </If>
    </Flex>
  );
}

export default SideNav;
