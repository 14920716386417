import { Button, Flex, Text } from "components/Base";
import ComposePost from "global/Compose";
import usePostStore from "lib/stores/entities/stream";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IAddPost } from "types/responses/post";
import PointCard from "./PointCard";

function SideContent() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addPost, getPostList] = usePostStore((s) => [
    s.addPost,
    s.getPostList,
  ]);

  const handleSubmit = async (data: IAddPost) => {
    setIsLoading(true);
    const post = await addPost(data);

    if (post) {
      const params = {
        page: 1,
        limit: 10,
      };
      setIsLoading(false);

      getPostList(params);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setIsLoading(false);
  };

  const handleClickPoint = () => {
    navigate("/profile?tab=level");
  };

  return (
    <>
      <Flex w="314px" direction="column" gap="16px">
        <PointCard onClick={handleClickPoint} />
        <Button
          color="secondary"
          block
          shape="circle"
          onClick={() => setVisible(true)}
        >
          <Flex alignItems="center" gap="8px" color="$white">
            <FaPlus />
            <Text weight="$bold">Buat Inspirasi Desa</Text>
          </Flex>
        </Button>
      </Flex>
      <ComposePost
        open={visible}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
}

export default SideContent;
