import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import userApi from "lib/api/user";
import { useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import { IPostUser } from "types/responses/post";
import { getColor } from "utils/general";

interface ModalTagProps {
  visible: boolean;
  selectedUsers?: Array<IPostUser>;
  onCancel: () => void;
  onSelectUser: (user: Array<IPostUser>) => void;
}

function ModalTag({
  visible,
  selectedUsers: initialValues,
  onCancel,
  onSelectUser,
}: ModalTagProps) {
  const [query, setQuery] = useState("");

  const [users, setUsers] = useState<Array<IPostUser>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<IPostUser>>(
    initialValues || [],
  );

  const [timeInterval, setTimeInterval] = useState<any>(0);

  const handleSearch = (keyword: string) => {
    const params = {
      query: keyword,
      page: 1,
      limit: 15,
    };
    userApi
      .getUserList(params)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);

    if (timeInterval) {
      clearTimeout(timeInterval);
    }

    setTimeInterval(
      setTimeout(() => {
        handleSearch(value);
      }, 500),
    );
  };

  const handleSelectUser = (user: IPostUser) => {
    const isExist = selectedUsers.find((item) => item.id === user.id);
    if (!isExist) {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const handleRemoveUser = (user: IPostUser) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
  };

  const handleDone = () => {
    onSelectUser(selectedUsers);

    setQuery("");
    onCancel();
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title="Tandai Warga"
      width={650}
      zIndex={1055}
    >
      <Flex w="100%" alignItems="center" gap="8px" mb="16px">
        <Flex flex="1">
          <InputSearch
            placeholder="Cari warga"
            w="100%"
            onChange={handleChange}
          />
        </Flex>
        <Text
          color="$secondary"
          weight="$bold"
          cursor="pointer"
          onClick={handleDone}
        >
          Selesai
        </Text>
      </Flex>
      <If condition={selectedUsers.length > 0}>
        <Flex
          direction="column"
          gap="16px"
          w="100%"
          pb="16px"
          mb="32px"
          borderBottom="solid 1px $coolGray600"
        >
          <Text fontSize="$sm" weight="$bold">
            Ditandai
          </Text>
          <Flex w="100%" gap="24px" wrap="wrap">
            {selectedUsers.map((user, index) => (
              <Flex
                direction="column"
                alignItems="center"
                gap="8px"
                css={{ maxWidth: 100 }}
                key={`tagged-users-${index}`}
              >
                <Flex w="40px" h="40px" position="relative">
                  <Avatar size={40} src={user.photo} />
                  <IoCloseCircle
                    style={{ position: "absolute", bottom: 0, right: 0 }}
                    color={getColor("red500")}
                    size={16}
                    onClick={() => handleRemoveUser(user)}
                    cursor="pointer"
                  />
                </Flex>
                <Text align="center">{user.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </If>
      <If condition={query.length === 0}>
        <Flex justify="center" align="center">
          <Text fontSize="$md" color="$coolGray400">
            Silakan input nama warga terlebih dahulu
          </Text>
        </Flex>
      </If>
      <If condition={query.length > 0 && users.length > 0}>
        <Flex direction="column" gap="16px" w="100%">
          {users.map((user, index) => {
            const isSelected = selectedUsers.find(
              (item) => item.id === user.id,
            );

            return (
              <Flex
                alignItems="center"
                gap="16px"
                key={`${index}`}
                cursor="pointer"
                onClick={() => handleSelectUser(user)}
              >
                <Flex w="40px" h="40px" position="relative">
                  <Avatar size={40} src={user.photo} />
                  <If condition={!!isSelected}>
                    <IoIosCheckmarkCircle
                      style={{ position: "absolute", bottom: 0, right: 0 }}
                      color={getColor("secondary")}
                      size={16}
                    />
                  </If>
                </Flex>
                <Text weight="$bold" fontSize="$md">
                  {user.name}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </If>
    </Modal>
  );
}

export default ModalTag;
