import produce from "immer";
import chatApi from "lib/api/chat";
import { StoreSlice } from "lib/stores/types";
import { ChatListStore, ChatRoomStore } from "./chatStore.types";

const chatListSlice: StoreSlice<ChatListStore, ChatRoomStore> = (set) => ({
  chatList: {},
  getChatList: async (params) => {
    try {
      const res = await chatApi.getChatList(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set(
          produce((state: ChatListStore) => {
            state.chatList[params.room] = {
              data: res.data.data,
              total: res.data.total,
            };
          }),
        );
      }

      // @ts-ignore
      set(
        produce((state: ChatListStore) => {
          state.chatList[params.room].data.push(...res.data.data);
          state.chatList[params.room].total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addChat: async (data) => {
    try {
      const res = await chatApi.addChat(data);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      set(
        produce((state: ChatListStore) => {
          state.chatList[data.room].data.unshift(res.data);
        }),
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  deleteChat: async (ids) => {
    try {
      const res = await chatApi.deleteChat(ids);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      // @ts-ignore
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  readChat: async (room) => {
    try {
      const res = await chatApi.readChat(room);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data === null) {
        return false;
      }

      // @ts-ignore
      set(
        produce((state: ChatListStore & ChatRoomStore) => {
          const idx = state.chatRoomList.findIndex(
            (item) => Number(item.id) === Number(room),
          );

          if (idx !== -1) {
            state.chatRoomList[idx].unread_count = 0;
          }
        }),
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
});

export default chatListSlice;
