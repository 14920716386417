import { Flex, Image } from "components/Base";
import StreamWidget from "global/StreamWidget";
import profileStore from "lib/stores/entities/profile";
import usePostStore from "lib/stores/entities/stream";

function Inspirasi() {
  const user = profileStore((s) => s.user);

  if (!user) {
    return null;
  }

  return (
    <>
      <StreamWidget userId={Number(user.id)} />
    </>
  );
}

export default Inspirasi;
