import { Box, Button, Flex, Text } from "components/Base";
import { Textarea } from "components/Form";
import useChatStore from "features/chat/chatStore";
import useStoryStore from "features/explore/components/Story/storyStore";
import If from "global/ErrorHandlers/If";
import useProfile from "hooks/useProfile";
import { useState } from "react";
import { FaRegHeart, FaHeart, FaPaperPlane } from "react-icons/fa";
import { IAddChat, IAddChatRoom } from "types/responses/chat";
import { IStory } from "types/responses/story";
import { getColor } from "utils/general";

interface FooterStoryProps {
  story: IStory;
}

function FooterStory({ story }: FooterStoryProps) {
  const [addChatRoom, addChat] = useChatStore((s) => [
    s.addChatRoom,
    s.addChat,
  ]);
  const [likeStory, unlikeStory] = useStoryStore((s) => [
    s.likeStory,
    s.unlikeStory,
  ]);
  const user = useProfile();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [liked, setLiked] = useState(story.liked === "1");

  const handleLike = async () => {
    const handler = liked ? unlikeStory : likeStory;
    const action = await handler(story.id);

    if (action) {
      setLiked((prevState) => !prevState);
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleBlur = () => {
    if (message === "") {
      setOpen(false);
    }
  };

  const handleClickSubmit = async () => {
    const dataChatRoom: IAddChatRoom = {
      name: "",
      description: "",
      users: `[${story.id_user}, ${user?.id}]`,
    };

    const addRoom = await addChatRoom(dataChatRoom);

    if (addRoom) {
      const dataChat: IAddChat = {
        room: addRoom.id,
        chat: message,
        attachment_id: story.id,
        attachment_type: "story",
      };

      const replyStory = await addChat(dataChat);

      if (replyStory) {
        setMessage("");
        setOpen(false);
      }
    }
  };

  return (
    <Box
      css={{
        position: "absolute",
        bottom: 0,
        transform: "translateY(-8px)",
        zIndex: 2,
        w: "calc(100% - 45px)",
      }}
      p="16px"
    >
      <Flex alignItems="flex-end" gap="12px">
        <Box
          css={{
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            mb: "16px",
            flex: 1,
          }}
        >
          <Text color="$white">{story.caption}</Text>
        </Box>
        <Button
          shape="circle"
          icon={
            !liked ? (
              <FaRegHeart color={getColor("red400")} />
            ) : (
              <FaHeart color={getColor("red400")} />
            )
          }
          css={{ mb: "16px" }}
          onClick={handleLike}
        />
      </Flex>
      <Flex alignItems="center" gap="12px">
        <Box css={{ flex: 1 }}>
          <Textarea
            placeholder="Komentar"
            rows={1}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></Textarea>
        </Box>
        <If condition={open}>
          <Button
            color="secondary"
            shape="circle"
            icon={<FaPaperPlane />}
            css={{ mb: "16px" }}
            onClick={handleClickSubmit}
          />
        </If>
      </Flex>
    </Box>
  );
}

export default FooterStory;
