import { Flex, Text } from "components/Base";
import Rangepicker from "components/Form/Datepicker/Range";
import useMengenal7DStore from "features/7d/mengenal7dStore";
import ModalDelete from "global/Modals/ModalDelete";
import PageTitle from "global/PageTitle";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import ModalComposeReport from "./components/ModalCompose";
import ReportCard from "./components/ReportCard";
import SideButton from "./components/SideButton";
import useReportStore from "./reportStore";

const PAGE_SIZE = 10;

function Reports() {
  const { id } = useParams();
  const postReport = useReportStore((s) => s.postReport);

  const [list, getList] = useMengenal7DStore((s) => [
    s.takeList,
    s.getTakeList,
  ]);
  const [reportList, totalReports, getReportList] = useReportStore((s) => [
    s.reportList,
    s.total,
    s.getReportList,
  ]);

  // Show modal copmose
  const [showCompose, setShowCompose] = useReportStore((s) => [
    s.showModal,
    s.setShowModal,
  ]);

  // Active report
  const [activeReport, setActiveReport] = useReportStore((s) => [
    s.activeReport,
    s.setActiveReport,
  ]);

  // Modal delete
  const [showDelete, setShowDelete, deleteReport] = useReportStore((s) => [
    s.showDelete,
    s.setShowDelete,
    s.deleteReport,
  ]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (id && page === 1) {
      getReportList({
        id,
        page,
        limit: PAGE_SIZE,
      });
    }
  }, [id]);

  useEffect(() => {
    if (list.length === 0) {
      getList();
    }
  }, [list]);

  const handleLoadMore = (isVisible: boolean) => {
    const hasMore = reportList.length < totalReports;
    if (isVisible && hasMore) {
      const newPage = page + 1;
      getReportList({
        // @ts-ignore
        id,
        page: newPage,
        limit: PAGE_SIZE,
      });

      setPage(newPage);
    }
  };

  const handleCancelCompose = () => {
    setShowCompose(false);
    setActiveReport(null);
  };

  const handleCancelDelete = () => {
    setShowDelete(false);
  };

  const handleSubmitCompose = async (data: any) => {
    const composeData = {
      ...data,
    };

    if (activeReport) {
      composeData.id = activeReport.id;
      // Get removed media by comparing with active report media
      const removedMedia = activeReport.media.filter(
        (item) => !data.media.includes(item.media),
      );

      const removedMediaId = removedMedia.map((item) => item.id);
      composeData.removed_media = `[${removedMediaId.join(",")}]`;
    } else {
      composeData.id_7d_take = id;
    }

    const post = await postReport(composeData);

    if (post) {
      const newPage = 1;
      getReportList({
        // @ts-ignore
        id,
        page: newPage,
        limit: PAGE_SIZE,
      });

      setPage(newPage);
      setShowCompose(false);
      setActiveReport(null);
    }
  };

  const handleDelete = async () => {
    if (activeReport) {
      const del = await deleteReport(activeReport.id);

      if (del) {
        const newPage = 1;
        getReportList({
          // @ts-ignore
          id,
          page: newPage,
          limit: PAGE_SIZE,
        });

        setPage(newPage);
        setShowDelete(false);
      }
    }
  };

  const item = list.find((item) => item.id_7d_take === id);

  if (!item) {
    return null;
  }

  return (
    <>
      <PageTitle title={`Laporan: ${item.title}`} backUrl="/7d" />
      <Flex alignItems="flex-start" gap="32px" width="100%">
        <SideButton
          onClickAdd={() => setShowCompose(true)}
          status={item.status}
        />
        <Flex direction="column" gap="16px" css={{ flex: 1 }}>
          <Flex gap="16px">
            <Text color="$coolGray600" w="150px" mt="8px">
              Filter Tanggal
            </Text>
            {/* @ts-ignore */}
            <Rangepicker />
          </Flex>
          <Flex direction="column" gap="16px">
            {reportList.map((report) => (
              <ReportCard report={report} />
            ))}
          </Flex>
          <ReactVisibilitySensor onChange={handleLoadMore}>
            <div>&nbsp;</div>
          </ReactVisibilitySensor>
        </Flex>
      </Flex>
      <ModalComposeReport
        visible={showCompose}
        onCancel={handleCancelCompose}
        onSubmit={handleSubmitCompose}
        initialValues={activeReport}
      />
      <ModalDelete
        title="Hapus Laporan?"
        visible={showDelete}
        onCancel={handleCancelDelete}
        onDelete={handleDelete}
      />
    </>
  );
}

export default Reports;
