import { DOCUMENT_FILES_EXTENSION } from "constants/app";
import { Card, Flex } from "components/Base";
import If from "global/ErrorHandlers/If";
import DocumentList from "global/Post/components/DocumentList";
import ImageGroup from "global/Post/components/ImageGroup";
import TextContent from "global/Post/components/TextContent";
import { useMemo } from "react";
import { IPostItem } from "types/responses/post";
import { getMediaExtension } from "utils/media";
import Category from "./Category";
import Comment from "./Comment";
import Footer from "./Footer";
import Header from "./Header";

interface PostProps {
  post: IPostItem;
  isDetail?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  showComment?: boolean;
}

function Post({
  post,
  isDetail = false,
  onDelete,
  onEdit,
  showComment = true,
}: PostProps) {
  const filterMedia = (source: Array<any>, equality: boolean) => {
    const mediaList = post.media.filter((media) => {
      const ext = getMediaExtension(media.media);

      return source.includes(ext) === equality;
    });

    return mediaList.map((item) => item.media);
  };

  const imageList = useMemo(() => {
    return filterMedia(DOCUMENT_FILES_EXTENSION, false);
  }, [post.media]);

  const documentList = useMemo(() => {
    return filterMedia(DOCUMENT_FILES_EXTENSION, true);
  }, [post.media]);

  return (
    <Card p="32px" w="100%" mb="16px">
      <Header post={post} onDelete={onDelete} onEdit={onEdit} />
      <Flex direction="column" gap="14px" w="100%">
        <Category category={post.category} />
        <TextContent text={post.content} />
        <If condition={imageList.length > 0}>
          <ImageGroup images={imageList} />
        </If>
        <If condition={documentList.length > 0}>
          <DocumentList documents={documentList} />
        </If>
      </Flex>
      <Footer
        postId={post.id}
        totalComment={Number(post.total_comment)}
        totalLike={Number(post.total_like)}
        liked={post.liked === "1"}
        content={post.content}
      />
      <If condition={showComment}>
        <Comment postId={post.id} user={post.user} isDetail={isDetail} />
      </If>
    </Card>
  );
}

export default Post;
