import { Flex, Image, Text } from "components/Base";
import { Input } from "components/Form";
import ModalContact from "features/login/components/ModalContact";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io5";
import { getColor } from "utils/general";

interface FilterHelpProps {
  value: string;
  onChange: (value: string) => void;
}

function FilterHelp({ value, onChange }: FilterHelpProps) {
  const [visible, setVisible] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <>
      <Flex
        position="relative"
        w="100%"
        bgColor="$secondary2"
        p="25px 32px"
        gap="10px"
        direction="column"
      >
        <Text size="$xl" weight="$bold" color="$white" lineHeight="25px">
          Ada yang bisa dibantu?
        </Text>
        <Text color="$white" lineHeight="22px">
          Kamu bisa cari topik yang ingin ditanyakan
        </Text>
        <Input
          prefix={<BsSearch color={getColor("coolGray300")} />}
          css={{ borderRadius: "$rounded-full" }}
          w="100%"
          zIndex={10}
          value={value}
          onChange={handleChange}
          allowClear
        />
        <Image
          src="/images/faq.png"
          alt="faq icon"
          position="absolute"
          top="0"
          right="0"
        />
        <Text align="center" mx="auto" color="$white">
          atau
        </Text>
        <Flex
          justify="center"
          align="center"
          gap="8px"
          css={{ cursor: "pointer" }}
          bgColor="$primary"
          p="8px 32px"
          br="$rounded-full"
          mx="auto"
          onClick={() => setVisible(true)}
        >
          <IoLogoWhatsapp color={getColor("secondary")} />
          <Text weight="bold" color="$secondary">
            Hubungi Kami
          </Text>
        </Flex>
      </Flex>
      <ModalContact visible={visible} onCancel={() => setVisible(false)} />
    </>
  );
}

export default FilterHelp;
