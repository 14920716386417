import { Flex, Image, Text } from "components/Base";
import { StyledCard } from "./Statistic.styled";

interface StatCardProps {
  image: string;
  total: number;
  title: string;
  bgColor?: string; // default: $primary3
}

function StatCard({
  image,
  total,
  title,
  bgColor = "$primary3",
}: StatCardProps) {
  return (
    <StyledCard backgroundColor={bgColor}>
      <Image
        className="stat-topo"
        src={`/images/topo-${image}.png`}
        position="absolute"
      />
      <Flex direction="column" gap="4px">
        <Text className="stat-title">
          {total}
          {total > 50 && "+"}
        </Text>
        <Text className="stat-subtitle">{title}</Text>
      </Flex>
      <Image className="stat-icon" src={`/images/${image}.png`} />
    </StyledCard>
  );
}

export default StatCard;
