import { APP_URL } from "constants/api";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import AuthCard from "components/Auth/AuthCard";
import { Box, Flex, Text } from "components/Base";
import { Input, Phone, Password } from "components/Form";
import ButtonBlock from "features/login/components/ButtonBlock";
import ModalContact from "features/login/components/ModalContact";
import useInput from "hooks/useInput";
import { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { getColor } from "utils/general";
import * as Yup from "yup";
import useRegisterStore from "./registerStore";

const registerSchema = Yup.object().shape({
  name: Yup.string().required("Nama lengkap harus diisi"),
  email: Yup.string().email().required("Email harus diisi"),
  phone: Yup.string().min(8).required("Nomor handphone harus diisi"),
  password: Yup.string().min(6).required("Kata sandi harus diisi"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Kata sandi tidak sama")
    .required("Ulangi kata sandi"),
});

function RegisterForm() {
  const navigate = useNavigate();

  const register = useRegisterStore((s) => s.register);

  const [name, setName] = useInput("");
  const [email, setEmail] = useInput("");
  const [phone, setPhone] = useInput("");
  const [password, setPassword] = useInput("");
  const [confirmPassword, setConfirmPassword] = useInput("");

  const [checked, setChecked] = useState(false);

  const [errors, setErrors] = useState<any>({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [visible, setVisible] = useState(false);

  const handleBack = () => {
    navigate("/login");
  };

  const handleChangeCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const validateFields = () => {
    registerSchema
      .validate(
        { name, email, phone, password, confirmPassword },
        { abortEarly: false },
      )
      .catch((err) => {
        const errorField = err.inner.reduce((acc: any, curr: any) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});

        setErrors(errorField);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name,
      email,
      phone: "62" + phone,
      password,
      confirmPassword,
    };

    const isValid = registerSchema.isValidSync(data);

    if (!isValid) {
      validateFields();
      return;
    }

    // delete confirmPassword
    // @ts-ignore
    delete data.confirmPassword;

    // Register user
    await register(data);
  };

  return (
    <AuthCard
      title="Daftar"
      subtitle="Untuk mulai, silahkan isi form dibawah ini."
      onBack={handleBack}
    >
      <form onSubmit={handleSubmit}>
        <Input
          label="Nama Lengkap"
          value={name}
          onChange={setName}
          required
          status={errors.name && "error"}
          errorMessage={errors.name}
        />
        <Input
          label="Alamat Email"
          type="email"
          value={email}
          onChange={setEmail}
          required
          status={errors.email && "error"}
          errorMessage={errors.email}
        />
        <Phone
          label="Nomor Handphone"
          value={phone}
          onChange={setPhone}
          required
          status={errors.phone && "error"}
          errorMessage={errors.phone}
        />
        <Password
          label="Kata Sandi"
          value={password}
          onChange={setPassword}
          required
          status={errors.password && "error"}
          errorMessage={errors.password}
        />
        <Password
          label="Ulangi Kata Sandi"
          value={confirmPassword}
          onChange={setConfirmPassword}
          required
          status={errors.confirmPassword && "error"}
          errorMessage={errors.confirmPassword}
        />
        <Box mt="8px" mb="16px" w="100%">
          <Checkbox checked={checked} onChange={handleChangeCheck}>
            Dengan mendaftar, saya menyetujui{" "}
            <a
              href={`${APP_URL}/terms-and-condition.html`}
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: getColor("secondary"), fontWeight: "bold" }}
            >
              Kebijakan Privasi
            </a>{" "}
            aplikasi Sigap.
          </Checkbox>
        </Box>
        <ButtonBlock color="primary" disabled={!checked} htmlType="submit">
          Daftar Sekarang
        </ButtonBlock>
      </form>
      <Text my="16px" align="center">
        Sudah punya akun?{" "}
        <Link to="/login">
          <Text as="span" weight="$bold" color="$secondary">
            Masuk di sini
          </Text>
        </Link>
      </Text>
      <Flex
        justify="center"
        align="center"
        gap="8px"
        css={{ cursor: "pointer" }}
        onClick={() => setVisible(true)}
      >
        <IoLogoWhatsapp color={getColor("secondary")} />
        <Text weight="bold" color="$secondary">
          Hubungi Kami
        </Text>
      </Flex>
      <ModalContact visible={visible} onCancel={() => setVisible(false)} />
    </AuthCard>
  );
}

export default RegisterForm;
