import { Box } from "components/Base";
import { isSameDay } from "date-fns";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import profileStore from "lib/stores/entities/profile";
import { useCallback, useEffect, useMemo, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { IChatDetail } from "types/responses/chat";
import BubbleMessage from "../ChatBubbles/BubbleMessage";
import ChatInfo from "../ChatBubbles/ChatInfo";
import ComposeChat from "../ComposeChat";

function ChatConvo() {
  const profile = profileStore((s) => s.user);

  const [sideChatState, setSideChatState, getChatRoomList] = useChatStore(
    (s) => [s.sideContentState, s.setSideContentState, s.getChatRoomList],
  );
  const [activeRoomId, chatList, getChatList] = useChatStore((s) => [
    s.activeChatRoom,
    s.chatList,
    s.getChatList,
  ]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    // get initial chat list
    if (activeRoomId) {
      const params = {
        page: 1,
        limit: 20,
        room: activeRoomId,
      };

      getChatList(params);

      // Scroll to bottom of chat content
      const chatContent = document.getElementById("chat-content");
      if (chatContent) {
        chatContent.scrollTop = chatContent.scrollHeight;
      }
    }
  }, [activeRoomId]);

  const convoList = useMemo(() => {
    if (!activeRoomId || !chatList[activeRoomId]) {
      return [];
    }

    return chatList[activeRoomId].data;
  }, [chatList, activeRoomId]);

  const messageList = useMemo(() => {
    const messages: Array<IChatDetail> = [];
    let lastDate = "0001-01-01";

    const chatConvo = convoList?.slice()?.reverse();

    chatConvo.forEach((chat, index) => {
      const createdDate = new Date(chat.created_at);
      const addSeparator = !isSameDay(createdDate, new Date(lastDate));

      if (addSeparator) {
        messages.push({
          id_chat_room: chat.id_chat_room,
          id: `separator-${index}`,
          created_at: chat.created_at,
          id_user: null,
          chat: "",
          media: "",
          deleted: "0",
          room: chat.room,
          type: "date",
        });

        lastDate = chat.created_at;
      }

      messages.push(chat);
    });

    return messages;
  }, [convoList]);

  const handleSuccessSubmit = () => {
    // get new chat list
    if (activeRoomId) {
      // TODO: After implement listener to fcmtoken, we can remove this
      // const params = {
      //   page: 1,
      //   limit: 20,
      //   room: activeRoomId,
      // };

      // getChatList(params);

      if (sideChatState !== "home") {
        setSideChatState("home");
        getChatRoomList({ page: 1 });
      }
    }
  };

  const handleLoadMore = (isVisible: boolean) => {
    if (activeRoomId && isVisible) {
      const chatData = chatList[activeRoomId];

      if (chatData.data.length >= chatData.total) {
        return;
      }

      const newPage = page + 1;
      const params = {
        page: newPage,
        limit: 20,
        room: activeRoomId,
      };

      getChatList(params);
      setPage(newPage);
    }
  };

  const renderContent = () => {
    if (!activeRoomId || !chatList[activeRoomId]) {
      return null;
    }

    return messageList.map((chat) => {
      const isAuthor = chat.id_user === profile?.id;

      if (chat.id_user) {
        return (
          <BubbleMessage
            key={`chat-id-${chat.id}`}
            chat={chat}
            isAuthor={isAuthor}
          />
        );
      }

      return (
        <ChatInfo
          key={`chat-info-${chat.id}`}
          chat={chat}
          isMessage={chat.type !== "date"}
        />
      );
    });
  };

  const hasConvo = messageList.length > 0;

  return (
    <Box w="100%" h="620px" p="20px 32px">
      <Box id="chat-content" w="100%" h="524px" mb="20px" overflow="auto">
        <If condition={hasConvo}>
          <VisibilitySensor onChange={handleLoadMore}>
            <div>&nbsp;</div>
          </VisibilitySensor>
        </If>
        {renderContent()}
      </Box>
      <ComposeChat onSuccessSubmit={handleSuccessSubmit} />
    </Box>
  );
}

export default ChatConvo;
