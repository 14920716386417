import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ModalReport from "global/Modals/ModalReport";
import SEO from "global/SEO";
import Footer from "./Footer";
import { MainContent } from "./styled";
import Header from "../Header";

const queryClient = new QueryClient();

interface MainLayoutProps {
  children: React.ReactNode;
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <SEO />
      <Header />
      <MainContent>{children}</MainContent>
      <Footer />
      <ModalReport />
    </QueryClientProvider>
  );
}

export default MainLayout;
