import { NOTIF_ACTIONS } from "constants/app";
import { addNewChat, addNewGroupChat } from "features/chat/chatStore/utils";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import authStore from "lib/stores/entities/auth";
import { getFcmToken, saveFcmToken } from "./authStore";

// TODO: Replace the following with your app's Firebase project configuration

const firebaseConfig = {
  apiKey: "AIzaSyBw2X1vSuPWVjFKBdUtXv-0x7-CyD04MjI",
  authDomain: "sigap-perjuangan.firebaseapp.com",
  projectId: "sigap-perjuangan",
  storageBucket: "sigap-perjuangan.appspot.com",
  messagingSenderId: "142718635111",
  appId: "1:142718635111:web:c26522435e9b374d7858c2",
  measurementId: "G-2Z3RHXV6DP",
};

const VAPID_KEY =
  "BAKvWCoHKZHYlJziJsGED-d640IYNjfqRztKSbmZBaaUGuBGZl8o3rYi_ZHhApfPSAyCMmNWe9AX_jV7cf2DeRQ";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        const fcmToken = authStore.getState().fcmToken || getFcmToken();

        if (fcmToken !== currentToken) {
          authStore.setState({ fcmToken: currentToken });
          saveFcmToken(currentToken);
        }
        // Save the Token to local storage and global state
        // console.log("current token for client: ", currentToken);

        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one.",
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

const parseChatMessage = (payload) => {
  const { data } = payload;
  const { action_data } = data;

  const parsedData = JSON.parse(action_data);

  // Do nothing if parsed data is not an object
  if (typeof parsedData !== "object") {
    addNewGroupChat(parsedData);
    return;
  }

  addNewChat(parsedData);
};

// TODO: Add message listener here
// Background Message Handler
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if (payload.data?.main_click_action === NOTIF_ACTIONS.CHAT) {
        parseChatMessage(payload);
      }
      // console.log("payload", payload);
      resolve(payload);
    });
  });
