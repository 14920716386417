import { Button } from "components/Base";
import { ButtonProps } from "components/Base/Button";

interface ButtonBlockProps extends ButtonProps {
  children: React.ReactNode;
}

function ButtonBlock({ children, ...props }: ButtonBlockProps) {
  const style = {
    br: "$rounded-full",
    p: "10px 0",
    w: "100%",
    fontWeight: "$bold",
  };
  return (
    <Button {...props} css={{ "&.ant-btn": style }}>
      {children}
    </Button>
  );
}

export default ButtonBlock;
