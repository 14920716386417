import { Flex, Text } from "components/Base";
import Avatar from "global/Avatar";
import useDebounce from "hooks/useDebounce";
import userApi from "lib/api/user";
import { useEffect, useState } from "react";
import { IUserList } from "types/responses/users";

interface SearchUserProps {
  keyword: string;
  onSelectUser: (user: IUserList) => void;
}

function SearchUser({ keyword, onSelectUser }: SearchUserProps) {
  const debouncedKeyword = useDebounce(keyword, 500);
  const [userList, setUserList] = useState<Array<IUserList>>([]);

  useEffect(() => {
    if (debouncedKeyword) {
      handleSearchUser(debouncedKeyword);
    } else {
      setUserList([]);
    }
  }, [debouncedKeyword]);

  const handleSearchUser = async (keyword: string) => {
    const params = {
      query: keyword,
      page: 1,
      limit: 15,
    };
    const res = await userApi.getUserList(params);

    if (res.status === 200) {
      setUserList(res.data.data);
    }
  };

  if (!debouncedKeyword) {
    return (
      <Text weight="$medium" align="center" fontSize="$sm" color="$coolGray400">
        Silakan input nama warga terlebih dulu
      </Text>
    );
  }

  return (
    <div style={{ margin: "0 -4px" }}>
      {userList.map((user) => (
        <Flex
          key={`search-result-${user.id}`}
          alignItems="center"
          w="100%"
          gap="16px"
          cursor="pointer"
          onClick={() => onSelectUser(user)}
          br="$rounded-lg"
          p="8px"
          css={{
            "&:hover": {
              bgColor: "$coolGray100",
            },
          }}
        >
          <Avatar src={user.photo} name={user.name} size={40} />
          <Text weight="$bold" ellipsis>
            {user.name}
          </Text>
        </Flex>
      ))}
    </div>
  );
}

export default SearchUser;
