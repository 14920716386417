import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Box, Button, Flex, Image, Text } from "components/Base";
import DownloadLogo from "global/MainLayout/DownloadLogo";
import { useNavigate } from "react-router-dom";
import { BannerContainer, BannerWrapper } from "./Banner.styled";

function BannerHome() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <BannerContainer>
      <BannerWrapper>
        <Flex direction="column" css={{ flex: 1 }}>
          <Text
            className="title-app"
            as="h1"
            weight="$bold"
            lineHeight="60px"
            color="$secondary"
          >
            SIGAP - Inspirasi Desa
          </Text>
          <Box
            css={{
              display: "-webkit-box",
              "-webkit-line-clamp": 3,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          >
            <Text className="subtitle-app" as="h2">
              SIGAP: AKSI INSPIRATIF WARGA UNTUK PERUBAHAN SIGAP
            </Text>
          </Box>
          <Button
            color="secondary"
            css={{
              "&.ant-btn": {
                p: "10px 32px",
                br: "$rounded-full",
                fontWeight: "$bold",
              },
            }}
            mb="32px"
            onClick={handleClick}
          >
            <Text as="span" fontSize="$md" color="inherit">
              Ketahui Lebih Lanjut
            </Text>
            <span style={{ marginLeft: 10 }}>
              <ArrowRightIcon />
            </span>
          </Button>
          <Text
            fontSize="$xl"
            lineHeight="24px"
            weight="$bold"
            mt="32px"
            mb="12px"
          >
            Download Aplikasi di
          </Text>
          <Flex gap="24px" align="center">
            <DownloadLogo />
          </Flex>
        </Flex>
        <Image src="/images/banner.png" />
      </BannerWrapper>
    </BannerContainer>
  );
}

export default BannerHome;
