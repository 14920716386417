import { Flex } from "components/Base";
import If from "global/ErrorHandlers/If";
import { useEffect, useMemo } from "react";
import NotifGroup from "./components/NotifGroup";
import EmptyNotif from "./Empty";
import useNotifStore from "./notifStore";

function Notif() {
  const [list, getList] = useNotifStore((s) => [s.notifList, s.getNotifList]);

  useEffect(() => {
    getList();
  }, []);

  const hasList = useMemo(() => {
    if (list.length === 0) {
      return false;
    }

    return list.some((group) => group.data.length > 0);
  }, [list]);

  return (
    <>
      <If condition={!hasList}>
        <EmptyNotif />
      </If>
      <If condition={hasList}>
        <Flex
          background="$white"
          direction="column"
          w="792px"
          mx="auto"
          gap="16px"
        >
          {list.map((group) => {
            if (group.data.length === 0) {
              return null;
            }

            return (
              <NotifGroup
                key={`notif-group-${group.title}`}
                title={group.title}
                listNotif={group.data}
              />
            );
          })}
        </Flex>
      </If>
    </>
  );
}

export default Notif;
