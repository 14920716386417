import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { useEffect, useState } from "react";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { getColor } from "utils/general";
import { downloadMedia, getMediaName } from "utils/media";

interface DocumentListProps {
  documents: Array<string | File>;
  removable?: boolean;
  onChangeDocs?: (docs: any[]) => void;
}

function DocumentList({
  documents,
  removable,
  onChangeDocs = () => {},
}: DocumentListProps) {
  const [docs, setDocs] = useState<Array<string | File>>([]);
  const documentLength = documents.length;

  useEffect(() => {
    if (documentLength > 0) {
      setDocs(documents);
    }
  }, [documentLength]);

  const onDownloadClick = (document: string | File) => {
    if (typeof document === "string") {
      downloadMedia(document);
    }
  };

  const onRemoveClick = (index: number) => {
    const newDocs = [...docs];
    newDocs.splice(index, 1);
    setDocs(newDocs);
    onChangeDocs(newDocs);
  };

  return (
    <Flex direction="column" w="100%" maxWidth="547px" gap="8px">
      {docs.map((document, index) => {
        const isFile = document instanceof File;
        const mediaName = isFile ? document.name : getMediaName(document);

        return (
          <Flex
            p="8px 14px"
            br="$rounded-sm"
            bg="$primary3"
            w="100%"
            justify="space-between"
            alignItems="center"
            key={`${index}-${mediaName}`}
          >
            <Flex alignItems="center" gap="14px">
              <BsFileEarmarkTextFill color={getColor("coolGray800")} />
              <Text color="$coolGray800">{mediaName}</Text>
            </Flex>
            <If condition={!removable}>
              <HiDownload
                color={getColor("coolGray800")}
                cursor="pointer"
                onClick={() => onDownloadClick(document)}
              />
            </If>
            <If condition={!!removable}>
              <IoClose
                color={getColor("coolGray800")}
                cursor="pointer"
                onClick={() => onRemoveClick(docs.indexOf(document))}
              />
            </If>
          </Flex>
        );
      })}
    </Flex>
  );
}

export default DocumentList;
