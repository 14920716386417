import { Button, Flex, Image } from "components/Base";
import { Textarea } from "components/Form";
import useStoryStore from "features/explore/components/Story/storyStore";
import useInput from "hooks/useInput";
import { HiPaperAirplane } from "react-icons/hi2";
import { IAddStory } from "types/responses/story";
import { convertToEventTarget } from "utils/input";
import { Modal } from "./styled";

interface ModalStoryProps {
  visible: boolean;
  story: File | null;
  onCancel: () => void;
  onSuccessSubmit: () => void;
}

function ModalStory({
  visible,
  story,
  onCancel,
  onSuccessSubmit,
}: ModalStoryProps) {
  const addStory = useStoryStore((s) => s.addStory);
  const [caption, setCaption] = useInput("");

  const handleCancel = () => {
    setCaption(convertToEventTarget(""));
    onCancel();
  };

  const handleSubmit = async () => {
    if (!story) {
      return;
    }

    const data: IAddStory = {
      caption,
      media: story,
    };

    const res = await addStory(data);

    if (res) {
      onSuccessSubmit();
      handleCancel();
    }
  };

  if (!story) return null;

  const imageUrl = URL.createObjectURL(story);

  return (
    <Modal open={visible} onCancel={handleCancel} width={750}>
      <Flex w="100%" gap="16px">
        <Flex
          w="405px"
          h="720px"
          align="center"
          justify="center"
          bgColor="$coolGray900"
        >
          <Image src={imageUrl} w="100%" />
        </Flex>
        <Flex flex="1" direction="column" gap="14px" align="flex-end">
          <Textarea
            placeholder="Tambahkan keterangan"
            rows={6}
            value={caption}
            onChange={setCaption}
          />
          <Button
            color="secondary"
            css={{
              "&.ant-btn": {
                p: "10px 40px",
                br: "$rounded-full",
                fontWeight: "$bold",
              },
            }}
            onClick={handleSubmit}
          >
            <Flex alignItems="center" gap="16px">
              Posting Story
              <HiPaperAirplane />
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalStory;
