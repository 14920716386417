import { Flex, Text } from "components/Base";
import useChatStore from "features/chat/chatStore";
import Avatar from "global/Avatar";
import { useEffect } from "react";
import GroupMember from "./GroupMember";

function GroupDetail() {
  const [activeId, rooms, getDetail] = useChatStore((s) => [
    s.activeChatRoom,
    s.chatRoomDetail,
    s.getChatRoomDetail,
  ]);

  useEffect(() => {
    if (activeId) {
      getDetail(activeId);
    }
  }, [activeId]);

  if (!activeId || !rooms[activeId]) {
    return null;
  }

  const activeChat = rooms[activeId];

  return (
    <Flex direction="column" w="100%" gap="32px" p="64px 32px 32px 32px">
      <Flex alignItems="center" gap="12px">
        <Avatar src={activeChat.media} alt={activeChat.name} size={42} />
        <Text weight="$bold" lineHeight="20px">
          {activeChat.name}
        </Text>
      </Flex>
      <Flex direction="column" gap="8px">
        <Text weight="$bold" color="$coolGray600">
          Deskripsi
        </Text>
        <Text
          color="$coolGray500"
          lineHeight="22px"
          italic={!activeChat.description}
        >
          {activeChat.description || "Grup ini belum memiliki deskripsi"}
        </Text>
      </Flex>
      <GroupMember
        isAdmin={activeChat.is_admin === "1"}
        groupName={activeChat.name}
      />
      {/* Add group member section */}
    </Flex>
  );
}

export default GroupDetail;
