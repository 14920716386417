import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps } from "antd";
import If from "./ErrorHandlers/If";

interface AvatarProps extends AntdAvatarProps {
  name?: string;
  src: string;
}

function Avatar({ src, name = "Sigapp", alt, ...props }: AvatarProps) {
  const additionalStyle = src ? {} : { backgroundColor: "#BB6BD9" };
  return (
    <AntdAvatar style={additionalStyle} src={src} {...props}>
      <If condition={!src && !props.children}>
        {name
          .split(" ")
          .slice(0, 2)
          .map((nama) => nama[0])
          .join("")}
      </If>
      <If condition={!!props.children}>{props.children}</If>
    </AntdAvatar>
  );
}

export default Avatar;
