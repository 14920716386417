import { Flex } from "components/Base";
import ComposePost from "global/Compose";
import ModalDelete from "global/Modals/ModalDelete";
import Post from "global/StreamWidget/components/Post";
import usePostStore from "lib/stores/entities/stream";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";

function PostDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [post, getDetail] = usePostStore((s) => [s.post, s.getPostDetail]);
  const [updatePost, deletePost] = usePostStore((s) => [
    s.updatePost,
    s.deletePost,
  ]);

  const [showDelete, setShowDelete] = useState(false);

  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  const handleDelete = async () => {
    const del = await deletePost(Number(id));

    if (del) {
      notifySuccess({
        message: "Berhasil menghapus inspirasi desa",
      });
      navigate("/home");
    }
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setIsLoadingEdit(false);
  };

  const handleEdit = async (data: any) => {
    setIsLoadingEdit(true);

    const update = await updatePost(data);

    if (update) {
      // @ts-ignore
      getDetail(id);
    }
  };

  if (!id || !post[id]) {
    return null;
  }

  const postDetail = post[id];

  return (
    <Flex direction="column" w="874px" mx="auto">
      <Post
        post={postDetail}
        onDelete={() => setShowDelete(true)}
        onEdit={() => setShowEdit(true)}
        isDetail
      />
      <ModalDelete
        visible={showDelete}
        onCancel={() => setShowDelete(false)}
        onDelete={handleDelete}
      />
      <ComposePost
        open={showEdit}
        onCancel={handleCloseEdit}
        initialValues={postDetail}
        onSubmit={handleEdit}
        isLoading={isLoadingEdit}
      />
    </Flex>
  );
}

export default PostDetail;
