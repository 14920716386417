import { VIDEO_FILES_EXTENSION } from "constants/app";
import { Modal } from "antd";
import { Flex, Image } from "components/Base";
import download from "downloadjs";
import If from "global/ErrorHandlers/If";
import uniqueId from "hooks/useId";
import { HiDownload } from "react-icons/hi";
import { IoCloseCircle } from "react-icons/io5";
import ReactPlayer from "react-player";
import { getMediaExtension, getMediaName } from "utils/media";

interface ModalImagesProps {
  visible: boolean;
  onCancel: () => void;
  images: Array<string | File>;
  removable?: boolean;
  onRemove?: (index: number) => void;
}

function ModalImages({
  visible,
  onCancel,
  images,
  onRemove = () => {},
  removable = false,
}: ModalImagesProps) {
  const renderMedia = (media: string | File) => {
    const isFile = media instanceof File;
    const mediaName = isFile ? media.name : media;
    const mediaUrl = isFile ? URL.createObjectURL(media) : media;
    const id = uniqueId("image-item");

    const ext = getMediaExtension(mediaName);
    const isVideo = VIDEO_FILES_EXTENSION.includes(ext);

    if (isVideo) {
      return (
        <Flex w="574px" br="$rounded-lg" key={id}>
          <ReactPlayer width={574} height={322} url={mediaUrl} controls />
        </Flex>
      );
    }

    return (
      <Image
        src={mediaUrl}
        css={{ maxWidth: "574px" }}
        br="$rounded-lg"
        key={id}
      />
    );
  };

  const handleDownload = (media: string | File) => {
    if (media instanceof File) {
      return;
    }
    const fileName = getMediaName(media);

    download(media, fileName);

    // const link = document.createElement("a");
    // link.href = media;
    // link.download = media;
    // link.target = "_blank";
    // link.rel = "noopener noreferrer";
    // link.style.display = "none";

    // document.body.appendChild(link);
    // link.click();

    // link.remove();
    // document.body.removeChild(link);
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={655}
      title="Lihat Foto & Video"
      footer={null}
      zIndex={1052}
    >
      <Flex
        direction="column"
        w="100%"
        gap="12px"
        css={{ maxHeight: "700px", overflow: "auto" }}
      >
        {images.map((image, index) => (
          <Flex w="100%" gap="16px" key={`image-${index}`}>
            {renderMedia(image)}
            <If condition={!removable}>
              <HiDownload
                size={22}
                cursor="pointer"
                onClick={() => handleDownload(image)}
              />
            </If>
            <If condition={removable}>
              <IoCloseCircle
                size={22}
                cursor="pointer"
                onClick={() => onRemove(index)}
              />
            </If>
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
}

export default ModalImages;
