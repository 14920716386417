import { APP_URL } from "constants/api";
import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { IPostComment } from "types/responses/post";

interface CommentItemProps {
  comment: Omit<IPostComment, "replies">;
  onReply?: () => void;
}

function CommentItem({ comment, onReply = () => {} }: CommentItemProps) {
  const com = comment.comment;
  const formattedComment = useMemo(() => {
    const regex = /<mention\b[^>]*>(.*?)<\/mention>/gi;
    const formatted = com.replace(regex, (match, token) => {
      let tmpDiv = document.createElement("div");
      tmpDiv.innerHTML = match;

      const userId = tmpDiv.querySelector("mention")?.getAttribute("id");
      return `<a href="${APP_URL}/user/${userId}" target="_blank">${token}</a>`;
    });

    return formatted;
  }, [com]);

  return (
    <Flex w="100%" gap="16px">
      <Avatar size={40} src={comment.user.photo} />
      <Flex direction="column" gap="8px" flex="1">
        <Flex
          direction="column"
          gap="8px"
          w="100%"
          border="solid 2px $coolGray200"
          p="8px 16px"
          br="$rounded-lg"
        >
          <Flex justify="space-between" w="100%">
            <Text
              color="$coolGray800"
              lineHeight="22px"
              weight="$bold"
              size="$lg"
              italic={!comment.user.name}
            >
              {comment.user.name || "User Tanpa Nama"}
            </Text>
            <Text color="$coolGray600">
              {formatDistanceToNow(new Date(comment.created_at))}
            </Text>
          </Flex>
          <Text color="$coolGray600" lineHeight="22px">
            <div
              className="text-comment"
              dangerouslySetInnerHTML={{ __html: formattedComment }}
            />
          </Text>
        </Flex>
        <Text
          color="$secondary"
          weight="$bold"
          cursor="pointer"
          onClick={onReply}
        >
          Balas
        </Text>
      </Flex>
    </Flex>
  );
}

export default CommentItem;
