import { Flex } from "components/Base";
import MainContent from "./components/MainContent";
import SideContent from "./components/SideContent";

function Chat() {
  return (
    <Flex w="100%" gap="16px">
      <SideContent />
      <MainContent />
    </Flex>
  );
}

export default Chat;
