import produce from "immer";
import userApi from "lib/api/user";
import { GetListParams } from "types/api";
import { IUserDetail, IUserList } from "types/responses/users";
import create from "zustand";

interface UserStore {
  user: Record<string, IUserDetail>;
  userList: Array<IUserList>;
  total: number;
  getUserList: (params: GetListParams) => void;
  getUserDetail: (id: string) => void;
}

const useUserStore = create<UserStore>((set) => ({
  user: {},
  userList: [],
  total: 0,
  getUserList: async (params) => {
    try {
      const res = await userApi.getUserList(params);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      if (params.page === 1) {
        return set({ userList: res.data.data, total: res.data.total });
      }

      set(
        produce((draft) => {
          draft.userList.push(...res.data.data);
          draft.total = res.data.total;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  getUserDetail: async (id) => {
    try {
      const res = await userApi.getUserDetail(id);

      if (res.status !== 200) {
        throw new Error("Error");
      }

      if (res.data.message) {
        throw new Error(res.data.message);
      }

      set(
        produce((draft) => {
          draft.user[id] = res.data;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
}));

export default useUserStore;
