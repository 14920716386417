import { MAX_MEDIA_UPLOAD } from "constants/app";
import { message } from "antd";
import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { useState } from "react";
import { IOpenMapLocation } from "types/responses/location";
import { IPostUser } from "types/responses/post";
import FooterIcon from "./FooterIcon";
import ModalLocation from "../Modals/ModalLocation";
import ModalTag from "../Modals/ModalTag";

interface FooterComposeProps {
  withPeople?: boolean;
  withLocation?: boolean;
  onChangeImage?: (arr: Array<File>) => void;
  onChangeDocs?: (arr: Array<any>) => void;
  onChangeLocation?: (location: Partial<IOpenMapLocation>) => void;
  onChangeUser?: (users: Array<IPostUser>) => void;
}

function FooterCompose({
  withPeople = false,
  withLocation = false,
  onChangeImage = () => {},
  onChangeDocs = () => {},
  onChangeLocation,
  onChangeUser,
}: FooterComposeProps) {
  const [showLocation, setShowLocation] = useState(false);
  const [showTag, setShowTag] = useState(false);

  const handleChangeMedia = (e: any, callback: (params: any) => void) => {
    try {
      const files = e.target.files;

      if (files.length > MAX_MEDIA_UPLOAD) {
        message.error(`Maksimal ${MAX_MEDIA_UPLOAD} file`);
        return;
      }

      // Set files to array
      const arr: Array<any> = [];
      for (let i = 0; i < files.length; i++) {
        arr.push(files[i]);
      }

      callback(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectLocation = (location: Partial<IOpenMapLocation>) => {
    if (onChangeLocation) {
      onChangeLocation(location);
    }
  };

  const handleSelectUser = (users: Array<IPostUser>) => {
    if (onChangeUser) {
      onChangeUser(users);
    }
  };

  return (
    <>
      <Flex
        justify="space-between"
        alignItems="center"
        p="13px 16px"
        border="solid 1px $coolGray200"
        w="100%"
      >
        <Text weight="$semibold">Tambahkan ke Postingan</Text>
        <Flex alignItems="center" justify="flex-end" gap="20px">
          <FooterIcon htmlFor="attach-image" src="/icons/attach-image.svg" />
          <FooterIcon htmlFor="attach-docs" src="/icons/attach-document.svg" />
          <If condition={withPeople}>
            <FooterIcon
              src="/icons/attach-person.svg"
              onClick={() => setShowTag(true)}
            />
          </If>
          <If condition={withLocation}>
            <FooterIcon
              src="/icons/attach-location.svg"
              onClick={() => setShowLocation(true)}
            />
          </If>
        </Flex>
        <input
          id="attach-image"
          type="file"
          style={{ display: "none" }}
          multiple
          accept="image/*, video/*"
          onChange={(e) => handleChangeMedia(e, onChangeImage)}
        />
        <input
          id="attach-docs"
          type="file"
          style={{ display: "none" }}
          multiple
          accept="application/pdf"
          onChange={(e) => handleChangeMedia(e, onChangeDocs)}
        />
      </Flex>
      <ModalLocation
        visible={showLocation}
        onCancel={() => setShowLocation(false)}
        onSelect={handleSelectLocation}
      />
      <ModalTag
        visible={showTag}
        onCancel={() => setShowTag(false)}
        onSelectUser={handleSelectUser}
      />
    </>
  );
}

export default FooterCompose;
