import { Card } from "components/Base";
import { styled } from "configs/stitches.config";

export const StyledCard = styled(Card, {
  display: "flex",
  boxShadow: "none",
  br: "8px",
  p: "20px 12px",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  height: "102px",
  alignItems: "center",
  justifyContent: "space-between",

  "& .stat-title": {
    fontWeight: "$bold",
    fontSize: "$2xl",
  },
  "& .stat-subtitle": {
    fontWeight: "500",
    fontSize: "$lg",
  },
  "& .stat-topo": {
    left: "-65%",
  },
  "& .stat-icon": {
    width: "36px",
  },

  "@md": {
    height: "119px",
    p: "20px",

    "& .stat-title": {
      fontSize: "$3xl",
    },
    "& .stat-subtitle": {
      fontSize: "$xl",
    },
    "& .stat-topo": {
      left: "0",
    },
    "& .stat-icon": {
      width: "48px",
    },
  },

  "@xl": {
    height: "169px",
    p: "32px",

    "& .stat-title": {
      fontSize: "$4xl",
    },
    "& .stat-subtitle": {
      fontSize: "$2xl",
    },
    "& .stat-topo": {
      left: "0",
    },
    "& .stat-icon": {
      width: "80px",
    },
  },
});
