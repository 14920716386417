import { Flex, Text } from "components/Base";
import { Input } from "components/Form";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import useInput from "hooks/useInput";
import { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { HiPaperAirplane } from "react-icons/hi2";
import { ImAttachment } from "react-icons/im";
import { IAddChat } from "types/responses/chat";
import { getColor } from "utils/general";
import { convertToEventTarget } from "utils/input";

interface ComposeChatProps {
  onSuccessSubmit?: () => void;
}

function ComposeChat({ onSuccessSubmit = () => {} }: ComposeChatProps) {
  const activeRoomId = useChatStore((s) => s.activeChatRoom);
  const postChat = useChatStore((s) => s.addChat);

  const [message, setMessage] = useInput("");

  const [file, setFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];

      if (!file) {
        throw new Error("No File");
      }

      setFile(file);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const data: IAddChat = {
      chat: message,
      room: activeRoomId,
    };

    if (file) {
      data.media = file;
    }

    const post = await postChat(data);

    if (post) {
      setMessage(convertToEventTarget(""));
      handleRemoveFile();
      onSuccessSubmit();
    }
  };

  const handleRemoveFile = () => {
    const input = document.getElementById("chat-add-file");

    setFile(null);

    if (input) {
      // @ts-ignore
      input.value = "";
    }
  };

  return (
    <Flex direction="column" w="100%" position="relative">
      <If condition={!!file}>
        <Flex
          alignItems="center"
          justify="space-between"
          p="8px"
          br="$rounded-lg"
          position="absolute"
          bgColor="$coolGray100"
          w="100%"
          top="-40px"
        >
          <Text weight="bold">{file?.name}</Text>
          <BsTrashFill
            cursor="pointer"
            color={getColor("red500")}
            onClick={() => handleRemoveFile()}
          />
        </Flex>
      </If>
      <Input
        placeholder="Ketikan pesan"
        prefix={
          <label htmlFor="chat-add-file">
            <ImAttachment color={getColor("secondary")} />
          </label>
        }
        suffix={
          <HiPaperAirplane
            color={getColor("coolGray400")}
            onClick={() => handleSubmit()}
          />
        }
        css={{
          mb: "0px",
          br: "$rounded-full",
        }}
        value={message}
        onChange={setMessage}
        onPressEnter={handleSubmit}
      />
      <input
        type="file"
        id="chat-add-file"
        onChange={handleChange}
        accept="image/*"
        style={{ display: "none" }}
        hidden
      />
    </Flex>
  );
}

export default ComposeChat;
