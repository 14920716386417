import { Button, Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";

interface ModalInfoProps {
  visible: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  onConfirm: (params?: any) => void;
  confirmText?: string;
}

function ModalInfo({
  visible,
  title = "Informasi",
  onConfirm,
  children,
  confirmText,
}: ModalInfoProps) {
  return (
    <Modal
      title={
        <Text weight="$bold" align="center">
          {title}
        </Text>
      }
      open={visible}
      onCancel={onConfirm}
      footer={null}
    >
      <Flex justify="center">{children ? children : "Modal Info"}</Flex>

      <Flex justify="center" alignItems="center" gap="12px" mt="20px">
        <Button shape="circle" color="secondary" onClick={onConfirm}>
          {confirmText || "Oke"}
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalInfo;
