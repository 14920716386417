import { Button, Flex, Grid } from "components/Base";
import { Input, Textarea } from "components/Form";
import useProfileStore from "features/profile/profileStore";
import useInput from "hooks/useInput";
import { UpdateProfileParams } from "lib/api/profile";
import profileStore from "lib/stores/entities/profile";
import { useEffect, useState } from "react";
import { convertToEventTarget } from "utils/input";
import { getMediaName } from "utils/media";
import { notifySuccess } from "utils/notification";
import UploadMedia from "./UploadMedia";

function EditProfile() {
  const profile = profileStore((s) => s.user);
  const getProfile = profileStore((s) => s.getProfile);
  const setProfileState = useProfileStore((s) => s.setProfileState);
  const updateProfile = useProfileStore((s) => s.updateProfile);

  const [name, setName] = useInput("");
  const [bio, setBio] = useInput("");

  const [photo, setPhoto] = useState<any>(null);
  const [cover, setCover] = useState<any>(null);

  useEffect(() => {
    if (profile) {
      setName(convertToEventTarget(profile.name));
      setBio(convertToEventTarget(profile.bio));

      // Set media state
      if (profile.photo) {
        const profilePhoto = {
          name: getMediaName(profile.photo),
        };
        setPhoto(profilePhoto);
      }

      if (profile.cover) {
        const profileCover = {
          name: getMediaName(profile.cover),
        };
        setCover(profileCover);
      }
    }
  }, [profile]);

  const handleCancel = () => {
    setProfileState("edit-profile");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || !bio) {
      return;
    }

    const data: UpdateProfileParams = {
      name,
      bio,
    };

    if (photo) {
      data["photo"] = photo;
    }

    if (cover) {
      data["cover"] = cover;
    }

    const update = await updateProfile(data);

    if (update) {
      // Reset file state
      setPhoto(null);
      setCover(null);

      notifySuccess({
        message: "Profil berhasil diperbarui",
      });

      getProfile();
    }
  };

  if (!profile) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Flex direction="column" w="100%" gap="8px">
        <Input label="Nama Lengkap" value={name} onChange={setName} />
        <Grid columns="repeat(2, minmax(352px, 352px))" gap="24px" mb="16px">
          <UploadMedia
            label="Foto Profil"
            value={photo}
            onChange={setPhoto}
            size="500 x 500"
          />
          <UploadMedia
            label="Foto Cover"
            value={cover}
            onChange={setCover}
            size="800 x 333"
          />
        </Grid>
        <Textarea label="Biodata Diri" value={bio} onChange={setBio} rows={5} />
      </Flex>
      <Flex alignItems="center" justify="flex-end" w="100%" gap="16px">
        <Button
          htmlType="button"
          color="secondary-outline"
          size="large"
          shape="circle"
          onClick={handleCancel}
          css={{
            "&.ant-btn": {
              p: "10px 40px",
              br: "$rounded-full",
              fontWeight: "$bold",
            },
          }}
        >
          Batalkan
        </Button>
        <Button
          htmlType="submit"
          color="secondary"
          size="large"
          shape="circle"
          css={{
            "&.ant-btn": {
              p: "10px 40px",
              br: "$rounded-full",
              fontWeight: "$bold",
            },
          }}
        >
          Simpan
        </Button>
      </Flex>
    </form>
  );
}

export default EditProfile;
