import Header from "./Header";
import MobileHeader from "./MobileHeader";

// Header for logged in users
function AppHeader() {
  return (
    <>
      <MobileHeader />
      <Header />
    </>
  );
}

export default AppHeader;
