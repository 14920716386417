import { Button } from "components/Base";
import { Link } from "react-router-dom";
import { ButtonAuthWrapper } from "./styled";

function ButtonAuth() {
  return (
    <ButtonAuthWrapper>
      <Link to="/login" style={{ flex: 1 }}>
        <Button
          block
          color="secondary"
          css={{
            "&.ant-btn": {
              w: "100%",
              p: "10px 40px",
              br: "$rounded-full",
              fontWeight: "$bold",
            },
          }}
        >
          Login
        </Button>
      </Link>
      <Link to="/register" style={{ flex: 1 }}>
        <Button
          block
          color="secondary-outline"
          css={{
            "&.ant-btn": {
              w: "100%",
              p: "10px 40px",
              br: "$rounded-full",
              fontWeight: "$bold",
            },
          }}
        >
          Daftar
        </Button>
      </Link>
    </ButtonAuthWrapper>
  );
}

export default ButtonAuth;
