import ForgotPassword from "features/forgot-password";
import MainLayout from "global/MainLayout";

function ForgotPasswordPage() {
  return (
    <MainLayout>
      <div style={{ margin: "30px 0 90px" }}>
        <ForgotPassword />
      </div>
    </MainLayout>
  );
}

export default ForgotPasswordPage;
