import { SECOND_API_URL } from "constants/api";
import { AxiosResponse } from "axios";
import request from "configs/request";
import { APIPromiseResponse } from "types/api";
import {
  IDetailGoogleMapLocation,
  IGoogleMapLocation,
  IOpenMapLocation,
} from "types/responses/location";

const LOCATION_URL = SECOND_API_URL + "/location";
const OPEN_MAP_URL = "https://nominatim.openstreetmap.org/reverse";
const GOOGLE_MAP_URL =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json";

const getLocation = (
  query?: string,
  type: string = "city",
): APIPromiseResponse => {
  return request.get(LOCATION_URL, {
    params: { query, type },
  });
};

const getOpenMapLocation = ({
  lat,
  lng,
  zoom = 15,
}: {
  lat: number;
  lng: number;
  zoom?: number;
}): Promise<AxiosResponse<IOpenMapLocation>> => {
  return request.get(OPEN_MAP_URL, {
    params: {
      format: "json",
      lat,
      lon: lng,
      zoom,
    },
  });
};

const getSearchLocation = (
  query: string,
): Promise<AxiosResponse<{ predictions: Array<IGoogleMapLocation> }>> => {
  return request.get(`${LOCATION_URL}/search`, { params: { query } });
};

interface IDetailLocation {
  html_attributions: Array<string>;
  result: IDetailGoogleMapLocation;
  status: string;
}

const getDetailLocation = (
  placeId: string,
): Promise<AxiosResponse<IDetailLocation>> => {
  return request.get(`${LOCATION_URL}/detail/${placeId}`);
};

export default {
  getLocation,
  getOpenMapLocation,
  getSearchLocation,
  getDetailLocation,
};
