import produce from "immer";
import { GetPostListParams } from "lib/api/7d";
import dApi from "lib/api/7d";
import { I7DReport, IAdd7DReport } from "types/responses/7d";
import create from "zustand";

interface ReportStore {
  reportList: Array<I7DReport>;
  total: number;
  report: Record<string, I7DReport>;
  showModal: boolean;
  showDelete: boolean;
  activeReport: I7DReport | null;
  setShowModal: (show: boolean) => void;
  setShowDelete: (show: boolean) => void;
  setActiveReport: (report: I7DReport | null) => void;
  getReportList: (params: GetPostListParams) => void;
  getReportDetail: (id: string) => void;
  postReport: (data: IAdd7DReport) => Promise<boolean>;
  deleteReport: (id: string) => Promise<boolean>;
}

const useReportStore = create<ReportStore>((set) => ({
  reportList: [],
  total: 0,
  report: {},
  showDelete: false,
  showModal: false,
  activeReport: null,
  setShowModal: (show) => set({ showModal: show }),
  setShowDelete: (show) => set({ showDelete: show }),
  setActiveReport: (report) => set({ activeReport: report }),
  getReportList: async (params) => {
    try {
      const res = await dApi.get7dPostList(params);

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      if (params.page === 1) {
        return set({ reportList: data.data, total: data.total });
      }

      return set(
        produce((draft: ReportStore) => {
          draft.reportList = [...draft.reportList, ...data.data];
          draft.total = data.total;
        }),
      );
    } catch (error) {
      return error;
    }
  },
  getReportDetail: async (id) => {
    try {
      const res = await dApi.get7dPostDetail(id);

      if (res.status !== 200) {
        return;
      }

      const data = res.data;

      if (data.message) {
        return;
      }

      set(
        produce((draft: ReportStore) => {
          draft.report[id] = data;
        }),
      );
    } catch (error) {
      return error;
    }
  },
  postReport: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const req = data.id ? dApi.update7dPost : dApi.add7dPost;
        const res = await req(data);

        if (res.status !== 200) {
          throw new Error("Error");
        }

        const resData = res.data;

        if (resData.message) {
          throw new Error("Error");
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteReport: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await dApi.delete7dPost(Number(id));

        if (res.status !== 200) {
          return;
        }

        const data = res.data;

        if (data.message) {
          return;
        }

        set(
          produce((draft: ReportStore) => {
            draft.reportList = draft.reportList.filter(
              (report) => report.id !== id,
            );
          }),
        );

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },
}));

export default useReportStore;
