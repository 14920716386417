import { useQuery } from "@tanstack/react-query";
import { Box, Flex } from "components/Base";
import user from "lib/api/user";
import StatCard from "./StatCard";
import SectionTitle from "../SectionTitle";

function Statistics() {
  const { data } = useQuery({
    queryKey: ["getUserStats"],
    queryFn: async () => {
      try {
        const res = await user.getUserStats();

        if (res.status !== 200) {
          throw new Error("Gagal mengambil data");
        }

        if (!res.data) {
          throw new Error("Data user kosong");
        }

        return res.data.data;
      } catch (err) {
        console.log(err);
        return {};
      }
    },
    initialData: {
      total_new_users: 0,
      total_active_users: 0,
    },
  });

  return (
    <Box as="section" py="60px" px="16px" bgColor="$white">
      <SectionTitle title="Bergabunglah bersama Sigap" />
      <Flex
        margin="auto"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxWidth="850px"
        gap="16px"
      >
        <StatCard
          image="add-user"
          total={data.total_new_users || 0}
          title="User Baru"
        />
        <StatCard
          bgColor="$secondary4"
          image="active-user"
          total={data.total_active_users || 0}
          title="User Aktif"
        />
      </Flex>
    </Box>
  );
}

export default Statistics;
