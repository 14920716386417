export const convertToEventTarget = (value: any) => {
  return {
    target: {
      value,
    },
  };
};

// convert /r/n to <br>
export const convertNewLines = (value: string) => {
  return value.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const getContentLines = (value: string) => {
  return value.split(/(?:\r\n|\r|\n)/g).length;
};
