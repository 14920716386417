import { message } from "antd";
import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import locationApi from "lib/api/location";
import { useEffect, useState } from "react";
import { MdLocationPin, MdOutlineMyLocation } from "react-icons/md";
import { IOpenMapLocation } from "types/responses/location";

interface CurrentLocationProps {
  initialValues?: Partial<IOpenMapLocation>;
  onChange: (location: Partial<IOpenMapLocation>) => void;
}

function CurrentLocation({ initialValues, onChange }: CurrentLocationProps) {
  const [location, setLocation] = useState<Partial<IOpenMapLocation> | null>(
    null,
  );

  useEffect(() => {
    if (initialValues) {
      setLocation(initialValues);
    }
  }, [initialValues]);

  const changeLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const res = await locationApi.getOpenMapLocation({
          lat: latitude,
          lng: longitude,
        });

        if (res.status === 200) {
          setLocation(res.data);
        }

        onChange(res.data);
      });
    }
  };

  return (
    <>
      <Flex
        alignItems="center"
        gap="10px"
        cursor="pointer"
        onClick={changeLocation}
        color="$secondary"
      >
        <If condition={!location}>
          <MdOutlineMyLocation />
          <Text>Gunakan lokasi saat ini</Text>
        </If>
        <If condition={!!location}>
          <MdLocationPin />
          <Text>{location?.display_name}</Text>
        </If>
      </Flex>
    </>
  );
}

export default CurrentLocation;
