import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import useChatStore from "features/chat/chatStore";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import ModalConfirm from "global/Modals/ModalConfirm";
import ModalDelete from "global/Modals/ModalDelete";
import ModalInfo from "global/Modals/ModalInfo";
import useInput from "hooks/useInput";
import profileStore from "lib/stores/entities/profile";
import { useEffect, useState } from "react";
import { HiOutlineLogout } from "react-icons/hi";
import { IoPersonAdd } from "react-icons/io5";
import { IChatGetMemberList } from "types/responses/chat";
import { IUserList } from "types/responses/users";
import { getColor } from "utils/general";
import MemberItem from "./MemberItem";
import ModalAddMember from "./ModalAddMember";

interface GroupMemberProps {
  isAdmin?: boolean;
  groupName?: string;
}

function GroupMember({ isAdmin = false, groupName = "-" }: GroupMemberProps) {
  const profile = profileStore((s) => s.user);
  const [activeRoomId, setActiveRoomId, getRoomList, setChatState] =
    useChatStore((s) => [
      s.activeChatRoom,
      s.setActiveChatRoom,
      s.getChatRoomList,
      s.setMainContentState,
    ]);
  const [memberList, getMemberList, addMember] = useChatStore((s) => [
    s.chatMemberList,
    s.getChatMemberList,
    s.addChatMember,
  ]);
  const [deleteMember, setAdmin] = useChatStore((s) => [
    s.deleteChatMember,
    s.updateChatAdmin,
  ]);

  const [query, setQuery] = useInput("");

  const [showAdd, setShowAdd] = useState(false);
  const [showSetAdmin, setShowSetAdmin] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showExit, setShowExit] = useState(false);
  const [showNotAdmin, setShowNotAdmin] = useState(false);

  const [selectedMember, setSelectedMember] =
    useState<IChatGetMemberList | null>(null);

  useEffect(() => {
    if (activeRoomId) {
      const params = {
        page: 1,
        room: activeRoomId,
      };

      getMemberList(params);
    }
  }, [activeRoomId]);

  const handleShowSetAdmin = (data: IChatGetMemberList) => {
    setSelectedMember(data);
    setShowSetAdmin(true);
  };

  const handleCancelSetAdmin = () => {
    setSelectedMember(null);
    setShowSetAdmin(false);
  };

  const handleSetAdmin = async () => {
    const data = {
      id_user: Number(selectedMember?.id),
      room: Number(activeRoomId),
    };

    const res = await setAdmin(data);

    if (res) {
      handleCancelSetAdmin();
      getMemberList({
        page: 1,
        room: activeRoomId,
      });
    }
  };

  const handleShowRemove = (data: IChatGetMemberList) => {
    setSelectedMember(data);
    setShowRemove(true);
  };

  const handleCancelRemove = () => {
    setSelectedMember(null);
    setShowRemove(false);
  };

  const handleRemove = async () => {
    const data = {
      id_user: Number(selectedMember?.id),
      room: Number(activeRoomId),
    };

    const res = await deleteMember(data);

    if (res) {
      handleCancelRemove();

      getMemberList({
        page: 1,
        room: activeRoomId,
      });
    }
  };

  const handleExitGroup = async () => {
    if (isAdmin) {
      // make sure there's another admin
      const adminCount = memberList[activeRoomId].data.filter(
        (member) => member.admin === "1",
      ).length;

      if (adminCount <= 1) {
        setShowNotAdmin(true);
        setShowExit(false);
        return;
      }
    }

    const data = {
      id_user: Number(profile?.id),
      room: Number(activeRoomId),
    };

    const res = await deleteMember(data);

    if (res) {
      setShowExit(false);

      // Reset state and refetch room list
      setActiveRoomId("");
      setChatState("chat-list");
      getRoomList({ page: 1 });
    }
  };

  const handleAddMember = async (users: Array<IUserList>) => {
    const data = {
      id_users: users.map((user) => Number(user.id)),
      room: Number(activeRoomId),
    };

    const res = await addMember(data);

    if (res) {
      setShowAdd(false);
      getMemberList({
        page: 1,
        room: activeRoomId,
      });
    }
  };

  if (!activeRoomId || !memberList[activeRoomId]) {
    return null;
  }

  const groupMembers = memberList[activeRoomId];
  const members = groupMembers.data.filter((member) => {
    return member.name.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <>
      <Flex direction="column" w="100%" gap="16px">
        <Text weight="$bold" color="$coolGray600">
          {groupMembers.total} Anggota Grup
        </Text>
        <InputSearch
          w="100%"
          placeholder="Cari Warga"
          value={query}
          onChange={setQuery}
        />
        <If condition={isAdmin}>
          <Flex
            alignItems="center"
            color="$coolGray700"
            gap="8px"
            cursor="pointer"
            onClick={() => setShowAdd(true)}
          >
            <Avatar size={40} style={{ backgroundColor: getColor("primary") }}>
              <IoPersonAdd
                color={getColor("coolGray700")}
                style={{ marginTop: 8 }}
              />
            </Avatar>
            <Text weight="$bold">Tambah Anggota Grup</Text>
          </Flex>
        </If>
        <Flex direction="column" gap="8px" maxHeight="550px" w="100%">
          {members.map((member) => (
            <MemberItem
              member={member}
              onRemove={() => handleShowRemove(member)}
              onSetAdmin={() => handleShowSetAdmin(member)}
              isAdmin={isAdmin}
            />
          ))}
        </Flex>
        <Flex
          alignItems="center"
          color="red"
          gap="8px"
          cursor="pointer"
          onClick={() => setShowExit(true)}
        >
          <HiOutlineLogout />
          <Text weight="$bold">Keluar dari grup ini</Text>
        </Flex>
      </Flex>
      <ModalAddMember
        visible={showAdd}
        onCancel={() => setShowAdd(false)}
        onAddMember={handleAddMember}
      />

      <ModalDelete
        visible={showRemove}
        onCancel={handleCancelRemove}
        onDelete={handleRemove}
      >
        <Text>
          Apakah kamu yakin ingin mengeluarkan <b>{selectedMember?.name}</b>{" "}
          dari grup <b>{groupName}</b>?
        </Text>
      </ModalDelete>

      <ModalConfirm
        visible={showSetAdmin}
        title="Konfirmasi Set Admin"
        onCancel={handleCancelSetAdmin}
        onConfirm={handleSetAdmin}
      >
        <Text>
          Apakah kamu yakin ingin menetapkan <b>{selectedMember?.name}</b>{" "}
          sebagai admin grup <b>{groupName}</b>?
        </Text>
      </ModalConfirm>

      <ModalConfirm
        visible={showExit}
        title="Konfirmasi Keluar Grup"
        onCancel={() => setShowExit(false)}
        onConfirm={handleExitGroup}
      >
        <Text>
          Apakah kamu yakin ingin keluar dari grup <b>{groupName}</b>?
        </Text>
      </ModalConfirm>

      <ModalInfo
        visible={showNotAdmin}
        onConfirm={() => setShowNotAdmin(false)}
      >
        <Text>
          Sebelum keluar dari grup {groupName} silahkan pilih admin baru untuk
          menjadi admin terlebih dahulu
        </Text>
      </ModalInfo>
    </>
  );
}

export default GroupMember;
