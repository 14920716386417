import { Flex } from "components/Base";
import StreamWidget from "global/StreamWidget";
import { useState } from "react";
import PostCategory from "./components/PostCategory";
import SideContent from "./components/SideContent";
import Story from "./components/Story";

function Explore() {
  const [categoryId, setCategoryId] = useState("");

  return (
    <Flex w="100%" gap="16px">
      <SideContent />
      <Flex as="main" direction="column" flex="1">
        <Story />
        <PostCategory selectedId={categoryId} onChange={setCategoryId} />
        <StreamWidget category={categoryId} />
      </Flex>
    </Flex>
  );
}

export default Explore;
