import { Image, Text } from "components/Base";
import info from "lib/api/info";
import { useEffect, useState } from "react";

function About() {
  const [about, setAbout] = useState("");

  useEffect(() => {
    info
      .getAbout()
      .then((res) => setAbout(res.data.value))
      .catch(() => {});
  }, []);

  return (
    <>
      <Image src="/logo.png" h="42px" alt="Logo Sigap" mx="auto" mb="40px" />
      <Text lineHeight="22px" align="center" mb="20px" weight="$bold">
        Didukung oleh:{" "}
      </Text>
      <Image
        src="/images/companies.png"
        h="42px"
        alt="Logo Pendukung"
        mx="auto"
        mb="40px"
      />
      <Text lineHeight="22px" align="justify">
        {about}
      </Text>
    </>
  );
}

export default About;
