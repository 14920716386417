import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";

export const socialMedias = [
  {
    icon: <BsInstagram size={23} />,
    url: "https://instagram.com",
  },
  {
    icon: <FaFacebook size={23} />,
    url: "https://facebook.com",
  },
  {
    icon: <BsTwitter size={23} />,
    url: "https://twitter.com",
  },
  {
    icon: <BsYoutube size={23} />,
    url: "https://youtube.com",
  },
];
