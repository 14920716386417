export const LOGIN_STATE = {
  LOGOUT: "logout",
  RESET_PASSWORD: "reset_password",
  FORGOT_PASSWORD: "forgot_password",
};

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

export const IMAGE_FILES_EXTENSION = ["jpg", "jpeg", "png", "gif", "svg"];

export const VIDEO_FILES_EXTENSION = ["mp4", "mov", "avi", "wmv", "flv", "mkv"];

export const AUDIO_FILES_EXTENSION = ["mp3", "wav", "ogg", "wma", "aac"];

export const DOCUMENT_FILES_EXTENSION = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
  "csv",
];

export const MAX_MEDIA_UPLOAD = 6;

export const NOTIF_ACTIONS = {
  CHAT: "OPEN_CHAT_DETAIL",
};
