import { Flex } from "components/Base";
import PageTitle from "global/PageTitle";
import StreamWidget from "global/StreamWidget";
import profileStore from "lib/stores/entities/profile";
import { useSearchParams } from "react-router-dom";

function Search() {
  const profile = profileStore((s) => s.user);
  const [search] = useSearchParams();

  const q = search.get("q") || "";
  const guestMode = !profile;

  return (
    <>
      <PageTitle title={`Hasil pencarian untuk '${q}'`} />
      <Flex maxWidth="850px" mx="auto">
        <StreamWidget keyword={q} guestMode={guestMode} />
      </Flex>
    </>
  );
}

export default Search;
