// @ts-nocheck
import { Flex, Image, Text } from "components/Base";
import { Link } from "react-router-dom";
import { socialMedias } from "./constants";
import DownloadLogo from "./DownloadLogo";
import { FooterGrid, FooterWrapper } from "./styled";

function Footer() {
  return (
    <FooterWrapper>
      <FooterGrid>
        <Flex direction="column" gap="20px">
          <Image src="/logo.png" height="47px" />
          <Text size="$md" color="$coolGray800" lineHeight="25px">
            <b>Yayasan Konservasi Alam Nusantara</b>
            <br />
            Graha Iskandarsyah 3rd.Fl. Jl. Iskandarsyah Raya No. 66C Jakarta,
            Indonesia 12160
            <br />
            Jakarta Selatan
            <br />
            DKI Jakarta
            <br />
            <span>
              Telepon <a href="tel:+622172792043">+62 21-727-920-43</a>
            </span>
            <br />
            <span>
              Email:{" "}
              <a href="mailto:indonesia@ykan.or.id">indonesia@ykan.or.id</a>
            </span>
          </Text>
        </Flex>
        <Flex className="footer-menu" direction="column" gap="12px">
          <Link to="/">
            <Text color="$coolGray800" fontWeight="$bold" size="$xl">
              Menu
            </Text>
          </Link>
          <Link to="/">
            <Text color="$coolGray800" fontWeight="$bold" size="$xl">
              Tentang Sigap
            </Text>
          </Link>
          <Link to="/">
            <Text color="$coolGray800" fontWeight="$bold" size="$xl">
              Bantuan
            </Text>
          </Link>
        </Flex>
        <Flex direction="column" gap="12px">
          <Text color="$coolGray800" fontWeight="$bold" size="$xl">
            Sosial Media Sigap
          </Text>
          <Flex align="center" gap="20px">
            {socialMedias.map((social, index) => (
              <a
                href={social.url}
                target="_blank"
                rel="noreferrer noopener"
                key={`social-${index}`}
              >
                <Flex
                  br="$rounded-full"
                  color="$white"
                  background="$coolGray800"
                  w="54px"
                  h="54px"
                  align="center"
                  justify="center"
                >
                  {social.icon}
                </Flex>
              </a>
            ))}
          </Flex>
          <Text color="$coolGray800" fontWeight="$bold" size="$xl">
            Download Aplikasi di
          </Text>
          <Flex align="center" gap="20px">
            <DownloadLogo />
          </Flex>
        </Flex>
      </FooterGrid>
      <Text align="center" color="$coolGray800" size="$xl" py="17px">
        &copy; 2022 - SIGAP. All rights reserved.
      </Text>
    </FooterWrapper>
  );
}

export default Footer;
