import { Flex } from "components/Base";
import React from "react";
import { GeneralProps } from "types/general";

interface FooterButtonProps {
  color: GeneralProps["color"];
  textColor?: GeneralProps["color"];
  onClick?: () => void;
}

function FooterButton({
  color,
  textColor,
  children,
  onClick = () => {},
}: React.PropsWithChildren<FooterButtonProps>) {
  return (
    <Flex
      role="button"
      cursor="pointer"
      py="8px"
      br="$rounded-full"
      bgColor={color}
      color={textColor}
      justify="center"
      align="center"
      gap="8px"
      onClick={onClick}
    >
      {children}
    </Flex>
  );
}

export default FooterButton;
