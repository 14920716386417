import { Flex, Text } from "components/Base";
import { IoMdEye } from "react-icons/io";

interface ViewerShowProps {
  isMyStory: boolean;
  totalViewer: number;
}

function ViewerShow({ isMyStory, totalViewer = 0 }: ViewerShowProps) {
  if (!isMyStory) {
    return null;
  }

  return (
    <Flex alignItems="center" gap="8px" color="$white">
      <IoMdEye />
      <Text weight="$bold">{totalViewer}</Text>
    </Flex>
  );
}

export default ViewerShow;
