import { Flex, Text } from "components/Base";
import { INotifItem } from "types/responses/notif";
import NotifItem from "./NotifItem";

interface NotifGroupProps {
  title: string;
  listNotif: Array<INotifItem>;
}

function NotifGroup({ title, listNotif }: NotifGroupProps) {
  return (
    <Flex direction="column" width="100%">
      <Text weight="$bold" color="$coolGray700" p="16px">
        {title}
      </Text>
      {listNotif.map((notif, index) => (
        <NotifItem notif={notif} key={`notif-item-${title}-${index}`} />
      ))}
    </Flex>
  );
}

export default NotifGroup;
