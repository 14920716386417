import User from "features/user";
import MainLayout from "global/MainLayout";

function UserPage() {
  return (
    <MainLayout>
      <User />
    </MainLayout>
  );
}

export default UserPage;
