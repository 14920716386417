import { Avatar } from "antd";
import { Flex } from "components/Base";
import { Input } from "components/Form";
import useInput from "hooks/useInput";
import { HiPaperAirplane } from "react-icons/hi2";
import { IPostItem } from "types/responses/post";
import { getColor } from "utils/general";
import { convertToEventTarget } from "utils/input";

interface ComposeCommentProps {
  user: IPostItem["user"];
  onComment?: (comment: string) => Promise<boolean>;
}

function ComposeComment({ user, onComment }: ComposeCommentProps) {
  const [comment, setComment] = useInput("");

  const handleSumbit = async () => {
    if (!comment) {
      return;
    }

    const res = await onComment?.(comment);

    if (res) {
      setComment(convertToEventTarget(""));
    }
  };

  return (
    <Flex alignItems="center" gap="12px" w="100%" mb="14px">
      <Avatar size={42} src={user.photo} />
      <Flex flex="1">
        <Input
          placeholder="Ketikan komentar"
          suffix={
            <HiPaperAirplane
              style={{ color: getColor("coolGray400") }}
              onClick={() => handleSumbit()}
            />
          }
          css={{
            mb: "0px",
            br: "$rounded-full",
            w: "100%",
          }}
          value={comment}
          onChange={setComment}
          onPressEnter={handleSumbit}
        />
      </Flex>
    </Flex>
  );
}

export default ComposeComment;
