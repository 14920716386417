import { SECOND_API_URL } from "constants/api";
import { AxiosResponse } from "axios";
import request from "configs/request";
import requestBackoffice from "configs/requestBackoffice";
import { APIPromiseResponse } from "types/api";
import {
  ILoginData,
  ILoginResponse,
  IRegisterData,
} from "types/responses/auth";

const AUTH_URL = `${SECOND_API_URL}/auth`;
const AUTH_QR_URL = `${AUTH_URL}/login/qr-code`;

const register = (data: IRegisterData): APIPromiseResponse => {
  return request.post("/add_user.php", data);
};

const login = (data: ILoginData): Promise<AxiosResponse<ILoginResponse>> => {
  return request.post(`/login_user.php`, data);
};

const loginQr = (
  code: string,
  token: string,
): Promise<AxiosResponse<ILoginResponse>> => {
  return requestBackoffice.post(`${AUTH_QR_URL}`, { code, fcmToken: token });
};

const forgotPassword = (email: string): APIPromiseResponse => {
  return request.post(`/forgot_password.php`, { email });
};

interface ResetPasswordParams {
  token: string;
  email: string;
  password?: string; // If no password, it will verify the code first
}

const resetPassword = (params: ResetPasswordParams): APIPromiseResponse => {
  return request.post("/reset_password.php", {
    email: params.email,
    password: params.password,
    verification_code: params.token,
  });
};

const resendOtp = (email: string): APIPromiseResponse => {
  return request.post("/resend_otp_register.php", { email });
};

const verifyOtp = (code: string, userId: string): APIPromiseResponse => {
  return request.post("/verify_register.php", {
    verification_code: code,
    user_id: userId,
  });
};

const getEmailVerif = (code: string): APIPromiseResponse<{ data: string }> => {
  return request.get("/get_email_verif.php", { params: { code } });
};

export default {
  register,
  forgotPassword,
  resetPassword,
  login,
  resendOtp,
  verifyOtp,
  loginQr,
  getEmailVerif,
};
