import { Box } from "components/Base";
import { Select } from "components/Form";
import usePostStore from "lib/stores/entities/stream";
import { useEffect } from "react";

interface PostCategoryProps {
  category?: string;
  onChange?: (value: string) => void;
}

function PostCategory({ category, onChange = () => {} }: PostCategoryProps) {
  const [categories, getCategories] = usePostStore((s) => [
    s.postCategories,
    s.getPostCategory,
  ]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
  }, [categories]);

  if (categories.length === 0) {
    return null;
  }

  const options = categories.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  return (
    <Box w="210px">
      <Select
        placeholder="Pilih kategori"
        options={options}
        value={category}
        onChange={onChange}
      />
    </Box>
  );
}

export default PostCategory;
