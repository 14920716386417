import { Flex, Text } from "components/Base";
import { AiFillLike } from "react-icons/ai";
import { getColor } from "utils/general";

interface StatsProps {
  totalComment: number;
  totalLike: number;
  onClickLike: () => void;
  onClickComment: () => void;
}

function Stats({
  totalComment,
  totalLike,
  onClickLike,
  onClickComment,
}: StatsProps) {
  return (
    <Flex alignItems="center" justify="space-between" w="100%">
      <Flex
        alignItems="center"
        gap="8px"
        flex="1"
        cursor="pointer"
        onClick={onClickLike}
      >
        <AiFillLike color={getColor("coolGray800")} />
        <Text color="$coolGray800" weight="$semibold">
          {totalLike || 0}
        </Text>
      </Flex>
      <Text
        color="$coolGray800"
        weight="$semibold"
        cursor="pointer"
        onClick={onClickComment}
      >
        {totalComment || 0} Komentar
      </Text>
    </Flex>
  );
}

export default Stats;
