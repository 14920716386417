import { Flex, Image, Text } from "components/Base";

interface BadgeLabelProps {
  label: string;
  value: React.ReactNode;
  color: string;
  icon: string;
}

function BadgeLabel({ label, value, color, icon }: BadgeLabelProps) {
  return (
    <Flex alignItems="center" gap="14px">
      <Image src={icon} alt={label} h="24px" css={{ color }} />
      <Flex direction="column" gap="4px">
        <Text size="$sm" weight="$semibold" lineHeight="20px">
          {label}
        </Text>
        <Text weight="$bold" color={color} lineHeight="20px">
          {value}
        </Text>
      </Flex>
    </Flex>
  );
}

export default BadgeLabel;
