import { Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
import InputSearch from "global/InputSearch";
import locationApi from "lib/api/location";
import { useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { IGoogleMapLocation, IOpenMapLocation } from "types/responses/location";
import { getColor } from "utils/general";
import CurrentLocation from "../CurrentLocation";

interface ModalLocationProps {
  visible: boolean;
  onCancel: () => void;
  onSelect: (location: Partial<IOpenMapLocation>) => void;
}

function ModalLocation({ visible, onCancel, onSelect }: ModalLocationProps) {
  const [query, setQuery] = useState("");
  const [timeInterval, setTimeInterval] = useState<any>(0);

  const [locations, setLocations] = useState<Array<IGoogleMapLocation>>([]);

  const handleCancel = () => {
    setQuery("");
    onCancel();
  };

  const handleSearch = (keyword: string) => {
    locationApi
      .getSearchLocation(keyword)
      .then((res) => {
        setLocations(res.data.predictions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelect = (location: IGoogleMapLocation) => {
    locationApi
      .getDetailLocation(location.place_id)
      .then((res) => {
        const data = {
          display_name: location.structured_formatting.main_text,
          lat: String(res.data.result.geometry.location.lat),
          lon: String(res.data.result.geometry.location.lng),
        };

        onSelect(data);
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) {
      if (timeInterval) {
        clearTimeout(timeInterval);
      }

      setTimeInterval(
        setTimeout(() => {
          handleSearch(value);
        }, 500),
      );
    }
  };

  const handleSelectCurrent = (location: Partial<IOpenMapLocation>) => {
    onSelect(location);
    handleCancel();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      title="Tandai Lokasi Terkini"
      width={650}
      zIndex={1055}
    >
      <Flex
        direction="column"
        gap="10px"
        pb="16px"
        borderBottom="solid 1px $coolGray300"
        w="100%"
      >
        <InputSearch
          w="100%"
          placeholder="Cari lokasi"
          value={query}
          onChange={handleChange}
        />
        <Flex direction="column" w="100%" gap="4px">
          <Text>Peta Kegiatan</Text>
          <CurrentLocation onChange={handleSelectCurrent} />
        </Flex>
      </Flex>
      <Flex direction="column" gap="16px" w="100%" mt="16px">
        {locations.map((location, index) => (
          <Flex
            key={`${index}`}
            alignItems="center"
            gap="16px"
            cursor="pointer"
            onClick={() => handleSelect(location)}
            w="100%"
            css={{
              "&:hover": {
                bgColor: "$coolGray50",
              },
            }}
          >
            <Flex
              w="40px"
              h="40px"
              br="$rounded-md"
              align="center"
              justify="center"
              bgColor="$secondary5"
            >
              <MdLocationPin color={getColor("secondary")} />
            </Flex>
            <Flex direction="column" flex="1" wrap="nowrap" overflow="hidden">
              <Text fontSize="$md" weight="$bold">
                {location.structured_formatting.main_text}
              </Text>
              <Text fontSize="$sm" color="$coolGray400" ellipsis w="100%">
                {location.structured_formatting.secondary_text}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
}

export default ModalLocation;
