import { Modal } from "antd";
import { Flex, Text } from "components/Base";
import config from "lib/api/config";
import { useEffect, useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io5";
import { getColor } from "utils/general";

interface ModalContactProps {
  visible: boolean;
  onCancel: () => void;
}

function ModalContact({ visible, onCancel }: ModalContactProps) {
  const [list, setList] = useState<Array<any>>([]);

  const getList = async () => {
    try {
      const res = await config.getContactList();

      if (res.status !== 200) {
        throw new Error("Gagal mengambil data");
      }

      if (!res.data) {
        throw new Error("Data tidak ditemukan");
      }

      setList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      closable
      title={
        <Text align="center" fontSize="18px" family="$bold" mb="32px">
          Hubungi Kami
        </Text>
      }
      width={490}
      footer={null}
    >
      <Flex direction="column" gap="16px">
        {list.map((item, index) => (
          <a
            key={`contact-${index}`}
            href={`https://wa.me/${item.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            <Flex align="center" gap="16px">
              <IoLogoWhatsapp color={getColor("secondary")} size={30} />
              <Text color="$coolGray500" lineHeight="20px">
                {item.name}
              </Text>
            </Flex>
          </a>
        ))}
      </Flex>
    </Modal>
  );
}

export default ModalContact;
