import { useInfiniteQuery } from "@tanstack/react-query";
import { Flex, Grid, Image, Text } from "components/Base";
import ModalShowStory from "global/Modals/ModalStory/ModalShow";
import story from "lib/api/story";
import { useEffect, useMemo, useState } from "react";
import { IPostUser } from "types/responses/post";
import { IStory } from "types/responses/story";
import { formatDate } from "utils/date";

function StoryArchive() {
  const [page, setPage] = useState(1);
  const limit = 20;

  const [showStory, setShowStory] = useState(false);
  const [activeStories, setActiveStories] = useState<Array<IStory>>([]);
  const [selectedUser, setSelectedUser] = useState<IPostUser | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-999);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["story-archive", { page, limit }],
    queryFn: async ({ pageParam = page }) => {
      const response = await story.getStoryArchive({ page, limit });

      return response.data.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.length < limit) {
        return undefined;
      }

      setPage((prevPage) => prevPage + 1);
      return page + 1;
    },
  });

  const stories = useMemo(() => {
    return data ? data.pages.flatMap((page) => page) : [];
  }, [data]);

  const handleShowStory = (
    user: IPostUser,
    stories: IStory[],
    index: number = -999,
  ) => {
    setSelectedUser(user);
    setActiveStories(stories);
    setShowStory(true);
    setCurrentIndex(index);
  };

  const handleCloseStory = () => {
    // Refetch the stories
    setSelectedUser(null);
    setActiveStories([]);
    setShowStory(false);
    setCurrentIndex(-999);
  };

  const handleStoryEnd = () => {
    // End of all stories or current user story
    // @ts-ignore
    if (currentIndex === stories.stories.length - 1 || currentIndex === -999) {
      handleCloseStory();
    } else {
      setCurrentIndex(currentIndex + 1);
      // @ts-ignore
      setActiveStories(stories.stories[currentIndex + 1].stories);
      // @ts-ignore
      setSelectedUser(stories.stories[currentIndex + 1].user);
    }
  };

  return (
    <>
      <Grid gap="16px" columns="repeat(4, 1fr)">
        {stories.map((story) => (
          <Flex
            key={story.id}
            onClick={() => handleShowStory(story.user, story.stories)}
            css={{ cursor: "pointer" }}
            position="relative"
          >
            <Image
              src={story.stories[0].media}
              h="265px"
              w="100%"
              autofit
              borderRadius="$rounded-lg"
            />
            <Flex
              direction="column"
              p="8px"
              br="$rounded"
              gap="4px"
              position="absolute"
              css={{ bgColor: "$white", top: "8px", left: "8px" }}
            >
              <Text weight="bold" size="$2xl">
                {formatDate(story.stories[0].created_at, "dd")}
              </Text>
              <Text>{formatDate(story.stories[0].created_at, "MMM")}</Text>
            </Flex>
          </Flex>
        ))}
      </Grid>
      <ModalShowStory
        visible={showStory}
        onCancel={handleCloseStory}
        onStoryEnd={handleStoryEnd}
        user={selectedUser}
        stories={activeStories}
      />
    </>
  );
}

export default StoryArchive;
