import { Card, Flex } from "components/Base";
import ModalAddStory from "global/Modals/ModalStory";
import ModalShowStory from "global/Modals/ModalStory/ModalShow";
import profileStore from "lib/stores/entities/profile";
import { useEffect, useState } from "react";
import { IPostUser } from "types/responses/post";
import { IStory } from "types/responses/story";
import StoryItem from "./StoryItem";
import useStoryStore from "./storyStore";

function Story() {
  const profile = profileStore((s) => s.user);
  const [stories, getStories] = useStoryStore((s) => [s.stories, s.getStories]);

  const [showAdd, setShowAdd] = useState(false);
  const [selectedStory, setSelectedStory] = useState<File | null>(null);

  const [showStory, setShowStory] = useState(false);
  const [activeStories, setActiveStories] = useState<Array<IStory>>([]);
  const [selectedUser, setSelectedUser] = useState<IPostUser | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-999);

  useEffect(() => {
    getStories();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];

      if (!file) return;

      setShowAdd(true);
      setSelectedStory(file);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModalAdd = () => {
    const modal = document.getElementById("add-story");

    if (modal) {
      // @ts-ignore
      modal.value = "";
    }

    setShowAdd(false);
    setSelectedStory(null);
  };

  const handleSuccessAdd = () => {
    getStories();
    handleCloseModalAdd();
  };

  const handleShowStory = (
    user: IPostUser,
    stories: IStory[],
    index: number = -999,
  ) => {
    setSelectedUser(user);
    setActiveStories(stories);
    setShowStory(true);
    setCurrentIndex(index);
  };

  const handleCloseStory = () => {
    // Refetch the stories
    getStories();

    setSelectedUser(null);
    setActiveStories([]);
    setShowStory(false);
    setCurrentIndex(-999);
  };

  const handleStoryEnd = () => {
    // End of all stories or current user story
    if (currentIndex === stories.stories.length - 1 || currentIndex === -999) {
      handleCloseStory();
    } else {
      setCurrentIndex(currentIndex + 1);
      setActiveStories(stories.stories[currentIndex + 1].stories);
      setSelectedUser(stories.stories[currentIndex + 1].user);
    }
  };

  if (!profile) return null;

  return (
    <>
      <Card
        w="100%"
        p="22px 0px 22px 25px"
        css={{
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        border="solid 1px $coolGray100"
        mb="12px"
      >
        <Flex alignItems="center" gap="40px" wrap="nowrap">
          <StoryItem
            user={profile}
            stories={stories.mine}
            mine
            onClick={() => handleShowStory(profile, stories.mine)}
          />
          {stories.stories.map((story, idx) => (
            <StoryItem
              user={story.user}
              stories={story.stories}
              onClick={() => handleShowStory(story.user, story.stories, idx)}
            />
          ))}
        </Flex>
      </Card>
      <input
        type="file"
        id="add-story"
        style={{ display: "none" }}
        accept="image/jpg, image/jpeg, image/png"
        onChange={handleChange}
      />
      <ModalAddStory
        visible={showAdd}
        onCancel={handleCloseModalAdd}
        onSuccessSubmit={handleSuccessAdd}
        story={selectedStory}
      />
      <ModalShowStory
        visible={showStory}
        onCancel={handleCloseStory}
        onStoryEnd={handleStoryEnd}
        user={selectedUser}
        stories={activeStories}
      />
    </>
  );
}

export default Story;
