import { ChatGeneralStore, StoreSlice } from "./chatStore.types";

const chatGeneralSlice: StoreSlice<ChatGeneralStore> = (set) => ({
  sideContentState: "home",
  mainContentState: "chat-list",
  setSideContentState: (state) => set({ sideContentState: state }),
  setMainContentState: (state) => set({ mainContentState: state }),
});

export default chatGeneralSlice;
