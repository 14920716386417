import { Flex, Image } from "components/Base";

function EmptyNotif() {
  return (
    <Flex align="center" justify="center" h="400px">
      <Image src="/images/empty-notif.png" alt="empty notif" />
    </Flex>
  );
}

export default EmptyNotif;
