// @ts-nocheck
import { Text } from "components/Base";
import { InnerCircle, OuterCircle, StyledCard } from "./List7D.styled";

interface Card7DProps {
  color: string;
  title: string;
  position: string;
  [key: string]: any;
}

function Card7D({ color, title, position, ...props }: Card7DProps) {
  const percentToHex = (percentNum = 0) =>
    Math.round((percentNum / 100) * 255)
      .toString(16)
      .padStart(2, "0");

  const bgColor = `${color}${percentToHex(20)}`;
  const circleColor = `${color}${percentToHex(80)}`;

  let circleStyle: any = {
    left: "-30%",
  };

  if (position === "right") {
    circleStyle = {
      right: "-30%",
    };
  }

  if (position === "center") {
    circleStyle = {
      left: "50%",
      transform: "translateX(-50%)",
    };
  }

  return (
    <StyledCard backgroundColor={bgColor} {...props}>
      <Text className="caption-7d" color={color}>
        {title}
      </Text>
      <OuterCircle border={`solid 16px ${circleColor}`} {...circleStyle}>
        <InnerCircle bg={circleColor} />
      </OuterCircle>
    </StyledCard>
  );
}

export default Card7D;
