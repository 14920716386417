import { Flex, Image, Text } from "components/Base";

interface EmptyChatProps {
  isInRoom?: boolean;
}

function EmptyChat({ isInRoom = false }: EmptyChatProps) {
  if (isInRoom) {
    return (
      <Flex
        align="center"
        justify="center"
        w="100%"
        h="690px"
        direction="column"
        gap="16px"
        px="120px"
      >
        <Image src="/images/empty-chat-content.png" alt="Empty Chat Content" />
        <Text weight="$bold" fontSize="$lg">
          Selamat Datang di fitur Chat
        </Text>
        <Text color="$coolGray500" lineHeight="22px">
          Silahkan memilih pesan untuk memulai percakapan. untuk melai ngirim
          pesan silahkan klik tombol Buat Chat
        </Text>
      </Flex>
    );
  }

  return (
    <Flex align="center" justify="center" w="100%" h="400px">
      <Image src="/images/empty-chat.png" alt="Empty Chat" />
    </Flex>
  );
}

export default EmptyChat;
