import authStore from "lib/stores/entities/auth";
import { useEffect } from "react";
import { Outlet, Route, useNavigate } from "react-router-dom";
import { getToken } from "utils/authStore";

function RestrictedRoute() {
  const navigate = useNavigate();
  const authUser = authStore((state) => state.user);

  useEffect(() => {
    const token = authUser || getToken();

    if (token) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  // // Get user data, if there's token redirect user to dashboard
  // if (loggedIn) {
  //   return <Navigate to="/home" />;
  // }

  return <Outlet />;
}

export default RestrictedRoute as typeof Route;
