import Mengenal7D from "features/7d";
import MainLayout from "global/MainLayout";
import PageTitle from "global/PageTitle";

function Mengenal7DPage() {
  return (
    <MainLayout>
      <PageTitle title="Mengenal Tahapan 7D" />
      <Mengenal7D />
    </MainLayout>
  );
}

export default Mengenal7DPage;
