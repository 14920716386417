import profileApi from "lib/api/profile";
import { IUserProfile } from "types/responses/users";
import create from "zustand";

interface ProfileSlice {
  user: IUserProfile | null;
  getProfile: () => void;
  resetProfile: () => void;
}

const profileStore = create<ProfileSlice>((set, get) => ({
  user: null,
  getProfile: async () => {
    const res = await profileApi.getProfile();

    if (res.status !== 200) {
      console.log("error", res.data);
    }

    if (!res.data) {
      // do nothing
      return;
    }

    set({ user: res.data });
  },
  resetProfile: () => {
    set({ user: null });
  },
}));

export default profileStore;
