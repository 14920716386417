import List7D from "./components/List7D";
import TakeList from "./components/TakeList";

function Mengenal7D() {
  return (
    <>
      <List7D />
      <TakeList />
    </>
  );
}

export default Mengenal7D;
