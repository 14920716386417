import { Button, Flex, Text } from "components/Base";
import { PROFILE_HEADER } from "features/profile/constants";
import useProfileStore, { ProfileState } from "features/profile/profileStore";
import ComposePost from "global/Compose";
import CardContent from "global/Profile/CardContent";
import profileStore from "lib/stores/entities/profile";
import usePostStore from "lib/stores/entities/stream";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IAddPost } from "types/responses/post";
import About from "./About";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import Help from "./Help";
import Inspirasi from "./Inspirasi";
import LevelUser from "./Level";
import PointHistory from "./PointHistory";
import StoryArchive from "./StoryArchive";

function MainContent() {
  const user = profileStore((s) => s.user);
  const profileState = useProfileStore((s) => s.profileState);
  const userPost = usePostStore((s) => s.userPost);

  const [addPost, getPostList] = usePostStore((s) => [
    s.addPost,
    s.getUserPostList,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const renderContent = () => {
    const contentMapping: Record<ProfileState, React.ReactNode> = {
      "point-history": <PointHistory />,
      about: <About />,
      "edit-profile": <EditProfile />,
      help: <Help />,
      "change-password": <ChangePassword />,
      inspirasi: <Inspirasi />,

      story: <StoryArchive />,
      "privacy-policy": <p>Ini konten settings</p>,
      level: <LevelUser />,
    };

    return contentMapping[profileState] || null;
  };

  const decideTransparent = () => {
    if (profileState === "help") {
      return true;
    }

    if (profileState === "inspirasi") {
      if (!user) {
        return false;
      }

      const postTotal = userPost[user.id]?.total || 0;

      return postTotal > 0;
    }

    return false;
  };

  const renderAdditionalContent = () => {
    if (profileState === "inspirasi") {
      return (
        <Button
          color="secondary"
          block
          shape="circle"
          onClick={() => setVisible(true)}
        >
          <Flex alignItems="center" gap="8px" color="$white">
            <FaPlus />
            <Text weight="$bold">Buat Inspirasi Desa</Text>
          </Flex>
        </Button>
      );
    }

    return null;
  };

  const handleSubmit = async (data: IAddPost) => {
    setIsLoading(true);
    const post = await addPost(data);

    if (post) {
      const params = {
        page: 1,
        limit: 10,
        user: user?.id,
      };
      setIsLoading(false);

      getPostList(params);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setIsLoading(false);
  };

  return (
    <>
      <CardContent
        title={PROFILE_HEADER[profileState]}
        transparent={decideTransparent()}
        headerContent={renderAdditionalContent()}
      >
        {renderContent()}
      </CardContent>
      <ComposePost
        open={visible}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
}

export default MainContent;
