import { ModalProps } from "antd";
import { Flex, Image, Text } from "components/Base";
import { Modal } from "components/Feedback";
import { copyToClipboard, shareToWhatsapp } from "utils/string";

interface ModalShareProps extends ModalProps {
  link: string;
  content?: string;
}

function ModalShare({ title, link, content = "", ...props }: ModalShareProps) {
  return (
    <Modal title={title || "Bagikan"} {...props}>
      <Flex direction="column" gap="16px">
        <Flex
          gap="16px"
          alignItems="center"
          w="350px"
          cursor="pointer"
          onClick={() => {
            shareToWhatsapp(link, content);
          }}
        >
          <Image src="/icons/logo-whatsapp.png" w="29px" />
          <Text color="$coolGray600">Whatsapp</Text>
        </Flex>
        <Flex
          gap="16px"
          alignItems="center"
          w="350px"
          cursor="pointer"
          onClick={copyToClipboard}
        >
          <Image src="/icons/logo-share.png" w="29px" />
          <Text color="$coolGray600">Salin Link</Text>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalShare;
